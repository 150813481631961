import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next-intl/client'

import {
  Button,
  //  CustomImage,
  Slider,
} from '@/components'
import { useLanguages, useOnResize } from '@/hooks'
import { fleetPath, getImage } from '@/utils'
import { IHomePageSlider } from '@/types'

const chooseCarSettings = {
  centerMode: true,
  centerPadding: '150px',
  dots: false,
  arrows: true,
  infinity: true,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: '70px',
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '70px',
      },
    },
  ],
}

export const ChooseCarSection: React.FC<{ items?: IHomePageSlider[] }> = ({
  items = [],
}) => {
  const router = useRouter()
  const { t } = useLanguages()
  const [isMobileFirst, setIsMobileFirst] = useState(false)

  const onResize = useCallback(() => {
    if (window.innerWidth < 768) {
      setIsMobileFirst(true)
    } else {
      setIsMobileFirst(false)
    }
  }, [])

  useOnResize(onResize)

  const settings = useMemo(
    () => ({
      ...chooseCarSettings,
      mobileFirst: isMobileFirst,
      centerPadding: '100px',
    }),
    [isMobileFirst],
  )
  return (
    <section className="choose-car">
      <h2 className="choose-car-title text-center">{t('meetTheFleet')}</h2>
      <Slider
        data={items}
        settings={settings}
        Component={ChooseCarSectionItem}
        className="choose-your-car-carousel"
      />
      <div className="d-flex justify-center">
        <Button className="btn big" onClick={() => router.push(fleetPath)}>
          {t('viewAllVehicles')}
        </Button>
      </div>
    </section>
  )
}

const ChooseCarSectionItem: React.FC<IHomePageSlider> = ({ title, image }) => {
  return (
    <div className="item">
      <div className="choose-your-car-img">
        <img
          width={345}
          height={194}
          className="w-100"
          alt="Choose your car img"
          src={getImage(image, false)}
          style={{ width: 'unset', height: 'unset' }}
        />
      </div>
      <p className="choose-car-carousel-title">{title}</p>
    </div>
  )
}
