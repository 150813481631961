import React from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'

import { CustomImage, HandlerPopup, PageWrapper } from '@/components'
import { useLanguages, useReservationSuccess } from '@/hooks'
import { joinArray } from '@/utils'

import confirmedIcon from '@/assets/images/icons/reservation_confirmed.svg'

export const ReservationConfirmed: React.FC = () => {
  const { t } = useLanguages()
  const { confirmed, isCanceled, isLoading, onClose, cancellationStatus } =
    useReservationSuccess()
  const { reservation } = confirmed || {}

  if (!reservation) return null

  return (
    <PageWrapper isLoading={isLoading}>
      <HandlerPopup
        variant="success"
        onClose={onClose}
        isOpen={!!cancellationStatus}
        customTitle={t('popupCanceled')}
        // @ts-ignore
        message={t(capitalize(cancellationStatus))}
      />
      <section className="reservation-confirmed printable">
        <div className="reservation-container">
          <div className="d-flex justify-between align-center flex-wrap justify-m-center">
            <div className="reservation-confirmed-box">
              <CustomImage src={confirmedIcon.src} width={40} height={40} />
              <div className="reservation-confirmed-box-info">
                <h2 className="reservation-confirmed-title">
                  {isCanceled
                    ? t('reservationCanceled')
                    : t('reservationReservationConfirmed')}
                </h2>
                <p className="reservation-confirmed-text">
                  {!isCanceled &&
                    t('reservationThankYou', {
                      name: joinArray(
                        ' ',
                        reservation.customer.givenName,
                        reservation.customer.surname,
                      ),
                      date: moment(
                        reservation.vehRentalCore.pickUpDateTime,
                      ).format('MMM DD, YYYY.'),
                    })}
                  <br />
                  {t('reservationConfirmationNumber', {
                    number: reservation?.confId.id,
                  })}
                </p>
              </div>
            </div>
            <img
              width={250}
              height={125}
              alt="Car img"
              src={reservation?.vehicle.pictureURL as string}
              style={{ objectFit: 'contain', height: 'unset' }}
            />
          </div>
        </div>
      </section>
    </PageWrapper>
  )
}
