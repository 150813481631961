import React from 'react'

import { Button, Modal } from '@/components'
import { TFunc } from '@/types'
import classNames from 'classnames'

interface IConfirmationModalProps {
  title: string
  onClose: TFunc
  onAction: TFunc
  isOpen?: boolean
  description: string
  closeButtonLabel?: string
  confirmButtonLabel: string
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  title,
  isOpen,
  onClose,
  onAction,
  description,
  closeButtonLabel,
  confirmButtonLabel,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      closeOnBackdrop
      showCloseButton
      onClose={onClose}
      modalContentClassName="min"
    >
      <h1 className="modal-subtitle">{title}</h1>
      <div className="modal-hr"></div>
      <p className="modal-spantext">{description}</p>
      <div
        className={classNames('modal-btn-group', {
          'justify-between': !!closeButtonLabel,
        })}
      >
        {!!closeButtonLabel && (
          <Button className="btn rounded-btn passive" onClick={onClose}>
            {closeButtonLabel}
          </Button>
        )}
        <Button className="btn rounded-btn" onClick={onAction}>
          {confirmButtonLabel}
        </Button>
      </div>
    </Modal>
  )
}
