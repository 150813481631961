import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { camelCase, capitalize } from 'lodash'

import {
  rem,
  filterFees,
  getUniqueValues,
  extractExtraName,
  humanFormatNumber,
  extractVehicleDetails,
} from '@/utils'
import { useLanguages, useReservation } from '@/hooks'
import { CustomImage, Modal } from '@/components'

import accordionDownIcon from '@/assets/images/icons/accordion-down.svg'

export const EstimationModal: React.FC = () => {
  const disabled = useRef(false)
  const { t, isRussian, currency } = useLanguages()
  const [isOpen, setOpen] = useState(false)
  const [showFees, setShowFees] = useState(false)
  const { reservationDetails, extras, equipments } = useReservation()
  const {
    fees,
    vehicle,
    totalCharge,
    rateDistance,
    vehicleCharges,
    extras: _selectedExtras,
  } = reservationDetails || {}

  const _fees = useMemo(() => fees?.filter(filterFees) || [], [fees])

  const onToggleEstimationModal = useCallback(() => {
    if (disabled.current) return
    setOpen(prev => !prev)
  }, [])

  useEffect(() => {
    disabled.current = !vehicle
  }, [vehicle])

  useEffect(() => {
    if (!isOpen) {
      setShowFees(false)
    }
  }, [isOpen])

  useEffect(() => {
    // @ts-ignore
    window.onToggleEstimationModal = onToggleEstimationModal
  }, [onToggleEstimationModal])

  const toggleFee = useCallback(() => {
    setShowFees(prev => !prev)
  }, [])

  const vehicleCategoryName = useMemo(
    () => vehicle?.vehicleType?.vehicleCategoryName,
    [vehicle?.vehicleType?.vehicleCategoryName],
  )

  const vehicleMakeModel = useMemo(
    () => vehicle?.vehicleMakeModel?.name,
    [vehicle?.vehicleMakeModel?.name],
  )

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      modalContentClassName="min"
      onClose={onToggleEstimationModal}
    >
      <h1 className="modal-subtitle">{t('estimatedTotal')}</h1>
      <div className="modal-hr"></div>
      <div className="modal-list-title">
        {isRussian
          ? //  @ts-ignore
            t(vehicleCategoryName?.toLowerCase())
          : capitalize(vehicleCategoryName)}
      </div>
      <ul className="modal-list-ul">
        <li>
          {capitalize(
            isRussian
              ? // @ts-ignore
                t(camelCase(vehicleMakeModel))
              : capitalize(vehicleMakeModel),
          )}
        </li>
        <li>
          {isRussian
            ? // @ts-ignore
              t(vehicle?.transmissionType?.toLowerCase?.())
            : capitalize(vehicle?.transmissionType)}
        </li>
      </ul>
      <div className="modal-hr"></div>
      {vehicleCharges
        ?.filter?.(_v => +_v.amount !== 0)
        .map((charge, idx) => (
          <React.Fragment key={idx}>
            <div className="modal-total-box">
              <p className="modal-total-item">
                {extractVehicleDetails(charge, t)}
              </p>
              <p className="modal-total-item">
                {t('currency', {
                  value: charge.amount,
                  currency: charge.currencyCode,
                })}
              </p>
            </div>
          </React.Fragment>
        ))}
      {`${rateDistance?.unlimited}` === 'true' && (
        <div className="modal-total-box">
          <p className="modal-total-item">{t('reservationUnlimitedMileage')}</p>
          <p className="modal-total-item">{t('reservationIncluded')}</p>
        </div>
      )}
      {getUniqueValues(
        _selectedExtras?.coverage?.filter(_v => _v.action !== 'Cancel') || [],
        'type',
      ).map(type => {
        return extras?.map?.((_v, idx) => {
          if (_v.coverageType !== type || parseInt(_v.charge.amount) === 0) {
            return null
          }
          return (
            <React.Fragment key={idx}>
              <div className="modal-total-box">
                <p className="modal-total-item">
                  {extractExtraName(_v, t, isRussian, _v.calculation)}
                </p>
                <p className="modal-total-item">
                  {t('currency', {
                    currency: _v.charge?.currencyCode ?? currency.name,
                    value: _v?.calculation?.total ?? _v.charge.amount,
                  })}
                </p>
              </div>
            </React.Fragment>
          )
        })
      })}
      {getUniqueValues(
        _selectedExtras?.equipments?.filter(_v => _v.action !== 'Cancel') || [],
        'type',
      ).map(type => {
        return equipments?.map?.((_v, idx) => {
          if (
            _v.equipment.equipType !== type ||
            parseInt(_v.charge.amount) === 0
          ) {
            return null
          }
          return (
            <React.Fragment key={idx}>
              {_v.calculation.map((calc, idx) => (
                <div key={idx} className="modal-total-box">
                  <p className="modal-total-item">
                    {extractExtraName(_v, t, isRussian, calc)}
                  </p>
                  <p className="modal-total-item">
                    {t('currency', {
                      currency: _v.charge?.currencyCode ?? currency.name,
                      value: calc.total ?? +calc.quantity * +calc.unitCharge,
                    })}
                  </p>
                </div>
              ))}
            </React.Fragment>
          )
        })
      })}
      <div className="modal-hr"></div>
      <div className="fee-details row justify-between align-center">
        <p className="taxes-fees" onClick={toggleFee}>
          {t('reservationTaxesAndFees')}
          {!!_fees.length && (
            <CustomImage
              width={19}
              height={19}
              src={accordionDownIcon.src}
              style={{
                marginLeft: rem(10),
                objectFit: 'contain',
                ...(showFees ? { rotate: '180deg' } : {}),
              }}
            />
          )}
        </p>
        {!showFees && !!_fees.length && (
          <p>
            {t('currency', {
              value: humanFormatNumber(
                _fees.reduce(
                  (acc, cur) => acc + +cur.amount.replaceAll(/[,]/g, ''),
                  0,
                ),
                ',',
              ),
              currency: currency.name,
            })}
          </p>
        )}
        {!_fees.length && <p>{t('reservationIncluded')}</p>}
      </div>
      {showFees &&
        _fees.map((fee, idx) => (
          <React.Fragment key={idx}>
            <div className="modal-total-box">
              <p className="modal-total-item">
                {isRussian
                  ? // @ts-ignore
                    t(camelCase(fee.description))
                  : capitalize(fee.description)}
              </p>
              <p className="modal-total-item">
                {t('currency', {
                  currency: fee.currencyCode,
                  value: fee.amount,
                })}
              </p>
            </div>
          </React.Fragment>
        ))}
      <div className="modal-hr"></div>
      <div className="modal-estimated-total">
        <div className="modal-estimated-total-item">{t('estimatedTotal')}</div>
        <div className="modal-estimated-total-item">
          {t('currency', {
            currency: totalCharge?.currencyCode,
            value: totalCharge?.estimatedTotalAmount,
          })}
        </div>
      </div>
      <div className="modal-info">{t('reservationNotReflectRates')}</div>
    </Modal>
  )
}
