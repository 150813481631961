import React from 'react'
import classNames from 'classnames'

import { ILoadingSkeletonProps } from './types'
import './styles.scss'

export const LoadingSkeleton: React.FC<ILoadingSkeletonProps> = ({
  className,
  WrapEl = 'div',
  width,
  height,
  borderRadius,
  ...rest
}) => {
  return (
    <WrapEl
      className={classNames('skeleton-container', className)}
      style={{
        height,
        width,
        borderRadius,
      }}
      {...rest}
    />
  )
}

export default LoadingSkeleton
