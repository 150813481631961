import React from 'react'
import moment from 'moment'

import { useLanguages, useReservationSuccess } from '@/hooks'
import { CustomImage } from '@/components'

import pickReturnIcon from '@/assets/images/icons/pick-return-arrow.svg'

const DATE_FORMAT = 'dddd, MMM D, YYYY'
const TIME_FORMAT = 'HH:mm A'

export const ReservationLocations: React.FC = () => {
  const { t } = useLanguages()
  const { confirmed } = useReservationSuccess()
  const { reservation, location_info } = confirmed || {}
  const { vehRentalCore } = reservation || {}

  return (
    <section className="pick-return printable">
      <div className="reservation-container">
        <div className="pick-return-box">
          <CustomImage
            width={32}
            height={182}
            src={pickReturnIcon.src}
            className="pick-return-arrow"
            style={{ objectFit: 'contain' }}
          />
          <div className="row">
            <div className="w-50">
              <div className="pick-return-info">
                <p className="pick-return-text">{t('pickup')}</p>
                <h4 className="pick-return-title">
                  {location_info?.pickUpLocation}
                </h4>
                <h4 className="pick-return-title">
                  {moment(vehRentalCore?.pickUpDateTime).format(DATE_FORMAT)}
                </h4>
                <h4 className="pick-return-title">
                  {moment(vehRentalCore?.pickUpDateTime).format(TIME_FORMAT)}
                </h4>
              </div>
            </div>
            <div className="w-50">
              <div className="pick-return-info">
                <p className="pick-return-text">{t('return')}</p>
                <h4 className="pick-return-title">
                  {location_info?.dropOffLocation ??
                    location_info?.pickUpLocation}
                </h4>
                <h4 className="pick-return-title">
                  {moment(vehRentalCore?.returnDateTime).format(DATE_FORMAT)}
                </h4>
                <h4 className="pick-return-title">
                  {moment(vehRentalCore?.returnDateTime).format(TIME_FORMAT)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
