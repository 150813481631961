import React, { useCallback } from 'react'
import classNames from 'classnames'

import { useLanguages, useReservation } from '@/hooks'
import { Button, Icon } from '@/components'
import { joinArray } from '@/utils'
import { IBranch } from '@/types'

interface ISelectLocationProps {
  isActive: boolean
  onShowDetails: (_location?: IBranch) => void
}

export const SelectLocation: React.FC<ISelectLocationProps> = ({
  isActive,
  onShowDetails,
}) => {
  const { locations } = useReservation()

  return (
    <div
      className={classNames('select-location-cards', {
        locationtabcontent: !isActive,
      })}
    >
      {locations.map((location, idx) => (
        <LocationCard
          key={idx}
          point={`${idx + 1}`}
          location={location}
          onShowDetails={onShowDetails}
          title={location.Branch_Station_City}
          address={joinArray(
            '<br />',
            location.Branch_Station_Address_1,
            location.Branch_Station_Address_2,
          )}
        />
      ))}
    </div>
  )
}

interface ILocationCardProps {
  point: string
  title: string
  address: string
  location: IBranch
  onShowDetails: (_location?: IBranch) => void
}

const LocationCard: React.FC<ILocationCardProps> = ({
  point,
  title,
  address,
  location,
  onShowDetails,
}) => {
  const { t } = useLanguages()
  const { onUpdate, locationStep, isMobile } = useReservation()

  const onSelect = useCallback(() => {
    const stepName = locationStep === 2 ? 'dropOff' : 'pickUp'
    onUpdate({
      [`${stepName}Location`]: location.Branch_Name,
      [`${stepName}Latitude`]: `${location.Branch_Station_Latitude || ''}`,
      [`${stepName}Longitude`]: `${location.Branch_Station_Longitude || ''}`,
      [`${stepName}StationCode`]: location.Station_Code ?? null,
    })
  }, [location, locationStep, onUpdate])

  return (
    <div className="select-location-card">
      <div className="select-location-card-full">
        <div className="select-location-card-badges">
          <div className="pin-badge">{point}</div>
        </div>
        <div className="select-location-card-info">
          <h1 className="select-location-title">{title}</h1>
          <div
            className="select-location-address"
            dangerouslySetInnerHTML={{ __html: address || '' }}
          />
        </div>
      </div>
      <div className="line"></div>
      <div
        className={classNames('select-location-card-buttons', {
          end: isMobile,
        })}
      >
        {!isMobile && (
          <div
            className="hours-services"
            onClick={() => onShowDetails(location)}
          >
            {t('reservationHoursAndServices')}
            <Icon name="chevron-right" />
          </div>
        )}
        <Button className="location-btn" onClick={onSelect}>
          {t('select')}
        </Button>
      </div>
    </div>
  )
}
