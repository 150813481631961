import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next-intl/client'
import classNames from 'classnames'

import { generatePath, getImage, getStorageValue } from '@/utils'
import {
  IMeta,
  IBlogPage,
  IResponse,
  IServiceType,
  IBlogItemType,
} from '@/types'
import { API_ENDPOINT, DEFAULT_LANG, LANG_KEY } from '@/constants'
import { Button, Observer } from '@/components'
import endpoints from '@/apis/endpoints'
import { useLanguages } from '@/hooks'

interface ISuggestionsSectionProps {
  isBlog?: boolean
  pageMeta?: IMeta
  showTitle?: boolean
  className?: string
  items: (IServiceType | IBlogItemType)[]
}

export const SuggestionsSection: React.FC<ISuggestionsSectionProps> = ({
  items,
  isBlog,
  pageMeta,
  className,
  showTitle = false,
}) => {
  const router = useRouter()
  const { t } = useLanguages()
  const canLoad = useRef(false)
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState<IMeta>({
    page: 1,
    perPage: 9,
    totalCount: 0,
  })
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState<
    Record<string, (IBlogItemType | IServiceType)[]>
  >({})

  useEffect(() => {
    setListData({
      1: items,
    })
  }, [items])

  useEffect(() => {
    if (pageMeta) {
      setMeta(pageMeta)
    }
  }, [pageMeta])

  const loadMore = useCallback(async () => {
    if (!canLoad.current) return
    try {
      canLoad.current = false
      setLoading(true)
      const lang = getStorageValue(LANG_KEY, DEFAULT_LANG)
      const res = await fetch(
        generatePath(
          `${API_ENDPOINT}/api/${lang}/${endpoints.blog}`,
          undefined,
          [
            {
              page,
              size: '9',
            },
          ],
        ),
      )
      const data: IResponse<IBlogPage> = await res.json()
      setListData(prev => ({ ...prev, [page]: data.data.items ?? [] }))
      setMeta(data.data.meta)
    } catch (error) {
      //
    } finally {
      setLoading(false)
    }
  }, [page])

  useEffect(() => {
    if (!isBlog) return
    loadMore()
  }, [loadMore, isBlog])

  const _pagedData = useMemo(
    () =>
      Object.keys(listData)
        .map(key => listData[key])
        .reduce((acc, cur) => [...acc, ...cur], []),
    [listData],
  )

  const onOpenLink = useCallback(
    (suggestion: IBlogItemType | IServiceType) => () => {
      // @ts-ignore
      if (suggestion.button_link) {
        // @ts-ignore
        return window.open(suggestion.button_link, '_self')
      }

      // @ts-ignore
      if (suggestion.slug) {
        // @ts-ignore
        return router.push(`/${suggestion.slug}`)
      }
    },
    [router],
  )

  const extractDescription = useCallback(
    (suggestion: IServiceType | IBlogItemType) => {
      // @ts-ignore
      if (!!suggestion.excerpt) {
        // @ts-ignore
        return suggestion.excerpt
      }
      // @ts-ignore
      if (!!suggestion.description) {
        // @ts-ignore
        return suggestion.description
      }
      return false
    },
    [],
  )

  const hasMore = useMemo(
    () => meta?.totalCount > _pagedData.length,
    [meta, _pagedData],
  )

  const onLoad = useCallback(() => {
    if (!hasMore) return
    canLoad.current = true
    setPage(prev => ++prev)
  }, [hasMore])

  return (
    <section className={classNames('suggestion-cards', className)}>
      {showTitle && (
        <h1 className="section-title text-center mb-5 mt-5">
          {t('blogPostSection')}
        </h1>
      )}
      <div className="row justify-start">
        {_pagedData?.map?.((suggestion, idx) => (
          <div key={idx} className="w-33">
            <div className="suggestion-card">
              <div
                className="suggestion-card-img"
                style={{ backgroundImage: getImage(suggestion.image) }}
              ></div>
              <div className="suggestion-card-info">
                <h3 className="suggestion-card-title">{suggestion.title}</h3>
                {!!extractDescription(suggestion) && (
                  <div
                    className="suggestion-card-text"
                    dangerouslySetInnerHTML={{
                      __html: extractDescription(suggestion),
                    }}
                  />
                )}
                <Button
                  className="btn mt-auto"
                  onClick={onOpenLink(suggestion)}
                >
                  {t('learnMore')}
                </Button>
              </div>
            </div>
          </div>
        ))}
        {isBlog && <Observer loading={loading} onIntersect={onLoad} />}
      </div>
    </section>
  )
}
