import React from 'react'

import { useLanguages, useReservation } from '@/hooks'
import { camelCase, capitalize } from 'lodash'

export const Vehicle: React.FC = () => {
  const { t, isRussian } = useLanguages()
  const { reservationDetails } = useReservation()

  const { vehicle } = reservationDetails || {}

  return (
    <>
      {vehicle && (
        <p>
          {capitalize(
            isRussian
              ? // @ts-ignore
                t(camelCase(vehicle.vehicleMakeModel.name))
              : capitalize(vehicle.vehicleMakeModel.name),
          )}
        </p>
      )}
    </>
  )
}

export const VehicleMobile: React.FC = () => {
  const { t, isRussian } = useLanguages()
  const { reservationDetails } = useReservation()

  const { vehicle } = reservationDetails || {}

  return (
    <>
      {vehicle && (
        <p className="stepper-opened-menu-value">
          {capitalize(
            isRussian
              ? // @ts-ignore
                t(camelCase(vehicle.vehicleMakeModel.name))
              : capitalize(vehicle.vehicleMakeModel.name),
          )}
        </p>
      )}
    </>
  )
}
