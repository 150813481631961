import React from 'react'

import { IWithSkeletonProps } from './types'

export const WithSkeleton: React.FC<IWithSkeletonProps> = ({
  loading,
  skeleton,
  children,
}) => {
  if (loading) {
    return <>{skeleton}</>
  }

  return <>{children}</>
}

export default WithSkeleton
