import React, { useEffect } from 'react'

import {
  useMap,
  APIProvider,
  AdvancedMarker,
  Map as GoogleMap,
} from '@vis.gl/react-google-maps'

import { ILocationPage } from '@/types'

import { MapPin } from '../Reservation'
import { isNull } from 'lodash'

const getLocation = (latitude: number | null, longitude: number | null) => ({
  lat: !isNull(latitude) ? +latitude : 40.18109,
  lng: !isNull(longitude) ? +longitude : 44.51509,
})

const LocationsMapView: React.FC<ILocationPage> = ({ longitude, latitude }) => {
  const map = useMap()

  useEffect(() => {
    if (map) {
      map.moveCamera({
        center: getLocation(latitude, longitude),
      })
    }
  }, [latitude, longitude, map])

  return (
    <GoogleMap
      zoomControl
      disableDefaultUI
      defaultZoom={11}
      mapId="custom-map-id"
      fullscreenControl={false}
      zoomControlOptions={{ position: 3 }}
      style={{ width: '100%', height: '100%' }}
      defaultCenter={getLocation(latitude, longitude)}
    >
      <AdvancedMarker position={getLocation(latitude, longitude)}>
        <MapPin id="1" />
      </AdvancedMarker>
    </GoogleMap>
  )
}

export const LocationsMap: React.FC<ILocationPage> = props => (
  <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string}>
    <LocationsMapView {...props} />
  </APIProvider>
)
