'use client'

import React, { useCallback, useRef } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { capitalize, omit } from 'lodash'
import moment from 'moment'
import axios from 'axios'

import {
  ICarType,
  DateRange,
  ICarListItem,
  PopupVariant,
  TTransferSchema,
} from '@/types'
import {
  Form,
  Modal,
  Button,
  TextInput,
  DateRangePicker,
  PhoneNumberInput,
} from '@/components'
import { generateFormData, transferForm } from '@/utils'
import { useHookForm, useLanguages } from '@/hooks'
import { API_ENDPOINT } from '@/constants'
import endpoints from '@/apis/endpoints'

interface IRequestPriceProps {
  onClose?: () => void
  item: ICarType & ICarListItem
  onTrigger?: (_variant: PopupVariant) => void
}

export const RequestPrice: React.FC<IRequestPriceProps> = ({
  item,
  onClose,
  onTrigger,
}) => {
  const { t } = useLanguages()
  const ref = useRef<React.ElementRef<typeof DateRangePicker>>(null)
  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(transferForm, {
    defaultValues: {
      pickUpTime: '12:00',
      car_lease_id: item?.car_id || item?.car?.id || item?.id,
      pickUpDate: moment().add(1, 'day').startOf('day').toDate(),
    },
  })

  const onSubmit: SubmitHandler<TTransferSchema> = useCallback(
    async data => {
      try {
        const formData = generateFormData({
          ...omit(data, ['pickUpTime', 'pickupDate']),
          date: `${moment(data.pickUpDate).format('YYYY-MM-DD')} ${moment(
            data.pickUpTime,
            'HH:mm',
          ).format('HH:mm:ss')}`,
        })
        const response = await axios.post(
          `${API_ENDPOINT}${endpoints.transfer}`,
          formData,
        )
        if (response.status === 200) {
          onClose?.()
          const timeout = setTimeout(() => {
            onTrigger?.('success')
            clearTimeout(timeout)
          }, 200)
        }
      } catch (error: any) {
        onClose?.()
        const timeout = setTimeout(() => {
          onTrigger?.('error')
          clearTimeout(timeout)
        }, 200)
      }
    },
    [onClose, onTrigger],
  )

  const onDateSelect = useCallback(
    (_dateRange?: DateRange) => {
      // @ts-ignore
      setValue('pickUpDate', _dateRange?.from)
      trigger('pickUpDate')
      ref.current?.onClose()
    },
    [setValue, trigger],
  )

  const onSelectTime = useCallback(
    (time: string) => {
      setValue(`pickUpTime`, time)
      trigger(`pickUpTime`)
    },
    [setValue, trigger],
  )

  return (
    <Modal
      isOpen
      closeOnBackdrop
      onClose={onClose}
      title={t('transferServicesPopup')}
      modalBodyClassName="modal-scroll"
    >
      <div className="form-fields-box text-transform-0">
        <Form className="form-fields" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('firstName')}
            label={t('firstName')}
            error={errors.firstName?.message}
            placeholder={t('firstName')}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('lastName')}
            label={t('lastName')}
            error={errors.lastName?.message}
            placeholder={t('lastName')}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('paxNumber')}
            error={errors.paxNumber?.message}
            label={capitalize(t('paxNumber'))}
            placeholder={capitalize(t('paxNumber'))}
          />
          <PhoneNumberInput
            required
            className="w-50"
            hasSuffix={false}
            onChange={value => {
              setValue('contactNumber', value)
              trigger('contactNumber')
            }}
            label={t('contactNumber')}
            value={getValues('contactNumber')}
            error={errors.contactNumber?.message}
            placeholder={t('contactNumber')}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('pickUpLocation')}
            error={errors.pickUpLocation?.message}
            label={capitalize(t('transferpickup'))}
            placeholder={capitalize(t('transferpickup'))}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('dropOffLocation')}
            error={errors.dropOffLocation?.message}
            label={capitalize(t('transferreturn'))}
            placeholder={capitalize(t('transferreturn'))}
          />
          <div className="w-33 d-flex flex-m-wrap mb-3 custom-datepicker">
            <DateRangePicker
              single
              ref={ref}
              type="pickup"
              value={{
                to: undefined,
                from: getValues('pickUpDate'),
              }}
              className="w-100"
              numberOfMonths={1}
              label={t('pickup')}
              onChange={onDateSelect}
              placement="bottom-start"
              onChangeTime={onSelectTime}
              time={getValues('pickUpTime')}
            />
          </div>
          <TextInput
            required
            className="w-100"
            hasSuffix={false}
            {...register('emailAddress')}
            label={t('emailAddress')}
            placeholder={t('emailAddress')}
            error={errors.emailAddress?.message}
          />
          <div className="w-100 d-flex justify-end justify-m-center">
            <Button type="submit" className="btn big">
              {t('requestPrice')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
