import React from 'react'

import { IBannerType, IWelcomeType } from '@/types'
import { CustomImage } from '@/components'
import { getImage } from '@/utils'

export const WelcomeSection: React.FC<
  { items: IWelcomeType[] } & Partial<IBannerType>
> = ({ items, title, image }) => {
  return (
    <section className="welcome">
      <div className="welcome-box">
        <p className="section-title text-center">{title}</p>
        <div className="row d-flex align-center justify-between">
          <div className="w-33 advantages-box-order">
            <div className="advantages">
              {items?.map((item, idx) => (
                <div key={idx} className="advantage-box">
                  {!!item.icon && (
                    <CustomImage
                      width={30}
                      height={30}
                      src={getImage(item.icon, false)}
                    />
                  )}
                  <p className="advantage-title">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-66 advantages-img-order">
            <img
              width={650}
              height={377}
              className="w-100"
              alt="Advantages img"
              src={getImage(image, false)}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
