import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { camelCase, capitalize } from 'lodash'

import {
  filterFees,
  getUniqueValues,
  extractExtraName,
  extractNumberParts,
  humanFormatNumber,
  extractVehicleDetails,
} from '@/utils'
import { useLanguages, useReservation } from '@/hooks'
import { Button, CustomImage } from '@/components'

import popularCarIcon from '@/assets/images/icons/popular-cars-menu-1.svg'
import accordionDownIcon from '@/assets/images/icons/accordion-down.svg'

export const VehicleDetails: React.FC = () => {
  const { t, currency, isRussian } = useLanguages()
  const [showTaxes, setShowTaxes] = useState(false)
  const { toStep, extras, equipments, reservationDetails, coupon } =
    useReservation()
  const {
    fees,
    vehicle,
    totalCharge,
    rateDistance,
    vehicleCharges,
    extras: _selectedExtras,
  } = reservationDetails || {}

  const _total = useMemo(
    () => (totalCharge?.estimatedTotalAmount || '').replaceAll(/[,]/g, ''),
    [totalCharge?.estimatedTotalAmount],
  )

  const [integerPart] = useMemo(() => extractNumberParts(_total), [_total])

  const _fees = useMemo(() => fees?.filter(filterFees) || [], [fees])

  const onToggleTaxes = useCallback(() => {
    if (!_fees.length) return
    setShowTaxes(prev => !prev)
  }, [_fees])

  const vehicleCategoryName = useMemo(
    () => vehicle?.vehicleType?.vehicleCategoryName,
    [vehicle?.vehicleType?.vehicleCategoryName],
  )

  const vehicleMakeModel = useMemo(
    () => vehicle?.vehicleMakeModel?.name,
    [vehicle?.vehicleMakeModel?.name],
  )

  return (
    <div className="review-box">
      <h2 className="review-box-title">{t('reservationVehicleDetails')}</h2>
      <div className="d-flex justify-between align-center">
        <div className="vehicle-car-details">
          <h2 className="vehicle-car-details-title">
            {isRussian
              ? // @ts-ignore
                t(vehicleCategoryName?.toLowerCase())
              : capitalize(vehicleCategoryName)}
          </h2>
          <h4 className="vehicle-car-details-subtitle">
            {capitalize(
              isRussian
                ? // @ts-ignore
                  t(camelCase(vehicleMakeModel))
                : capitalize(vehicleMakeModel),
            )}
          </h4>
          <p className="vehicle-car-details-type">
            <CustomImage width={15} height={15} src={popularCarIcon.src} />
            {isRussian
              ? // @ts-ignore
                t(vehicle?.transmissionType?.toLowerCase?.())
              : capitalize(vehicle?.transmissionType)}
          </p>
        </div>
        <img
          width={170}
          height={170}
          alt="Car img"
          className="vehicle-car-img"
          style={{ objectFit: 'contain' }}
          src={vehicle?.pictureURL || ''}
        />
      </div>
      <div className="review-title row justify-between align-center mb-1">
        <p className="review-box-detail-info-text">
          <b>{t('reservationVehicle')}</b>
        </p>
        <Button type="button" className="edit-btn" onClick={() => toStep(3)}>
          {t('edit')}
        </Button>
      </div>
      {vehicleCharges
        ?.filter?.(_v => +_v.amount !== 0)
        .map((charge, idx) => (
          <div key={idx} className="d-flex justify-between align-start">
            <p className="review-box-detail-info-text">
              {extractVehicleDetails(charge, t)}
            </p>
            <p className="review-box-detail-info-text">
              {t('currency', {
                value: charge.amount,
                currency: charge.currencyCode,
              })}
            </p>
          </div>
        ))}
      {`${rateDistance?.unlimited}` === 'true' && (
        <div className="d-flex justify-between align-center">
          <p className="review-box-detail-info-text">
            {t('reservationUnlimitedMileage')}
          </p>
          <p className="review-box-detail-info-text">
            {t('reservationIncluded')}
          </p>
        </div>
      )}
      <div className="review-title row justify-between align-center mb-1 mt-3">
        <p className="review-box-detail-info-text">
          <b>{t('reservationExtras')}</b>
        </p>
        <Button type="button" className="edit-btn" onClick={() => toStep(4)}>
          {t('edit')}
        </Button>
      </div>
      {getUniqueValues(
        _selectedExtras?.coverage?.filter(_v => _v.action !== 'Cancel') || [],
        'type',
      ).map(type => {
        return extras.map((_v, idx) => {
          if (_v.coverageType !== type || parseInt(_v.charge.amount) === 0) {
            return null
          }
          return (
            <div key={idx} className="d-flex justify-between align-start">
              <p className="review-box-detail-info-text">
                {extractExtraName(_v, t, isRussian, _v.calculation)}
              </p>
              <p className="review-box-detail-info-text">
                {t('currency', {
                  currency: currency.name,
                  value: _v?.calculation?.total,
                })}
              </p>
            </div>
          )
        })
      })}
      {getUniqueValues(
        _selectedExtras?.equipments?.filter(_v => _v.action !== 'Cancel') || [],
        'type',
      ).map(type => {
        return equipments?.map?.((_v, idx) => {
          if (
            _v.equipment.equipType !== type ||
            parseInt(_v.charge.amount) === 0
          ) {
            return null
          }
          return (
            <React.Fragment key={idx}>
              {_v.calculation.map((calc, idx) => (
                <div key={idx} className="d-flex justify-between align-start">
                  <p className="review-box-detail-info-text">
                    {extractExtraName(_v, t, isRussian, calc)}
                  </p>
                  <p className="review-box-detail-info-text">
                    {t('currency', {
                      currency: _v.charge?.currencyCode ?? currency.name,
                      value: calc.total ?? _v.charge.amount,
                    })}
                  </p>
                </div>
              ))}
            </React.Fragment>
          )
        })
      })}
      {!!coupon && (
        <>
          <div className="review-title row justify-between align-center mb-1 mt-3">
            <p className="review-box-detail-info-text">
              <b>{t('adjustments')}</b>
            </p>
          </div>
          <div className="d-flex justify-between align-start">
            <p className="review-box-detail-info-text m-w-unset">
              {t('code', {
                percent: coupon?.percentage,
                audience: `${coupon?.audience} (${coupon?.percentage}%)`,
              }).toUpperCase()}
            </p>
          </div>
        </>
      )}
      <div className="review-taxes">
        <div className="d-flex justify-between align-center">
          <p
            onClick={onToggleTaxes}
            className={classNames('review-box-detail-info-text dropdown', {
              show: showTaxes,
            })}
          >
            <b>{t('reservationTaxesAndFees')}</b>
            {!!_fees.length && (
              <CustomImage
                width={19}
                height={19}
                className="up"
                src={accordionDownIcon.src}
                style={{ objectFit: 'contain' }}
              />
            )}
          </p>
          <p className="review-box-detail-info-text">
            {!showTaxes && (
              <>
                {_fees.length
                  ? t('currency', {
                      currency: currency.name,
                      value: humanFormatNumber(
                        _fees.reduce(
                          (acc, cur) =>
                            acc + +cur.amount.replaceAll(/[,]/g, ''),
                          0,
                        ),
                        ',',
                      ),
                    })
                  : t('reservationIncluded')}
              </>
            )}
          </p>
        </div>
        {showTaxes &&
          _fees.map((fee, idx) => (
            <div key={idx} className="d-flex justify-between align-center">
              <p className="review-box-detail-info-text">
                {isRussian
                  ? // @ts-ignore
                    t(camelCase(fee.description))
                  : capitalize(fee.description)}
              </p>
              <p className="review-box-detail-info-text">
                {t('currency', {
                  currency: fee.currencyCode ?? currency.name,
                  value: fee.amount,
                })}
              </p>
            </div>
          ))}
      </div>
      <div className="d-flex justify-between align-center">
        <p className="review-box-detail-info-text">{t('estimatedTotal')}</p>
        <p className="estimated-total">
          <span className="text">{totalCharge?.currencyCode}</span>
          <span className="total">{humanFormatNumber(integerPart, ',')}</span>
          <span className="text">
            .{(+_total)?.toFixed?.(2).split('.')?.[1]}
          </span>
        </p>
      </div>
    </div>
  )
}
