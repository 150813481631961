import React from 'react'
import classNames from 'classnames'

import { useLanguages } from '@/hooks'
import { CustomImage } from '../Image'

import { type ITextInputProps } from './types'

import closeIcon from '@/assets/images/icons/close-green.svg'

export const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      label,
      name,
      onClear,
      error,
      fakeValue,
      className = '',
      onChange,
      required,
      hasSuffix = true,
      ...rest
    },
    ref,
  ) => {
    const { t } = useLanguages()

    return (
      <div className={classNames(className, { error: !!error })}>
        {!!label && (
          <label htmlFor={name}>
            {label}
            {!!required && hasSuffix && <sup> *</sup>}
          </label>
        )}
        <div
          className={classNames('input-group', {
            required,
          })}
          data-value={required ? `* ${t('required')}` : undefined}
        >
          <div className={classNames('fake-input', { 'd-none': !fakeValue })}>
            <div className="fake-input-tag">
              <p>{fakeValue}</p>
              <div className="fake-input-line"></div>
              <CustomImage
                width={19}
                height={19}
                className="icon"
                onClick={onClear}
                src={closeIcon.src}
              />
            </div>
          </div>
          <input
            {...rest}
            ref={ref}
            id={name}
            name={name}
            onChange={onChange}
            className={classNames('form-control', {
              'd-none': fakeValue,
            })}
          />
        </div>
      </div>
    )
  },
)
