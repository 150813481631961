import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

// import { CustomImage } from '@/components'
import { useLanguages } from '@/hooks'
import { IBenefit } from '@/types'
import { getImage } from '@/utils'

export const BenefitsSection: React.FC<{ items: IBenefit[] }> = ({ items }) => {
  const [active, setActive] = useState<number>(0)
  const { t } = useLanguages()

  const _setActive = useCallback(
    (_idx: number) => () => {
      setActive(_idx)
    },
    [],
  )

  return (
    <section className="car-release-benefits">
      <div className="container">
        <h2 className="benefits-title">{t('carLeaseBenefits')}</h2>
        <div className="benefits-cards">
          {items?.map((benefit, idx) => (
            <div
              key={idx}
              onClick={_setActive(idx)}
              onMouseOver={_setActive(idx)}
              className={classNames('benefits-card', {
                active: idx === active,
              })}
            >
              <img
                width={24}
                height={24}
                alt="Benefit img"
                src={getImage(benefit.icon, false)}
              />
            </div>
          ))}
        </div>
        <h3 className="benefits-cards-title">{items?.[active]?.title}</h3>
        <p className="benefits-cards-text">{items?.[active]?.description}</p>
      </div>
    </section>
  )
}
