import React from 'react'
import classNames from 'classnames'

import { Icon } from '../Icon'

import { IButtonProps } from './types'

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    { loading, disabled, iconLeft, iconRight, className, children, ...rest },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        {...rest}
        className={classNames(className, {
          disabled: !!disabled,
        })}
        disabled={disabled || loading}
      >
        {!!iconLeft && <Icon name={iconLeft} />}
        {children}
        {!!iconRight && <Icon name={iconRight} />}
        {/* {loading && <Icon name="loading" />} */}
      </button>
    )
  },
)
