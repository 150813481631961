import React, { useCallback, useImperativeHandle, useRef } from 'react'
import classNames from 'classnames'

import { usePopover } from '@/hooks'
import { TFunc } from '@/types'

import { ITriggerAction } from './types'

interface Props {
  trigger: 'on-click' | 'mouse-over'
  onClose: TFunc
  disabled: boolean
  onTrigger: TFunc
  children: React.ReactElement
}

const Trigger = React.forwardRef<ITriggerAction, Props>(
  ({ trigger, disabled, onTrigger, onClose, children }, triggerRef) => {
    const { setTriggerRect } = usePopover()

    const ref = useRef<HTMLElement>(null)

    const _onTrigger = useCallback(() => {
      if (disabled) return

      const element = ref.current
      if (element == null) return

      const rect = element.getBoundingClientRect()
      setTriggerRect(rect)
      onTrigger?.()
    }, [disabled, onTrigger, setTriggerRect])

    useImperativeHandle(
      triggerRef,
      () => ({
        onClose,
        onTrigger: _onTrigger,
      }),
      [_onTrigger, onClose],
    )

    const childrenToTriggerPopover = React.cloneElement(children, {
      className: classNames('popover-trigger', children.props.className),
      ...(trigger === 'on-click' && {
        onClick: _onTrigger,
      }),
      ...(trigger === 'mouse-over' && {
        onMouseOver: _onTrigger,
      }),
      ref,
    })

    return childrenToTriggerPopover
  },
)

export default Trigger
