import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

import { Button } from '..'

import { IAccordionProps } from './types'

export const Accordion: React.FC<IAccordionProps> = ({ items }) => {
  const [active, setActive] = useState<number>()

  const toggleAccordion = useCallback(
    (idx: number) => () => {
      setActive(prev => (prev !== idx ? idx : undefined))
    },
    [],
  )

  return (
    <div className="accordion-box">
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <Button
            className={classNames('accordion', {
              active: item.id === active,
            })}
            onClick={toggleAccordion(item.id)}
          >
            <p>{item.question}</p>
          </Button>
          <div
            className="panel"
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </React.Fragment>
      ))}
    </div>
  )
}
