import React from 'react'
import classNames from 'classnames'

import { ISectionProps } from './types'

export const Section: React.FC<ISectionProps> = ({
  id = '',
  children,
  className,
  outSideChildren,
  additionClassName,
  hasBackground = true,
}) => {
  return (
    <section
      id={id}
      className={classNames(`${className}-section`, additionClassName, {
        'bg-none': !hasBackground,
      })}
    >
      {outSideChildren}
      {!!children && (
        <div className={classNames(`${className}-container`)}>{children}</div>
      )}
    </section>
  )
}
