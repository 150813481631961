import classNames from 'classnames'
import React, { useCallback } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

import { useLanguages } from '@/hooks'

import { IPhoneNumberInputProps } from './types'

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = ({
  error,
  name,
  label,
  onChange,
  required,
  className,
  hasSuffix,
  placeholder,
  ...rest
}) => {
  const { t } = useLanguages()

  const _onChange = useCallback(
    (
      _value: string,
      _data: CountryData | any,
      e: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string,
    ) => {
      onChange?.(formattedValue)
    },
    [onChange],
  )

  return (
    <div className={classNames(className, { error: !!error })}>
      {!!label && (
        <label htmlFor={name}>
          {label}
          {!!required && hasSuffix && <sup> *</sup>}
        </label>
      )}
      <div
        className={classNames('phone-input', {
          required,
        })}
        data-value={required ? `* ${t('required')}` : undefined}
      >
        <PhoneInput
          country="am"
          placeholder={placeholder}
          {...rest}
          onChange={_onChange}
        />
      </div>
    </div>
  )
}
