import React from 'react'
import classNames from 'classnames'

export const FilterModal: React.FC<{
  isOpen?: boolean
  children: React.ReactNode
}> = ({ isOpen, children }) => {
  return (
    <div
      className={classNames('filter-modal-overlay', {
        open: isOpen,
      })}
    >
      <div className="filter-modal">{children}</div>
    </div>
  )
}
