import { useEffect } from 'react'

import { TFunc } from '@/types'

export const useOnResize = (cb: TFunc) => {
  useEffect(() => {
    window.addEventListener('resize', cb)
    return () => {
      window.removeEventListener('resize', cb)
    }
  }, [cb])
}
