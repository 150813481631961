'use client'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Map,
  useMap,
  APIProvider,
  AdvancedMarker,
} from '@vis.gl/react-google-maps'
import { capitalize } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'

import { generatePath, joinArray, openDays, replacePhoneNumber } from '@/utils'
import { useLanguages, useReservationSuccess } from '@/hooks'
import { CustomImage, MapPin, TextLink } from '@/components'
import { IBranch, TFunc } from '@/types'

const DATE_FORMAT = 'dddd, MMM D, YYYY @'

// import locationPinIcon from '@/assets/images/icons/location-on-black.svg'
import accordionDownIcon from '@/assets/images/icons/accordion-down.svg'
import accordionUpIcon from '@/assets/images/icons/accordion-up.svg'
// import infoIcon from '@/assets/images/icons/info.svg'

export const LocationsAccordion: React.FC = () => {
  const { t } = useLanguages()
  const { confirmed } = useReservationSuccess()
  const { reservation, locationData } = confirmed || {}
  const { vehRentalCore } = reservation || {}
  const [showItem, setShowItem] = useState<'pickup' | 'return'>('pickup')

  const onShow = useCallback(
    (type: 'pickup' | 'return') => () => {
      setShowItem(type)
    },
    [],
  )

  return (
    <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string}>
      <div className="rental-details-accordion">
        <LocationsAccordionItem
          label={t('pickup')}
          onClick={onShow('pickup')}
          show={showItem === 'pickup'}
          date={`${moment(vehRentalCore?.pickUpDateTime).format(
            DATE_FORMAT,
          )} ${moment(vehRentalCore?.pickUpDateTime).format('HH:mm A')}`}
          location={locationData?.pickUpLocation}
        />
        <LocationsAccordionItem
          label={t('return')}
          onClick={onShow('return')}
          show={showItem === 'return'}
          date={`${moment(vehRentalCore?.returnDateTime).format(
            DATE_FORMAT,
          )} ${moment(vehRentalCore?.returnDateTime).format('HH:mm A')}`}
          location={locationData?.returnLocation}
        />
      </div>
    </APIProvider>
  )
}

interface ILocationsAccordionItemProps {
  date: string
  label: string
  show?: boolean
  onClick?: TFunc
  location?: IBranch
}

const LocationsAccordionItem: React.FC<ILocationsAccordionItemProps> = ({
  date,
  show,
  label,
  onClick,
  location,
}) => {
  const map = useMap()
  const { appLang } = useLanguages()

  const { latitude, longitude } = useMemo(() => {
    return {
      latitude: location?.Branch_Station_Latitude,
      longitude: location?.Branch_Station_Longitude,
    }
  }, [location])

  useEffect(() => {
    if (map && latitude && longitude) {
      map.moveCamera({
        center: {
          lat: latitude,
          lng: longitude,
        },
      })
    }
  }, [latitude, longitude, map])

  const { t } = useLanguages()

  const workingDates = useMemo(
    () => (location ? openDays(location) : []),
    [location],
  )

  const replace = useCallback(
    (value: string) => {
      if (appLang === 'ru') {
        return value.replaceAll('Hrs', 'часа')
      }
      return value
    },
    [appLang],
  )

  return (
    <div className="rental-details-accordion-item">
      <div
        onClick={onClick}
        className={classNames('rental-details-accordion-header', {
          show,
        })}
      >
        <h4 className="rental-details-accordion-title">{label}</h4>
        <CustomImage
          width={19}
          height={19}
          className="down"
          src={accordionDownIcon.src}
          style={{ objectFit: 'contain' }}
        />
        <CustomImage
          width={19}
          height={19}
          className="up"
          src={accordionUpIcon.src}
          style={{ objectFit: 'contain' }}
        />
      </div>
      <div
        className={classNames('rental-details-accordion-body', {
          show,
        })}
      >
        <div className="row">
          <div className="w-50">
            <div className="rental-details-accordion-info">
              <p className="rental-details-accordion-date">{date}</p>
              <p className="rental-details-accordion-text">
                {location?.Branch_Station_Address_1}
              </p>
              <TextLink
                defaultAction
                href={`tel:${replacePhoneNumber(
                  location?.Branch_Station_Phone,
                )}`}
                className="rental-details-accordion-link"
              >
                {replacePhoneNumber(location?.Branch_Station_Phone)}
              </TextLink>
              <p className="rental-details-accordion-text">
                {joinArray(
                  ' ',
                  location?.Branch_Station_City,
                  ',',
                  location?.Branch_Station_Country,
                  location?.Branch_Station_Postal_Code,
                )}
              </p>
              <TextLink
                defaultAction
                href={generatePath(
                  process.env.NEXT_PUBLIC_GOOGLE_MAP_PATH ?? '',
                  {
                    lat: latitude,
                    lng: longitude,
                  },
                )}
                target="_blank"
                className="rental-details-accordion-link"
              >
                {t('getDirections')}
              </TextLink>
              <p className="rental-details-accordion-text">
                {capitalize(t('hoursFor', { date: label }))}
              </p>
              {workingDates.map((days, idx) => (
                <div
                  key={idx}
                  className="rental-details-accordion-text dates w-50"
                >
                  <p>{days.label}</p>
                  <p>{replace(days.value)}</p>
                </div>
              ))}
              {/* <TextLink href="#" className="rental-details-accordion-link">
                After-Hours Policy
              </TextLink> */}
            </div>
          </div>
          <div className="w-50">
            <div className="rental-details-accordion-map">
              <Map
                zoomControl
                disableDefaultUI
                defaultZoom={11}
                mapId="custom-map-id"
                fullscreenControl={false}
                zoomControlOptions={{ position: 3 }}
                style={{ width: '100%', height: '100%' }}
                defaultCenter={
                  typeof latitude === 'number' && typeof longitude === 'number'
                    ? {
                        lat: latitude,
                        lng: longitude,
                      }
                    : undefined
                }
              >
                {typeof latitude === 'number' &&
                  typeof longitude === 'number' && (
                    <AdvancedMarker
                      position={{
                        lat: latitude,
                        lng: longitude,
                      }}
                    >
                      <MapPin id="1" />
                    </AdvancedMarker>
                  )}
              </Map>
            </div>
            {/* <div className="rental-details-accordion-badge disable">
              <CustomImage
                width={30}
                height={30}
                src={locationPinIcon.src}
                style={{ objectFit: 'contain' }}
              />
              <p>{t('reservationPickupUnavailable')}</p>
              <CustomImage
                width={20}
                height={20}
                src={infoIcon.src}
                style={{ objectFit: 'contain' }}
              />
            </div>
            <div className="rental-details-accordion-badge">
              <CustomImage
                width={30}
                height={30}
                src={locationPinIcon.src}
                style={{ objectFit: 'contain' }}
              />
              <p>{t('reservationAfterHoursUnavailable')}</p>
              <CustomImage
                width={20}
                height={20}
                src={infoIcon.src}
                style={{ objectFit: 'contain' }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
