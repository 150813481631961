import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next-intl/client'
import { camelCase, capitalize } from 'lodash'

import {
  homePath,
  joinArray,
  filterFees,
  ellipsizeMiddle,
  humanFormatNumber,
  extractNumberParts,
  replacePhoneNumber,
} from '@/utils'
import {
  Icon,
  Button,
  TextLink,
  // CustomImage,
  LocationsAccordion,
} from '@/components'
import { useLanguages, useReservationSuccess } from '@/hooks'

import reservationConfirmedIcon from '@/assets/images/icons/reservation_confirmed.svg'

export const ReservationRentalDetails: React.FC = () => {
  const router = useRouter()
  const { t, isRussian, currency } = useLanguages()
  const { terms, confirmed } = useReservationSuccess()

  const { reservation } = confirmed || {}
  const { pricedCoverages, pricedEquips, vehicle } = reservation || {}

  const _total = useMemo(
    () =>
      (reservation?.totalCharge.estimatedTotalAmount || '').replaceAll(
        /[,]/g,
        '',
      ),
    [reservation?.totalCharge.estimatedTotalAmount],
  )

  const [integerPart] = useMemo(() => extractNumberParts(_total), [_total])

  const startNew = useCallback(() => {
    router.replace(homePath)
  }, [router])

  const fees = useMemo(
    () => reservation?.fees?.filter(filterFees) || [],
    [reservation?.fees],
  )

  const coverages = useMemo(
    () =>
      pricedCoverages?.filter(_v => parseInt(_v.calculation.total) !== 0) || [],
    [pricedCoverages],
  )

  const equipments = useMemo(
    () => pricedEquips?.filter(_v => parseInt(_v.charge.amount) !== 0) || [],
    [pricedEquips],
  )

  const vehicleCategoryName = useMemo(
    () => vehicle?.vehicleType?.vehicleCategoryName,
    [vehicle?.vehicleType?.vehicleCategoryName],
  )

  const vehicleMakeModel = useMemo(
    () => vehicle?.vehicleMakeModel?.name,
    [vehicle?.vehicleMakeModel?.name],
  )

  return (
    <section className="rental-details">
      <div className="reservation-container">
        <div className="row">
          <div className="w-66 printable">
            <div className="rental-details-box">
              <div className="rental-details-box-item">
                <h1 className="rental-details-box-title">
                  {t('reservationRentalDetails')}
                </h1>
              </div>
              <div className="rental-details-box-item">
                <h1 className="rental-details-box-subtitle">
                  {t('reservationRentalDetails')}
                </h1>
                <p className="rental-details-box-text">
                  {t('reservationDriverName')}
                  <span>
                    {joinArray(
                      ' ',
                      reservation?.customer?.givenName,
                      reservation?.customer.surname,
                    )}
                  </span>
                </p>
                <p className="rental-details-box-text">
                  {t('reservationEmailAddress')}
                  <span>{confirmed?.email}</span>
                </p>
                <p className="rental-details-box-text">
                  {t('contactNumber')}:
                  <span>
                    {replacePhoneNumber(reservation?.customer?.phone_number)}
                  </span>
                </p>
                <p className="rental-details-box-text">
                  {t('reservationAge')}
                  <span>
                    {+(reservation?.customer?.age || 0) === 25
                      ? ' 25+'
                      : reservation?.customer.age}
                  </span>
                </p>
              </div>
              <div className="rental-details-box-item">
                <h1 className="rental-details-box-subtitle">
                  {t('reservationVehicle')}
                </h1>
                <p className="rental-details-box-text">
                  {t('reservationVehicleClass')}
                  <span>
                    {isRussian
                      ? !!vehicleCategoryName
                        ? // @ts-ignore
                          t(vehicleCategoryName?.toLowerCase())
                        : ''
                      : capitalize(vehicleCategoryName)}
                  </span>
                </p>
                <ul className="rental-details-box-ul">
                  <li>
                    <p className="rental-details-box-text">
                      {capitalize(
                        isRussian
                          ? // @ts-ignore
                            t(camelCase(vehicleMakeModel))
                          : vehicleMakeModel,
                      )}
                    </p>
                  </li>
                  <li>
                    <p className="rental-details-box-text">
                      {/* @ts-ignore */}
                      {t(vehicle?.transmissionType?.toLowerCase?.())}
                    </p>
                  </li>
                </ul>
                {reservation?.vehicleCharges
                  ?.filter?.(_v => +_v.amount !== 0)
                  .map((charge, idx, arr) => (
                    <React.Fragment key={idx}>
                      <div className="d-flex justify-between align-center">
                        <p className="rental-details-box-text">
                          {/* @ts-ignore */}
                          {t(camelCase(charge?.description))}
                        </p>
                        <p className="rental-details-box-text">
                          {t('currency', {
                            value: charge.amount,
                            currency: charge.currencyCode,
                          })}
                        </p>
                      </div>
                      {arr?.[idx + 1] && <div className="modify-line"></div>}
                    </React.Fragment>
                  ))}
                {`${reservation?.rateDistance?.unlimited}` === 'true' && (
                  <div className="d-flex justify-between align-center">
                    <p className="rental-details-box-text">
                      {t('reservationUnlimitedMileage')}
                    </p>
                    <p className="rental-details-box-text">
                      {t('reservationIncluded')}
                    </p>
                  </div>
                )}
              </div>
              {(!!equipments?.length || !!coverages?.length) && (
                <div className="rental-details-box-item">
                  <h1 className="rental-details-box-subtitle">
                    {t('reservationExtras')}
                  </h1>
                  {coverages.map((_v, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div className="d-flex justify-between align-center">
                          <p className="rental-details-box-text">
                            {capitalize(
                              isRussian
                                ? // @ts-ignore
                                  t(`${_v.code}Title`)
                                : _v.details,
                            )}
                          </p>
                          <p className="rental-details-box-text">
                            {t('currency', {
                              currency:
                                _v.charge?.currencyCode ?? currency.name,
                              value: _v?.calculation.total ?? _v.charge.amount,
                            })}
                          </p>
                        </div>
                      </React.Fragment>
                    )
                  })}
                  {equipments.map((_v, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div className="d-flex justify-between align-center">
                          <p className="rental-details-box-text">
                            {capitalize(
                              isRussian
                                ? // @ts-ignore
                                  t(`${_v.equipment.equipType}Title`)
                                : _v.equipment.description,
                            )}
                          </p>
                          <p className="rental-details-box-text">
                            {t('currency', {
                              currency:
                                _v.charge?.currencyCode ?? currency.name,
                              value: _v.charge.amount,
                            })}
                          </p>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              )}
              <div className="rental-details-box-item">
                <div className="d-flex justify-between align-center">
                  <h1 className="rental-details-box-subtitle">
                    {t('reservationTaxesAndFees')}
                  </h1>
                  {!fees?.length && (
                    <p className="rental-details-box-text">
                      {t('reservationIncluded')}
                    </p>
                  )}
                </div>
                {fees.map((fee, idx, arr) => (
                  <React.Fragment key={idx}>
                    <div className="d-flex justify-between align-center">
                      <p className="rental-details-box-text">
                        {isRussian
                          ? // @ts-ignore
                            t(camelCase(fee.description))
                          : capitalize(fee.description)}
                      </p>
                      <p className="rental-details-box-text">
                        {t('currency', {
                          currency: fee.currencyCode,
                          value: fee.amount,
                        })}
                      </p>
                    </div>
                    {arr[idx + 1] && <div className="modify-line"></div>}
                  </React.Fragment>
                ))}
              </div>
              <div className="rental-details-box-item d-flex justify-between align-center">
                <div className="d-flex flex-column">
                  <p className="rental-details-box-text">
                    {t('estimatedTotal')}
                  </p>
                  <p className="rental-details-box-text">
                    {t('reservationEstimatedTotalDue')}
                  </p>
                </div>
                <p className="rental-details-box-total">
                  <span className="dollar">
                    {confirmed?.reservation?.totalCharge?.currencyCode}
                  </span>
                  <span className="whole">
                    {humanFormatNumber(integerPart, ',')}
                  </span>
                  <span className="reminder">
                    .{(+_total)?.toFixed?.(2).split('.')?.[1]}
                  </span>
                </p>
              </div>
            </div>
            <LocationsAccordion />
          </div>
          <div className="w-33">
            <Button
              onClick={startNew}
              className="reservation-btn bordered w-100"
            >
              {t('reservationGoToHomePage')}
            </Button>
            <div className="rental-box">
              <div className="rental-box-header">
                {t('rentalCheckListTitle')}
              </div>
              <div className="rental-box-body">
                <div className="rental-box-check-items">
                  {[...new Array(4).fill(1)].map((_, idx) => (
                    <div className="rental-box-check-item" key={idx}>
                      <img
                        width={19}
                        height={20}
                        alt="Reservation status img"
                        style={{ objectFit: 'contain' }}
                        src={reservationConfirmedIcon.src}
                      />
                      {/* @ts-ignore */}
                      <p>{t(`rentalCheckList${idx}`)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rental-box">
              <div className="rental-box-header">
                {t('reservationRentalPolicies')}
              </div>
              <div className="rental-box-body bg-grey min">
                <div className="rental-box-links">
                  {terms?.map?.((term, idx) => (
                    <TextLink
                      key={idx}
                      className="rental-box-link"
                      // @ts-ignore
                      onClick={() => window?.toggleTermsModal?.(idx)}
                    >
                      {ellipsizeMiddle(
                        // @ts-ignore
                        isRussian ? t(`${term.subCode}Title`) : term.title,
                        25,
                      )}
                      <Icon name="chevron-right" />
                    </TextLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
