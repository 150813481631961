import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'

import { useLanguages, useOnClickOutside } from '@/hooks'
import { ITab } from '@/types'

import { INativeSelectProps, ISelectProps } from './types'

function SelectComponent<T>({
  label,
  value,
  options,
  onChange,
  className,
  placeholder = 'select',
}: ISelectProps<T>) {
  const { t } = useLanguages()
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const toggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  useOnClickOutside(ref, true, onClose)

  const _selectValue = useCallback(
    (value: ITab<T>) => () => {
      onChange(value)
      onClose()
    },
    [onChange, onClose],
  )

  return (
    <div ref={ref} className={classNames('custom-select-box', className)}>
      {!!label && <h4 className="custom-select-title">{label}</h4>}
      <div className="custom-select">
        <div
          onClick={toggle}
          className={classNames('select-selected', {
            'select-arrow-active': open,
            active: !!value,
          })}
        >
          {value || (placeholder === 'select' ? t('select') : placeholder)}
        </div>
        <div
          className={classNames('select-items', {
            'select-hide': !open,
          })}
        >
          {options?.map?.((option, idx) => (
            <div key={idx} onClick={_selectValue(option)}>
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function NativeSelectComponent<T>(
  {
    label,
    error,
    options,
    required,
    className,
    placeholder,
    ...rest
  }: INativeSelectProps<T>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useLanguages()

  return (
    <div className={classNames(className, { error: !!error })}>
      {!!label && (
        <label htmlFor={rest.name}>
          {label}
          {!!required && <sup> *</sup>}
        </label>
      )}
      <div
        className={classNames('input-group', {
          required,
        })}
        data-value={required ? `* ${t('required')}` : undefined}
      >
        <select {...rest} id={rest.name} ref={ref} className="form-control">
          {!!placeholder && (
            <option value="default" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option, idx) => (
            <option key={idx} value={`${option.value}`}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export const NativeSelect = React.forwardRef(NativeSelectComponent)

export const Select = SelectComponent
