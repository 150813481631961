import React from 'react'
import classNames from 'classnames'

import { CustomImage, Icon } from '../'

import { ICheckboxProps } from './types'

import infoIcon from '@/assets/images/icons/info.svg'

export const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  checked,
  onClick,
  disabled,
  hideInfoIcon,
  className = '',
  // ...rest
}) => {
  return (
    <div
      className={classNames('different-location-box', className, {
        disabled: !!disabled,
      })}
    >
      <div
        onClick={() => onClick?.(!checked)}
        className="different-location-checkbox"
      >
        {checked && <Icon name="checkbox" />}
      </div>
      {!!label && (
        <>
          <p className="different-location-text">{label}</p>
          {!hideInfoIcon && (
            <CustomImage src={infoIcon.src} width={20} height={20} />
          )}
        </>
      )}
    </div>
  )
}

export default Checkbox
