import * as yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'

export const searchReservationForm = yup
  .object()
  .shape({
    surname: yup.string().required(),
    givenName: yup.string().required(),
    reservationNumber: yup.string().required(),
  })
  .required()

export const carReservationForm = yup
  .object()
  .shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    car_lease_id: yup.number().required(),
    contactNumber: yup
      .string()
      .test('is-valid', 'Invalid phone number', value =>
        !!value ? isValidPhoneNumber(value) : false,
      )
      .required(),
    additionalComments: yup.string().required(),
    emailAddress: yup.string().email().required(),
  })
  .required()

export const carLeaseForm = yup
  .object()
  .shape({
    carId: yup.number().required(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    carLeaseId: yup.number().required(),
    contactNumber: yup
      .string()
      .test('is-valid', 'Invalid phone number', value =>
        !!value ? isValidPhoneNumber(value) : false,
      )
      .required(),
    emailAddress: yup.string().email().required(),
  })
  .required()

export const transferForm = yup
  .object()
  .shape({
    pickUpDate: yup.date().required(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    paxNumber: yup.number().required(),
    pickUpTime: yup.string().required(),
    car_lease_id: yup.number().required(),
    contactNumber: yup
      .string()
      .test('is-valid', 'Invalid phone number', value =>
        !!value ? isValidPhoneNumber(value) : false,
      )
      .required(),
    pickUpLocation: yup.string().required(),
    dropOffLocation: yup.string().required(),
    emailAddress: yup.string().email().required(),
  })
  .required()

export const startReservationForm = yup
  .object()
  .shape({
    driverAge: yup.number().required(),
    pickUpDate: yup.date().required(),
    returnDate: yup.date().required(),
    returnTime: yup.string().required(),
    pickUpTime: yup.string().required(),
    differentLocation: yup.number().required(),
    pickUpLocation: yup.string().required(),
    pickUpLatitude: yup.string().required(),
    pickUpLongitude: yup.string().required(),
    pickUpStationCode: yup.string(),

    promotionCode: yup.string().optional(),

    dropOffLocation: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffLatitude: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffLongitude: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffStationCode: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string()
      }),
  })
  .required()

export const reserveNowForm = yup
  .object()
  .shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    phoneNumber: yup
      .string()
      .test('is-valid', 'Invalid phone number', value =>
        !!value ? isValidPhoneNumber(value) : false,
      )
      .required(),
    email: yup.string().email().required(),
  })
  .required()

export const editDateRangeForm = yup
  .object()
  .shape({
    pickUpDate: yup.date().required(),
    returnDate: yup.date().required(),
    returnTime: yup.string().required(),
    pickUpTime: yup.string().required(),
  })
  .required()

export const editLocationForm = yup
  .object()
  .shape({
    differentLocation: yup.number().required(),
    pickUpLocation: yup.string().required(),
    pickUpLatitude: yup.string().required(),
    pickUpLongitude: yup.string().required(),
    pickUpStationCode: yup.string(),
    dropOffLocation: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffLatitude: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffLongitude: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string().required()
      }),
    dropOffStationCode: yup
      .string()
      .when('differentLocation', (values?: number[]) => {
        if (!values || values?.includes(0)) return yup.string()
        return yup.string()
      }),
  })
  .required()
