import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import { useOnClickOutside, useWait } from '@/hooks'
import { type IModalProps } from './types'

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  header,
  title,
  children,
  onOpened,
  onClosed,
  onClose,
  className,
  modalBodyClassName,
  modalContentClassName,
  closeOnBackdrop = true,
  showCloseButton = true,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(!!isOpen)

  useWait(
    () => {
      setVisible(!!isOpen)
      if (isOpen) {
        onOpened?.()
      }
    },
    isOpen ? 0 : 200,
  )

  useWait(
    () => {
      setOpen(!!isOpen)
      if (!isOpen) {
        onClosed?.()
      }
    },
    isOpen ? 200 : 0,
  )

  useOnClickOutside(contentRef, isOpen && closeOnBackdrop, onClose)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
    return () => {
      document.body.classList.remove('disable-scroll')
    }
  }, [isOpen])

  if (!visible) return null

  return document?.getElementById('portal')
    ? createPortal(
        <div className={classNames('modal', className, { open })}>
          {visible && (
            <div
              ref={contentRef}
              className={classNames('modal-content', modalContentClassName)}
            >
              {showCloseButton && (
                <div className="modal-header">
                  <span className="close" onClick={onClose}>
                    &times;
                  </span>
                </div>
              )}
              <div className={classNames('modal-body', modalBodyClassName)}>
                {typeof header === 'string' ? (
                  <h1 className="modal-title">{header}</h1>
                ) : (
                  header
                )}
                {!!title && <h1 className="modal-title">{title}</h1>}
                {children}
              </div>
            </div>
          )}
        </div>,
        document.getElementById('portal')!,
      )
    : null
}
