import React from 'react'
import classNames from 'classnames'

import { IRadioButtonProps } from './types'

export const RadioButton = React.forwardRef<
  HTMLInputElement,
  IRadioButtonProps
>(({ label, className, onChange, subLabel, ...rest }, ref) => {
  return (
    <label className={classNames('radiobutton', className)}>
      <div className="row">
        <span>{label}</span>
        <span>{subLabel}</span>
      </div>
      <input {...rest} ref={ref} type="checkbox" onChange={onChange} />
      <span className="checkmark"></span>
    </label>
  )
})
