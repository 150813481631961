import { useCallback, useRef, useState } from 'react'
import {
  useQuery,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  DefinedInitialDataOptions,
} from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { IResponse, TLazyQueryParams } from '@/types'
import { useLanguages } from '@/hooks'

import { errorBlackList } from './errorBlackList'
import { apiRequest } from './request'

interface TQueryCallbacks<TResponse = unknown> {
  onSuccess?: (_data: TResponse) => void
  onFail?: (_error: any) => void
  onSettled?: () => void
}

interface IQueryOptions<TResponse = unknown>
  extends TQueryCallbacks<TResponse> {
  options?: Partial<
    DefinedInitialDataOptions<AxiosResponse<TResponse> | undefined, AxiosError>
  >
}

const onError = (
  error: any,
  apiPath: string,
  // toastFn: (_type: VariantType, _message: string) => void,
) => {
  if (error.response?.status === 401) {
    // @ts-ignore
    window?.signOut?.()
  } else if (error.message) {
    if (!errorBlackList.includes(apiPath)) {
      // TODO toastFn('error', extractErrorFromResponse(error))
    }
  }
}

export const useGet = <TParam = unknown, TResponse = unknown>(
  path: string,
  params?: TParam,
  options?: IQueryOptions<IResponse<TResponse>>,
) => {
  return useQuery({
    queryKey: [...(options?.options?.queryKey || []), path, params],
    queryFn: async () => {
      try {
        const res = await apiRequest.get<IResponse<TResponse>>(path)
        options?.onSuccess?.(res.data)
        return res
      } catch (error: any) {
        onError(error, path)
        options?.onFail?.(error)
      } finally {
        options?.onSettled?.()
      }
    },
    throwOnError: (error: AxiosError) => {
      onError(error, path)
      return false
    },
    ...options,
  })
}

export const useLazyGet = <TParam = unknown, TResponse = unknown>(
  path: string,
  params?: TLazyQueryParams<TParam>,
  options?: IQueryOptions<IResponse<TResponse>>,
) => {
  const enabledRef = useRef(false)
  const [enabled, setEnabled] = useState(false)
  const [apiPath, setApiPath] = useState<string>(
    generatePath(path, params?.pathParams || {}, params?.queryParams || []),
  )

  const trigger = useCallback(
    (_payload?: TLazyQueryParams<TParam>) => {
      if (_payload) {
        setApiPath(
          generatePath(
            path,
            _payload?.pathParams || {},
            _payload?.queryParams || [],
          ),
        )
      }
      if (!enabledRef.current) {
        setEnabled(true)
        enabledRef.current = true
      }
    },
    [path],
  )

  const query = useQuery({
    queryKey: [...(options?.options?.queryKey || []), apiPath],
    queryFn: async ({ signal }) => {
      try {
        const res = await apiRequest.get<IResponse<TResponse>>(apiPath, {
          signal,
        })
        options?.onSuccess?.(res.data)
        return res
      } catch (error: any) {
        onError(error, path)
        options?.onFail?.(error)
      } finally {
        options?.onSettled?.()
        setEnabled(false)
        enabledRef.current = false
      }
    },
    enabled,
    retry: 0,
    ...options,
  })

  return [trigger, query] as const
}

export function useGenericMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    hideLoading?: boolean
  },
): UseMutationResult<TData, TError, TVariables, TContext> {
  return useMutation({
    ...options,
    onError: (error: any, variables, context) => {
      return options?.onError?.(error, variables, context)
    },
  })
}

export const useDelete = <TParam = unknown, TResponse = unknown>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<
    AxiosResponse<IResponse<TResponse>>,
    AxiosError,
    TParam
  >,
) => {
  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.delete<IResponse<TResponse>>(path, {
        data: variables || data,
      }),
    ...options,
  })
}

export const usePost = <
  TParam = unknown,
  TResponse = unknown,
  TCustomResponse = unknown,
>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<
    AxiosResponse<IResponse<TResponse> & TCustomResponse>,
    AxiosError,
    TParam | void
  >,
) => {
  const { appLang } = useLanguages()

  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.post<IResponse<TResponse> & TCustomResponse>(
        generatePath(path, { lang: appLang }),
        variables || data,
      ),
    ...options,
  })
}

export const usePut = <TParam = unknown, TResponse = unknown>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<
    AxiosResponse<IResponse<TResponse>>,
    AxiosError,
    TParam | void
  >,
) => {
  const { appLang } = useLanguages()
  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.put<IResponse<TResponse>>(
        generatePath(path, { lang: appLang }),
        variables || data,
      ),
    ...options,
  })
}

export const generatePath = (
  path: string,
  params: { [x: string]: string | number | undefined | null } = {},
  queryData?: Record<string, string | number | boolean>[],
  fullUrl?: boolean,
): string => {
  let newPath: string = path
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  if (queryData && queryData.length > 0) {
    const query = queryData
      .map(item => Object.keys(item)[0] + '=' + Object.values(item)[0])
      .join('&')
    newPath = newPath + '?' + query
  }

  if (fullUrl) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    return `${baseUrl}${newPath}`
  }

  return newPath
}

export const useLazyGetCustom = <TParam = unknown, TResponse = unknown>(
  path: string,
  params?: TLazyQueryParams<TParam>,
  options?: IQueryOptions<TResponse>,
) => {
  const enabledRef = useRef(false)
  const [enabled, setEnabled] = useState(false)
  const [apiPath, setApiPath] = useState<string>(
    generatePath(path, params?.pathParams || {}, params?.queryParams || []),
  )

  const trigger = useCallback(
    (_payload?: TLazyQueryParams<TParam>) => {
      if (_payload) {
        setApiPath(
          generatePath(
            path,
            _payload?.pathParams || {},
            _payload?.queryParams || [],
          ),
        )
      }
      if (!enabledRef.current) {
        setEnabled(true)
        enabledRef.current = true
      }
    },
    [path],
  )

  const query = useQuery({
    queryKey: [...(options?.options?.queryKey || []), apiPath],
    queryFn: async ({ signal }) => {
      try {
        const res = await axios.get<TResponse>(apiPath, {
          signal,
          headers: {
            'X-Api-Key': process.env.NEXT_PUBLIC_X_API_KEY,
          },
        })
        options?.onSuccess?.(res.data)
        return res
      } catch (error: any) {
        onError(error, path)
        options?.onFail?.(error)
      } finally {
        options?.onSettled?.()
        setEnabled(false)
        enabledRef.current = false
      }
    },
    enabled,
    retry: 0,
    ...options,
  })

  return [trigger, query] as const
}
