import React, { useCallback, useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import * as langs from 'date-fns/locale'
import classNames from 'classnames'
import moment from 'moment'

import { getDateRange, isDisableDate } from '@/utils'
import { useLanguages } from '@/hooks'
import { DateRange } from '@/types'
import { Popover, Icon } from '../'

import { ITriggerAction } from '../Popover/types'

import { IDateRangePickerProps } from './types'
import { PickerClasses } from './constants'
// import Toolbar from './toolbar'
import Trigger from './trigger'

export const DateRangePicker = React.forwardRef<
  ITriggerAction,
  IDateRangePickerProps
>(
  (
    {
      time,
      type,
      value,
      label,
      single,
      maxDate,
      minDate,
      onChange,
      disabled,
      className,
      onChangeTime,
      // restoreKey,
      disabledDates,
      closeOnOutside,
      numberOfMonths = 2,
      placement = 'bottom-center',
      ...rest
    },
    ref,
  ) => {
    const { appLang } = useLanguages()
    const [range, setRange] = useState<DateRange | undefined | null>(undefined)

    useEffect(() => {
      if (
        range === undefined ||
        range?.to !== value?.to ||
        range?.from !== value?.from
      ) {
        const from = moment().toDate()
        setRange(value || { from, to: undefined })
      }
    }, [range, value])

    const onSelect = useCallback(
      (v: DateRange | undefined) => {
        if (single && range?.from) {
          const { from } = getDateRange(range, v)
          setRange({ from, to: undefined })
          return onChange?.({ from, to: undefined })
        }
        if (range?.from && range.to) {
          const { from } = getDateRange(range, v)
          setRange({ from, to: undefined })
          onChange?.({ from, to: undefined })
        } else {
          setRange(v)
          onChange?.(v)
        }
      },
      [onChange, range, single],
    )

    return (
      <Popover
        ref={ref}
        autoScroll
        placement={placement}
        className={classNames(className)}
        disabled={disabled}
        closeOnOutside={closeOnOutside}
        WrapEl="div"
        distance={12}
        // onClosed={() => setRange(undefined)}
        button={
          <Trigger
            required
            time={time}
            type={type}
            label={label}
            value={range}
            showBothTriggers={true}
            onChangeTime={onChangeTime}
          />
        }
      >
        <div className="date-range-picker-panel">
          <div
            className={classNames('date-range-picker-panel-box', {
              fit: numberOfMonths === 1,
            })}
          >
            <div className="item-bg">
              <div className="date-range">
                <DayPicker
                  {...rest}
                  showOutsideDays
                  mode="range"
                  defaultMonth={range?.from}
                  selected={range?.from ? range : undefined}
                  // @ts-ignore
                  onSelect={onSelect}
                  numberOfMonths={numberOfMonths}
                  disabled={(curDate: Date) =>
                    isDisableDate(curDate, disabledDates, maxDate, minDate)
                  }
                  locale={
                    // appLang === 'hy'
                    //   ? langs.hy
                    //   :
                    appLang === 'ru' ? langs.ru : langs.enGB
                  }
                  className="custom-date-range-picker"
                  fromDate={moment().add(1, 'day').toDate()}
                  modifiersClassNames={{ today: 'day-today' }}
                  classNames={{ ...PickerClasses, ...classNames }}
                  components={{
                    IconLeft: () => <Icon name="arrow-left" />,
                    IconRight: () => <Icon name="arrow-right" />,
                  }}
                />
                {/* <Toolbar /> */}
              </div>
            </div>
          </div>
        </div>
      </Popover>
    )
  },
)
