import React from 'react'
import { useRouter } from 'next-intl/client'

import { Button, CustomImage } from '@/components'
import { blogPostPath, getImage } from '@/utils'
import { useLanguages } from '@/hooks'
import { IBannerType } from '@/types'

export const BlogPostSection: React.FC<Partial<IBannerType>> = ({
  title,
  image,
  description,
}) => {
  const router = useRouter()
  const { t } = useLanguages()

  return (
    <section className="blog-posts">
      <CustomImage
        priority
        width={1280}
        height={(1280 * 9) / 16}
        className="blog-posts-img"
        src={getImage(image, false)}
        style={{ width: '100%', height: '100%' }}
      />
      <div className="blog-posts-box">
        <div className="blog-posts-border">
          <p className="blog-posts-title">{title}</p>
          <div
            className="blog-posts-subtitle"
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
          <Button
            className="btn big-btn"
            onClick={() => router.push(blogPostPath)}
          >
            {t('readMore')}
          </Button>
        </div>
      </div>
    </section>
  )
}
