import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ICarType,
  ICarListItem,
  ICarLeaseCarType,
  PopupVariant,
  ICurrencyResponse,
} from '@/types'
import { useLanguages } from '@/hooks'
import { Slider } from '../'

import {
  HandlerPopup,
  RequestPrice,
  LeasingDetails,
  CarReservation,
} from './Modals'
import { PopularCarItem } from './popularCarItem'

export type TForPage = '1' | '2' | '3' | '4'

interface IPopularCarsSectionProps {
  type?: TForPage
  exchange?: ICurrencyResponse['data']
  carList?: (ICarListItem | ICarType | ICarLeaseCarType)[]
}

const properties = {
  1: 'carLease',
  2: 'monthAndMore',
  3: 'transferServices',
  4: 'longTerms',
}

export const PopularCarsSection: React.FC<IPopularCarsSectionProps> = ({
  exchange,
  type = '1',
  carList = [],
}) => {
  const { t } = useLanguages()
  const [selectedItem, setItem] = useState<
    ICarListItem | ICarType | ICarLeaseCarType | null
  >(null)
  const [showPopup, setShowPopup] = useState<PopupVariant>()

  const listComponent = useMemo(
    () => (
      <Slider
        settings={{ centerMode: true }}
        data={carList}
        className="popular-cars-carousel"
        Component={args => (
          <PopularCarItem
            {...args}
            type={type}
            exchange={exchange}
            onClick={_item => setItem(_item)}
          />
        )}
      />
    ),
    [type, exchange, carList],
  )

  useEffect(() => {
    if (!!selectedItem) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
    return () => {
      document.body.classList.remove('disable-scroll')
    }
  }, [selectedItem])

  const onClose = useCallback(() => {
    setShowPopup(undefined)
  }, [])

  const onTriggerPopup = useCallback((variant: PopupVariant) => {
    setShowPopup(variant)
  }, [])

  const _property = useMemo(() => properties[type], [type])

  return (
    <section className="popular-cars mb-5">
      {/* @ts-ignore */}
      <h2 className="landing-title text-center">{t(`${_property}Title`)}</h2>
      {/* @ts-ignore */}
      <h3 className="landing-subtitle mb-m-0">{t(`${_property}Desc`)}</h3>
      {listComponent}
      {type === '3' && !!selectedItem && (
        <RequestPrice
          // @ts-ignore
          item={selectedItem}
          onTrigger={onTriggerPopup}
          onClose={() => setItem(null)}
        />
      )}
      {['2', '4'].includes(type) && !!selectedItem && (
        <CarReservation
          // @ts-ignore
          item={selectedItem}
          onTrigger={onTriggerPopup}
          onClose={() => setItem(null)}
        />
      )}
      {type === '1' && !!selectedItem && (
        <LeasingDetails
          // @ts-ignore
          item={selectedItem}
          exchange={exchange}
          onTrigger={onTriggerPopup}
          onClose={() => setItem(null)}
        />
      )}
      <HandlerPopup
        onClose={onClose}
        variant={showPopup}
        isOpen={!!showPopup}
      />
    </section>
  )
}
