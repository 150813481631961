import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { camelCase, capitalize, omit } from 'lodash'

import { Icon, Button, Select, RadioButton, CustomImage } from '@/components'
import {
  joinArray,
  shallowEqual,
  getUniqueValues,
  humanFormatNumber,
} from '@/utils'
import { DepthKeys, useLanguages, useReservation } from '@/hooks'
import { ITab, TFilter, IVehicle, IVehicleInfo } from '@/types'

import { IFilter } from './filters'
import { FilterModal } from './'

import accordionDownIcon from '@/assets/images/icons/accordion-down.svg'
import manualIcon from '@/assets/images/icons/popular-cars-menu-1.svg'
import promotionIcon from '@/assets/images/icons/promotion.svg'
// import filterIcon from '@/assets/images/icons/filter.svg'

const sortBy: ITab<number, DepthKeys>[] = [
  {
    value: 0,
    label: 'featured',
  },
  {
    value: 1,
    label: 'lowToHigh',
  },
  {
    value: 2,
    label: 'highToLow',
  },
]

export const ChooseVehicle: React.FC = () => {
  const { t } = useLanguages()
  const [value, setValue] = useState<ITab<number>>({
    ...sortBy[1],
    label: t(sortBy[1].label),
  })
  const [selectedFilters, setFilters] = useState<Omit<IFilter, 'status'>>({
    capacity: [],
    vehicleType: [],
    transmission: [],
  })
  const [showMore, setShowMore] = useState<IVehicle>()
  const { vehicles, onSelectVehicle, vehicle } = useReservation()

  const onSort = useCallback(
    (vehicle1: IVehicleInfo, vehicle2: IVehicleInfo) => {
      const { value: sortBy } = value
      if (sortBy === 1) {
        return (
          +vehicle1.totalCharge.estimatedTotalAmount.replaceAll(',', '') -
          +vehicle2.totalCharge.estimatedTotalAmount.replaceAll(',', '')
        )
      }
      if (sortBy === 2) {
        return (
          +vehicle2.totalCharge.estimatedTotalAmount.replaceAll(',', '') -
          +vehicle1.totalCharge.estimatedTotalAmount.replaceAll(',', '')
        )
      }
      return 0
    },
    [value],
  )

  const _filteredVehicles = useMemo(() => {
    const { transmission, capacity, vehicleType } = selectedFilters
    const _vehicles = vehicles
      ?.filter(vehicle => {
        const transmissionMatch =
          !transmission?.length ||
          transmission.includes(vehicle.vehicle.transmissionType)
        const capacityMatch =
          !capacity?.length ||
          capacity.some(_v => +vehicle.vehicle.passengerQuantity >= +_v)
        const typeMatch =
          !vehicleType?.length ||
          vehicleType.includes(vehicle.vehicle.vehicleType.vehicleCategoryName)

        return transmissionMatch && capacityMatch && typeMatch
      })
      .filter(_v => _v.status === 'Available')
    if (value.value) {
      _vehicles?.sort(onSort)
    }

    return _vehicles
  }, [selectedFilters, vehicles, value.value, onSort])

  const onChange = useCallback((_value: ITab<number>) => {
    setValue(_value)
  }, [])

  const onSelect = useCallback(
    (idx: number) => () => {
      const _selected = _filteredVehicles?.[idx]
      if (!_selected) return
      onSelectVehicle({
        vehicle: _selected.vehicle,
        reference: _selected.reference,
      })
    },
    [onSelectVehicle, _filteredVehicles],
  )

  const onShowMore = useCallback(
    (idx: number) => () => {
      const detailsItem = _filteredVehicles?.[idx]
      if (!detailsItem) return
      setShowMore(prev => {
        if (shallowEqual(prev, detailsItem.vehicle)) {
          return undefined
        }
        return detailsItem.vehicle
      })
    },
    [_filteredVehicles],
  )

  const onSetFilter = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      setFilters(prev => ({
        ...prev,
        // @ts-ignore
        [name]: prev?.[name].includes(value)
          ? // @ts-ignore
            prev?.[name]?.filter(
              // @ts-ignore
              filter => filter !== value,
            )
          : // @ts-ignore
            [...prev?.[name], value],
      }))
    },
    [],
  )

  const filters = useMemo(
    () => (
      <FilterForm
        value={value}
        onChange={onChange}
        vehicles={vehicles}
        onSetFilter={onSetFilter}
      />
    ),
    [vehicles, onChange, onSetFilter, value],
  )

  return (
    <>
      <section className="choose-vehicle">
        <div className="row gap">
          <div className="filter-container">
            <h3 className="reservation-subtitle">{t('reservationFilter')}</h3>
            {filters}
          </div>
          <div className="vehicle-container">
            <h1 className="reservation-title mb-23">
              {t('reservationChooseVehicleClass')}
            </h1>
            {/* <div className="d-flex justify-between mb-3">
              <div className="choose-vehicle-results">
                <div className="choose-vehicle-result">
                  {t('reservationResults', {
                    count: _filteredVehicles?.length,
                  })}
                </div>
                <div className="choose-vehicle-result">
                  <p>{t('sortBy')}</p> {value.label}
                </div>
              </div>
              <div className="choose-location-mobile-tools w-auto mt-0 d-none">
                <Button className="btn-tools">
                  <CustomImage
                    width={11}
                    height={11}
                    src={filterIcon.src}
                    style={{ objectFit: 'contain' }}
                  />
                  <p>{t('sort&Filter')}</p>
                </Button>
              </div>
            </div> */}
            <div className="vehicle-cards">
              {_filteredVehicles && _filteredVehicles.length > 0 ? (
                _filteredVehicles.map((vehicleInfo, idx) => (
                  <VehicleCard
                    key={idx}
                    {...vehicleInfo}
                    onSelect={onSelect(idx)}
                    onShowMore={onShowMore(idx)}
                    showMore={shallowEqual(vehicleInfo.vehicle, showMore || {})}
                    selected={shallowEqual(vehicleInfo.vehicle, vehicle || {})}
                  />
                ))
              ) : (
                <p className="text-center mt-5 error-title">{t('emptyList')}</p>
              )}
            </div>
          </div>
        </div>
      </section>
      <FilterModal>{filters}</FilterModal>
    </>
  )
}

const VehicleCard: React.FC<
  IVehicleInfo & {
    selected: boolean
    showMore: boolean
    onSelect?: () => void
    onShowMore?: () => void
  }
> = ({ selected, onSelect, showMore, onShowMore, ...rest }) => {
  const { isMobile, coupon } = useReservation()
  const {
    pictureURL,
    baggageQuantity,
    airConditioning,
    transmissionType,
    passengerQuantity,
    vehicleType: { sizeName },
    vehicleMakeModel: { name },
  } = rest.vehicle

  const { estimatedTotalAmount, currencyCode } = rest.totalCharge

  const { vehicleCharges } = rest.rentalRate
  const { rateQualifier } = rest.rentalRate

  const [period] = vehicleCharges

  const { t, isRussian } = useLanguages()
  const divRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (showMore || selected) {
      divRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }, [showMore, selected])

  const isAvailable = useMemo(() => rest.status === 'Available', [rest.status])

  return (
    <div
      ref={divRef}
      className={classNames('vehicle-card', {
        selected,
      })}
    >
      <div className="vehicle-card-selected">
        <Icon name="checkbox" />
        <p>{t('currentSelection')}</p>
      </div>
      <img
        width={238}
        height={151}
        alt="Car img"
        src={pictureURL}
        className="vehicle-car-img"
        style={{ objectFit: 'contain' }}
      />
      <div className="vehicle-info mr-auto pl-2">
        <h3 className="vehicle-info-title big m-size">
          {/* @ts-ignore */}
          {isRussian ? t(camelCase(sizeName)) : sizeName}
        </h3>
        <h4 className="vehicle-info-subtitle">
          {/* @ts-ignore */}
          {capitalize(isRussian ? t(camelCase(name)) : name)}
        </h4>
        <div className="vehicle-details-menu">
          <div className="vehicle-details-menu-item">
            <CustomImage src={manualIcon.src} width={24} height={24} />
            <p>
              {/* @ts-ignore */}
              {isRussian ? t(transmissionType.toLowerCase()) : transmissionType}
            </p>
          </div>
          <div className="vehicle-details-menu-item">
            <Icon name="popular-cars-menu-3" />
            <p>{t('leasingPopupSeats', { count: +passengerQuantity })}</p>
          </div>
          <div className="vehicle-details-menu-item">
            <Icon name="popular-cars-menu-4" />
            <p>{t('leasingPopupBags', { count: +baggageQuantity })}</p>
          </div>
        </div>
        <div
          className={classNames('more-tool', {
            rotate: showMore,
          })}
          onClick={onShowMore}
        >
          <p>{t('reservationReadDetails')}</p>
          <Icon name="arrow-drop-down" />
        </div>
      </div>
      <div className="vehicle-info">
        <h3 className="vehicle-info-title border-bottom">
          {t('reservationPayLater')}
        </h3>
        <div className="vehicle-info-total">
          {isAvailable && (
            <>
              <div className="vehicle-info-total-item">
                <p>
                  {t('reservationPer', {
                    // @ts-ignore
                    period: t(period.calculation.unitName),
                  })}
                </p>
                <h3>
                  {t('currency', {
                    currency: currencyCode,
                    value: period.calculation.unitCharge,
                  })}
                </h3>
              </div>
              <div className="vehicle-info-total-line d-m-none"></div>
              <div className="vehicle-info-total-item">
                <p>{t('reservationTotal')}</p>
                <h3>
                  <b>
                    {t('currency', {
                      currency: currencyCode,
                      value: estimatedTotalAmount,
                    })}
                  </b>
                </h3>
              </div>
              {rateQualifier?.corpDiscountNmbr && (
                <div className="vehicle-info-promotion">
                  <CustomImage src={promotionIcon.src} width={20} height={20} />
                  <p>
                    {joinArray(
                      ' ',
                      coupon?.percentage ? `${coupon?.percentage}%` : undefined,
                      t('promotionApplied'),
                    )}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <Button
          onClick={onSelect}
          disabled={!isAvailable}
          className={classNames('btn w-100')}
        >
          {t('select')}
        </Button>
        <div
          className={classNames('d-flex justify-center', {
            'd-none': !isMobile,
          })}
        >
          <div
            className={classNames('more-tool m', {
              rotate: showMore,
            })}
            onClick={onShowMore}
          >
            <p className="underline-m-none">
              {t('reservationFeaturesAndPricingDetails')}
            </p>
            <CustomImage
              width={19}
              height={19}
              className="up"
              src={accordionDownIcon.src}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'hidden-vehicle-info d-flex justify-between w-100',
          {
            show: showMore,
          },
        )}
      >
        <div className="vehicle-info mt-5">
          <h4 className="vehicle-info-subtitle">
            <b>{t('vehicleFeatures')}</b>
          </h4>
          <h4 className="vehicle-info-subtitle">
            {joinArray(
              ' ',
              airConditioning === 'true' ? t('reservationAirConditioning') : '',
              isRussian
                ? // @ts-ignore
                  t(transmissionType.toLowerCase())
                : capitalize(transmissionType),
            )}
          </h4>
          <p className="vehicle-info-text">{t('reservationNotReflectTaxes')}</p>
        </div>
        <div className="vehicle-info mt-5">
          <h3 className="vehicle-info-title">{t('priceDetails')}</h3>
          <div className="vehicle-info-total">
            {vehicleCharges
              .filter(_v => _v.calculation.total !== '0')
              .map((charge, idx) => (
                <div className="vehicle-info-total-item" key={idx}>
                  <p>
                    {t('perPeriod', {
                      count: charge.calculation.quantity,
                      period:
                        charge.calculation.unitName === 'RentalPeriod'
                          ? t(`${charge.calculation.unitName}`)
                          : // @ts-ignore
                            t(`${charge.calculation.unitName}s`),
                    })}
                  </p>
                  <h3>
                    {t('currency', {
                      currency: charge.currencyCode,
                      value: charge.amount,
                    })}
                  </h3>
                </div>
              ))}
            <div className="vehicle-info-total-item">
              <p>{t('tax&Fee')}</p>
              <h3>{t('reservationIncluded')}</h3>
            </div>
            <div className="vehicle-info-total-line d-m-none"></div>
            <div className="vehicle-info-total-item">
              <p>{t('estimatedTotal')}</p>
              <h3>
                <b>
                  {t('currency', {
                    currency: currencyCode,
                    value: estimatedTotalAmount,
                  })}
                </b>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface IFilterFormProps {
  value?: ITab<number>
  vehicles?: IVehicleInfo[]
  onChange: (_option: ITab<number>) => void
  onSetFilter: (_payload: { name: string; value: string }) => void
}

const FilterForm: React.FC<IFilterFormProps> = ({
  value,
  vehicles,
  onChange,
  onSetFilter,
}) => {
  const { t, currency } = useLanguages()

  const sortingBy = useMemo(
    () => sortBy.map(_v => ({ value: _v.value, label: t(_v.label) })),
    [t],
  )
  // @ts-ignore
  const filterOptions: Record<TFilter, { label: string; value: string }[]> =
    useMemo(() => {
      const _vehicles = vehicles?.map(_v => _v.vehicle) || []
      return {
        transmission: [
          ...getUniqueValues(_vehicles, 'transmissionType').map(_v => ({
            value: _v,
            label: _v,
          })),
        ],
        vehicleType: [
          ...getUniqueValues(
            _vehicles.map(_v => _v.vehicleType),
            'vehicleCategoryName',
          ).map(_v => ({
            value: _v,
            label: _v,
          })),
        ],
        capacity: [
          ...getUniqueValues(_vehicles, 'passengerQuantity')
            .filter(_v => _v!)
            .sort((a, b) => +a - +b)
            .map(_v => ({
              value: _v,
              label: _v,
            })),
        ],
      }
    }, [vehicles])

  const extractLowPrice = useCallback(
    (filter: 'transmission' | 'capacity' | 'vehicleType', value: any) => {
      // @ts-ignore
      const key: keyof IVehicle =
        filter === 'transmission'
          ? 'transmissionType'
          : filter === 'capacity'
          ? 'passengerQuantity'
          : 'vehicleType'
      const extra = key === 'vehicleType' ? 'vehicleCategoryName' : undefined
      return (
        vehicles
          ?.filter(_v => {
            let currentValue = _v.vehicle[key]
            if (extra) {
              // @ts-ignore
              currentValue = currentValue[extra]
            }
            // @ts-ignore
            currentValue = currentValue?.toLowerCase?.()
            return (
              _v.status === 'Available' &&
              // @ts-ignore
              currentValue === `${value}`.toLowerCase()
            )
          })
          ?.sort((_a, _b) => {
            return (
              +_a.totalCharge.estimatedTotalAmount.replaceAll(',', '') -
              +_b.totalCharge.estimatedTotalAmount.replaceAll(',', '')
            )
          })?.[0]?.totalCharge?.estimatedTotalAmount || ''
      )
    },
    [vehicles],
  )

  return (
    <div className="filter-box">
      <h4 className="filter-box-title">{t('reservationSortBy')}</h4>
      <Select<number>
        options={sortingBy}
        onChange={onChange}
        value={value?.label}
        className="reservation"
      />
      {Object.keys(omit(filterOptions, 'status')).map(name => {
        return (
          <React.Fragment key={name}>
            <h4 className="filter-box-title">
              {/* @ts-ignore */}
              {t(`reservation${capitalize(name)}`)}
            </h4>
            {filterOptions[name as TFilter].map((_v, idx) => {
              // @ts-ignore
              const minValue = extractLowPrice(name, _v.value)
              if (!minValue) return null
              return (
                <RadioButton
                  key={idx}
                  name={name}
                  onChange={() => {
                    onSetFilter({
                      name,
                      value: _v.value,
                    })
                  }}
                  // @ts-ignore
                  label={t(camelCase(_v.label.toLowerCase()))}
                  subLabel={t('currency', {
                    currency: currency.symbol,
                    value:
                      // @ts-ignore
                      humanFormatNumber(minValue.replaceAll(',', '')),
                  })}
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}
