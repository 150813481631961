import React from 'react'
import classNames from 'classnames'

import { useLanguages } from '@/hooks'

import { ITextAreaProps } from './types'

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      label,
      error,
      name,
      required,
      hasSuffix,
      className = '',
      onChange,
      ...rest
    },
    ref,
  ) => {
    const { t } = useLanguages()

    return (
      <div className={classNames(className, { error: !!error })}>
        {!!label && (
          <label htmlFor={name}>
            {label}
            {!!required && hasSuffix && <sup> *</sup>}
          </label>
        )}
        <div
          className={classNames('input-group', {
            required,
          })}
          data-value={required ? `* ${t('required')}` : undefined}
        >
          <textarea
            {...rest}
            ref={ref}
            id={name}
            name={name}
            onChange={onChange}
            className="form-control text-area"
          />
        </div>
      </div>
    )
  },
)
