import React from 'react'

import { FullScreenLoader } from '../'

export const PageWrapper: React.FC<{
  children: React.ReactNode
  isLoading?: boolean
  hide?: boolean
}> = ({ children, isLoading, hide = false }) => {
  return (
    <>
      {children}
      {isLoading && <FullScreenLoader hideContent={hide} />}
    </>
  )
}
