import React from 'react'

import { NativeSelect, TextInput } from '@/components'
import { useLanguages, useReservation } from '@/hooks'

import { airlineDetails } from './constants'

export const FlightDetails: React.FC = () => {
  const { t } = useLanguages()
  const { reservationDetails, onUpdateFlight } = useReservation()

  return (
    <div className="review-box">
      <div className="review-form">
        <h1 className="review-form-title">{t('flightDetails')}</h1>
        <div className="review-input">
          <NativeSelect
            options={airlineDetails.map(_v => ({
              value: _v.code,
              label: _v.description,
            }))}
            label={t('airplaneName')}
            placeholder={t('select')}
            value={reservationDetails?.arrivalDetails?.code ?? 'default'}
            onChange={option => onUpdateFlight({ code: option.target.value })}
          />
        </div>
        <div className="review-input">
          <TextInput
            label={t('flightNumber')}
            value={reservationDetails?.arrivalDetails?.number ?? ''}
            onChange={v => onUpdateFlight({ number: v.target.value })}
          />
        </div>
      </div>
    </div>
  )
}
