import React, { useCallback, useMemo, useState } from 'react'

import { FleetCard, Select } from '@/components'
import { FleetData, ITab } from '@/types'
import { useLanguages } from '@/hooks'

import { filters } from '../Reservation/filters'

export const FleetClientPage: React.FC<{ data: FleetData[] }> = ({ data }) => {
  const { t } = useLanguages()
  const [value, setValue] = useState<ITab<string, string>>()

  const onChange = useCallback((_value: ITab<string, string>) => {
    setValue(_value)
  }, [])

  const list = useMemo(() => data, [data])

  const onFilter = useCallback(
    (data: FleetData) => {
      if (value?.value === 'all' || !value) return true
      const vehicleType = data.EHIWebName.toLowerCase()
      if (value.value === 'car') {
        return !vehicleType.includes('van') && !vehicleType.includes('suv')
      }
      return vehicleType.includes(value.value)
    },
    [value],
  )

  const vehicleTypes = useMemo(
    () =>
      filters.vehicleType.map(_v => ({
        // @ts-ignore
        value: _v.value,
        // @ts-ignore
        label: t(_v.label),
      })),
    [t],
  )
  return (
    <section className="fleet">
      <div className="big-container">
        <div className="d-flex justify-between mb-5 flex-m-wrap">
          <h1 className="fleet-title">{t('fleetTitle')}</h1>
          <Select
            onChange={onChange}
            label={t('carType')}
            value={value?.label}
            options={vehicleTypes}
          />
        </div>
        <div className="fleet-cards">
          {list.filter(onFilter).map((_v, idx) => (
            <FleetCard key={idx} {..._v} />
          ))}
        </div>
      </div>
    </section>
  )
}
