import { ITab, TFilter } from '@/types'
import { DepthKeys } from '@/hooks'

export interface IFilter {
  status: string | null
  capacity: string[]
  vehicleType: string[]
  transmission: string[]
}

export const filters: Record<TFilter, ITab<string, DepthKeys | string>[]> = {
  transmission: [
    {
      value: 'automatic',
      label: 'automatic',
    },
    {
      value: 'manual',
      label: 'manual',
    },
  ],
  vehicleType: [
    {
      value: 'all',
      label: 'all',
    },
    {
      value: 'car',
      label: 'car',
    },
    {
      value: 'suv',
      label: 'suv',
    },
    {
      value: 'van',
      label: 'van',
    },
  ],
  capacity: [
    {
      label: '4',
      value: '4',
    },
    {
      label: '5',
      value: '5',
    },
    {
      label: '7',
      value: '7',
    },
    {
      label: '8',
      value: '8',
    },
  ],
  status: [],
}
