import React from 'react'
import classNames from 'classnames'

interface IMapPinProps {
  id: string
  className?: string
}

export const MapPin: React.FC<IMapPinProps> = ({ id, className }) => {
  return (
    <div className={classNames('map-marker', className)}>
      <span>{id}</span>
    </div>
  )
}
