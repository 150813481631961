import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { SubmitHandler } from 'react-hook-form'
import { useRouter } from 'next-intl/client'
import { capitalize } from 'lodash'

import {
  editPath,
  startPath,
  setStorageValue,
  generateSubPath,
  reservationsPath,
  searchReservationForm,
  reservationSuccessPath,
} from '@/utils'
import {
  Button,
  Form,
  TextLink,
  TextInput,
  PageWrapper,
  HandlerPopup,
} from '@/components'
import {
  IResponse,
  TSearchForm,
  IErrorResponse,
  IReserveNowResponse,
} from '@/types'
import { useHookForm, useLanguages } from '@/hooks'
import { CONFIRMED_KEY } from '@/constants'
import { useGetReservation } from '@/apis'

export const FindReservation: React.FC = () => {
  const router = useRouter()
  const params = useSearchParams()
  const { t, appLang } = useLanguages()
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const surname = params.get('lastName')
  const givenName = params.get('firstName')
  const reservationNumber = params.get('id')

  const onFindReservation = useCallback(
    (data: IResponse<IReserveNowResponse>) => {
      setStorageValue(CONFIRMED_KEY, { ...data.data, modifying: true })
      const timer = setTimeout(() => {
        router.replace(reservationSuccessPath)
        clearTimeout(timer)
      }, 10)
    },
    [router],
  )

  const onFail = useCallback((error: IErrorResponse) => {
    setErrorMessage(error?.response?.data?.message)
    if (error?.response?.data?.message) {
      setShowPopup(true)
    }
  }, [])

  const [searchReservation, { isLoading }] = useGetReservation(
    onFindReservation,
    onFail,
  )

  const onClose = useCallback(() => {
    setShowPopup(false)
    router.replace(generateSubPath(reservationsPath, editPath))
  }, [router])

  const {
    handler: {
      reset,
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(searchReservationForm)

  const onSubmit: SubmitHandler<TSearchForm> = useCallback(
    data => {
      searchReservation({
        // @ts-ignore
        queryParams: Object.keys(data || {}).map(key => ({ [key]: data[key] })),
        pathParams: { lang: appLang },
      })
    },
    [searchReservation, appLang],
  )

  useEffect(() => {
    if (surname && givenName && reservationNumber) {
      const data = {
        surname,
        givenName,
        reservationNumber,
      }
      reset(data)
      onSubmit(data)
    }
  }, [onSubmit, reset, surname, givenName, reservationNumber])

  return (
    <PageWrapper
      isLoading={isLoading}
      hide={!!surname && !!givenName && !!reservationNumber}
    >
      <section className="find-reservation-form">
        <div className="find-reservation-box">
          <div className="find-reservation-box-header">
            <h1 className="find-reservation-box-title">
              {t('viewModifyCancel')}
            </h1>
            <p className="find-reservation-box-or">{t('or')}</p>
            <TextLink
              className="find-reservation-box-modify"
              href={generateSubPath(reservationsPath, startPath)}
            >
              {t('startReservationButton')}
            </TextLink>
          </div>
          <div className="form-fields-box">
            <Form className="form-fields" onSubmit={handleSubmit(onSubmit)}>
              <div className="w-100">
                <TextInput
                  required
                  label={t('confirmationNumber')}
                  placeholder={t('confirmationNumber')}
                  error={errors.reservationNumber?.message}
                  {...register('reservationNumber')}
                />
              </div>
              <div className="w-50">
                <TextInput
                  required
                  label={t('firstName')}
                  placeholder={t('firstName')}
                  error={errors.givenName?.message}
                  {...register('givenName')}
                />
              </div>
              <div className="w-50">
                <TextInput
                  required
                  label={t('lastName')}
                  placeholder={t('lastName')}
                  error={errors.surname?.message}
                  {...register('surname')}
                />
              </div>
              <div className="w-100 d-flex justify-end justify-m-center">
                <Button type="submit" className="btn power">
                  {t('search')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </section>
      <HandlerPopup
        variant="error"
        onClose={onClose}
        isOpen={showPopup}
        // @ts-ignore
        message={t(capitalize(errorMessage))}
      />
    </PageWrapper>
  )
}
