import React from 'react'
import {
  FieldErrors,
  UseFormTrigger,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form'

import { PhoneNumberInput, TextInput } from '@/components'
import { TReserveNowForm } from '@/types'
import { useLanguages } from '@/hooks'

interface IDetailsForm {
  disabled?: boolean
  errors: FieldErrors<TReserveNowForm>
  trigger: UseFormTrigger<TReserveNowForm>
  setValue: UseFormSetValue<TReserveNowForm>
  register: UseFormRegister<TReserveNowForm>
  getValues: UseFormGetValues<TReserveNowForm>
}

export const DetailsForm: React.FC<IDetailsForm> = ({
  errors,
  trigger,
  register,
  disabled,
  setValue,
  getValues,
}) => {
  const { t } = useLanguages()

  return (
    <div className="review-form">
      <h1 className="review-form-title">{t('reservationContactDetails')}</h1>
      <h3 className="review-form-subtitle">{t('reservationRequiredFields')}</h3>
      <TextInput
        required
        disabled={disabled}
        value={getValues('firstName')}
        {...register('firstName', {
          onChange: () => {
            trigger('firstName')
          },
        })}
        error={errors.firstName?.message}
        label={t('firstName')}
        className="review-input w-100"
      />
      <TextInput
        required
        disabled={disabled}
        value={getValues('lastName')}
        {...register('lastName', {
          onChange: () => {
            trigger('lastName')
          },
        })}
        error={errors.lastName?.message}
        label={t('lastName')}
        className="review-input w-100"
      />
      <TextInput
        required
        disabled={disabled}
        value={getValues('email')}
        {...register('email', {
          onChange: () => {
            trigger('email')
          },
        })}
        error={errors.email?.message}
        className="review-input w-100"
        label={t('emailAddress')}
      />
      <PhoneNumberInput
        required
        hasSuffix
        disabled={disabled}
        value={getValues('phoneNumber')}
        onChange={value => {
          setValue('phoneNumber', value)
          trigger('phoneNumber')
        }}
        error={errors.phoneNumber?.message}
        className="review-input w-100"
        label={t('contactNumber')}
      />
    </div>
  )
}
