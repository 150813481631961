import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Checkbox, Form, Popover, SearchInput } from '@/components'
import { useHookForm, useLanguages, useReservation } from '@/hooks'
import { ILocationData, TEditLocationForm } from '@/types'
import { editLocationForm, joinArray } from '@/utils'

export const Locations: React.FC = () => {
  const { parameters } = useReservation()

  const { pickUpLocation, dropOffLocation } = parameters || {}

  return (
    <>
      <p>{pickUpLocation}</p>
      {!!dropOffLocation && <p>{dropOffLocation}</p>}
    </>
  )
}

export const LocationsEditor: React.FC = () => {
  const { t } = useLanguages()
  const initialized = useRef(false)
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null)
  const { isLocationEditing, toStep, onUpdate, parameters } = useReservation()

  const onClose = useCallback(() => {
    if (!isLocationEditing) return
    toStep(2, true)
    document.body.classList.remove('overlay')
  }, [isLocationEditing, toStep])

  useEffect(() => {
    if (isLocationEditing) {
      document.body.classList.add('overlay')
    } else {
      document.body.classList.remove('overlay')
    }
    return () => {
      document.body.classList.remove('overlay')
    }
  }, [isLocationEditing])

  const {
    handler: {
      reset,
      trigger,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(editLocationForm)

  const defaultState = useMemo(
    () => ({
      dropOffLatitude: parameters?.dropOffLatitude,
      dropOffLocation: parameters?.dropOffLocation,
      dropOffLongitude: parameters?.dropOffLongitude,
      dropOffStationCode: parameters?.dropOffStationCode,
      pickUpLatitude: parameters?.pickUpLatitude,
      pickUpLocation: parameters?.pickUpLocation,
      pickUpLongitude: parameters?.pickUpLongitude,
      pickUpStationCode: parameters?.pickUpStationCode,
      differentLocation: +(parameters?.differentLocation || 0),
    }),
    [parameters],
  )

  useEffect(() => {
    if (!isLocationEditing) {
      initialized.current = false
      return
    }
    if (initialized.current) return
    initialized.current = true
    reset({ ...defaultState })
  }, [defaultState, isLocationEditing, parameters, reset])

  const onSelect = useCallback(
    (type: 'dropOff' | 'pickUp') => (location?: ILocationData) => {
      setValue(`${type}Location`, location?.name ?? '')
      setValue(`${type}StationCode`, location?.code ?? '')
      setValue(`${type}Latitude`, `${location?.latitude ?? ''}`)
      setValue(`${type}Longitude`, `${location?.longitude ?? ''}`)
      trigger(`${type}StationCode`)
      trigger(`${type}Longitude`)
      trigger(`${type}Latitude`)
      trigger(`${type}Location`)
    },
    [setValue, trigger],
  )

  const pickupLocation = joinArray(' ', getValues('pickUpLocation'))

  const dropOffLocation = joinArray(' ', getValues('dropOffLocation'))

  const isChecked = !!getValues('differentLocation')

  const onSubmit: SubmitHandler<TEditLocationForm> = useCallback(
    payload => {
      const { differentLocation } = payload
      const _resetState = !payload.differentLocation
        ? {
            dropOffLatitude: '',
            dropOffLocation: '',
            dropOffLongitude: '',
            dropOffStationCode: '',
          }
        : {}
      onUpdate(
        {
          ...payload,
          ..._resetState,
          // @ts-ignore
          differentLocation: `${differentLocation}`,
        },
        true,
      )
    },
    [onUpdate],
  )

  useEffect(() => {
    if (isLocationEditing) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      popoverRef.current?.onTrigger()
    } else {
      popoverRef.current?.onClose()
    }
  }, [isLocationEditing])

  return (
    <Popover
      distance={0}
      closeOnOutside
      ref={popoverRef}
      button={<div />}
      hasArrow={false}
      onClosed={onClose}
      onOpened={() => document.body.classList.add('overlay')}
    >
      <div className="stepper-editor">
        <div className="stepper-editor-box">
          <div className="d-flex flex-column w-100 align-center pt-24 pb-24 p-m-0">
            <div className="stepper-editor-header w-50 m-w-70">
              <h3 className="stepper-editor-title">
                {t('reservationChangeYourLocation')}
              </h3>
              <p>* {t('required')}</p>
            </div>
            <div className="form-fields-box">
              <div className="form-fields d-flex justify-center align-start mt-m-20">
                <Form className="w-50 m-w-70" onSubmit={handleSubmit(onSubmit)}>
                  <SearchInput
                    required
                    initial=""
                    value={pickupLocation}
                    error={errors.pickUpLocation?.message}
                    onSelect={onSelect('pickUp')}
                    label={t('pickupLocation')}
                    className="preview-input"
                    placeholder={t('locationPlaceholder')}
                  />
                  <Checkbox
                    onClick={(_isChecked: boolean) => {
                      setValue('differentLocation', _isChecked ? 1 : 0)
                      trigger('differentLocation')

                      if (!_isChecked) {
                        setValue('dropOffLocation', undefined)
                        setValue('dropOffLatitude', undefined)
                        setValue('dropOffLongitude', undefined)
                        setValue('dropOffStationCode', undefined)
                        trigger('dropOffLocation')
                        trigger('dropOffLatitude')
                        trigger('dropOffLongitude')
                        trigger('dropOffStationCode')
                      }
                    }}
                    checked={!!getValues('differentLocation')}
                    label={t('returnToDifferentLocation')}
                  />
                  {isChecked && (
                    <SearchInput
                      required
                      initial=""
                      value={dropOffLocation}
                      error={errors.dropOffLocation?.message}
                      onSelect={onSelect('dropOff')}
                      label={t('returnLocation')}
                      className="preview-input"
                      placeholder={t('locationPlaceholder')}
                    />
                  )}
                  <div className="d-flex justify-end">
                    <Button className="btn w-m-100" type="submit">
                      {t('reservationUpdate')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  )
}

export const LocationMobile: React.FC = () => {
  const { parameters } = useReservation()

  const { pickUpLocation, dropOffLocation } = parameters || {}
  return (
    <>
      <p className="stepper-opened-menu-value">{pickUpLocation}</p>
      {!!dropOffLocation && (
        <>
          <p className="stepper-opened-menu-divider">-</p>
          <p className="stepper-opened-menu-value">{dropOffLocation}</p>
        </>
      )}
    </>
  )
}
