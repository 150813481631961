import React from 'react'

import { CustomImage } from '@/components'
import { ILocationPage } from '@/types'
import { useLanguages } from '@/hooks'
import { joinArray } from '@/utils'

import clockIcon from '@/assets/images/icons/clock.svg'

export const LocationInfo: React.FC<ILocationPage> = data => {
  const { t } = useLanguages()
  return (
    <div className="w-50">
      <div className="rent-car-info-box">
        <CustomImage src={clockIcon.src} width={24} height={24} />
        <div
          className="rent-car-info-text"
          dangerouslySetInnerHTML={{
            __html: joinArray(
              ' ',
              t('contactUsOperationDaysAndHours'),
              '<br />',
              data.working_hours,
            ),
          }}
        />
      </div>
      {!!data.other_information && (
        <div className="rent-car-info-box">
          <div className="circle" />
          <div
            className="rent-car-info-text"
            dangerouslySetInnerHTML={{
              __html: joinArray(
                ' ',
                t('contactUsOtherInformation'),
                '<br />',
                data.other_information,
              ),
            }}
          />
        </div>
      )}
    </div>
  )
}
