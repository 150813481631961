export default {
  faq: 'faq',
  home: 'home',
  blog: 'blog',
  blogInner: 'blog',
  aboutUs: 'about-us',
  location: 'location',
  carLease: 'car-lease',
  contactUs: 'contact-us',
  welcome: 'welcome-page',
  termsOfUse: 'terms-of-use',
  specialOffers: 'best-offer',
  cookiePolicy: 'cookie-policy',
  privacyPolicy: 'privacy-policy',
  oneWayRentals: 'one-way-rentals',
  longTermRentals: 'long-term-rentals',
  findReservation: 'find-a-reservation',
  corporateRentals: 'corporate-rentals',
  transferServices: 'transfer-services',
  luxuryCarRentals: 'luxury-car-rentals',
  monthAndMore: 'month-and-more-rentals',
  termsAndConditions: 'terms-and-conditions',
  rentCarWithDriver: 'rent-a-car-with-driver',
  transportationForEvents: 'transportation-for-events',
  offers: 'offers',
  // api
  exchangeRate: '/exchange/rate',
  staticTexts: '/static-texts',
  fleetList: 'mft/vehicles',
  // post
  lease: '/api/form/car-lease',
  longTerm: '/api/form/long-term',
  transfer: '/api/form/transfer-services',
  // reservation flow
  search: '/api/:lang/enterprise/location/search',
  reserveNow: '/api/:lang/enterprise/reservation/reserve-now',
  cancelReservation: '/api/:lang/enterprise/reservation/cancel',
  modifyReservation: '/api/:lang/enterprise/reservation/modify',
  getReservation: '/api/:lang/enterprise/reservation/reservation',
  selectVehicle: '/api/:lang/enterprise/reservation/select-vehicle',
  startReservation: '/api/:lang/enterprise/reservation/start-reservation',
  flightEndpoint:
    'https://prd-east.webapi.enterprise.com/enterprise-ewt/location/1042894?type=return',
  searchEndpoint: `https://search.location.enterprise.com/v1/search/location/${process.env.NEXT_PUBLIC_BRAND}/web/text/:keyword`,
} as const
