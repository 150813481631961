import React from 'react'

import { CustomImage } from '@/components'
import { IBannerType } from '@/types'
import { getImage } from '@/utils'

import googleReviewIcon from '@/assets/images/google-review.png'

export const ReviewSection: React.FC<Partial<IBannerType>> = ({
  image,
  title = 'Car Rental in Yerevan, Armenia',
  // eslint-disable-next-line max-len
  description = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the i`,
}) => {
  return (
    <section className="rate">
      <div className="row">
        <div className="w-50">
          <div
            className="rate-img"
            style={{
              backgroundImage: getImage(image),
            }}
          />
        </div>
        <div className="w-50">
          <div className="rate-info">
            <p className="rate-title">{title}</p>
            <div
              className="rate-subtitle"
              // dangerouslySetInnerHTML={{ __html: description || '' }}
            >
              {description}
            </div>
            <CustomImage width={145} height={43} src={googleReviewIcon.src} />
          </div>
        </div>
      </div>
    </section>
  )
}
