'use client'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { capitalize } from 'lodash'
import classNames from 'classnames'

import {
  useOnResize,
  useLanguages,
  useReservation,
  useReservationSuccess,
} from '@/hooks'
import { Button, CustomImage, Icon, Modal } from '@/components'
import { ellipsizeMiddle } from '@/utils'

import printIcon from '@/assets/images/icons/print.svg'

import './styles.scss'

export const TermsModal: React.FC = () => {
  const { terms } = useReservation()
  const { t, isRussian } = useLanguages()
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [selected, setSelected] = useState<number>(0)
  const { terms: successTerms } = useReservationSuccess()

  const toggleTermsModal = useCallback((_selected?: number) => {
    if (_selected !== undefined) {
      setSelected(_selected)
    } else {
      setSelected(0)
    }
    setIsOpen(prev => !prev)
  }, [])

  const onSelect = useCallback(
    (idx: number) => () => {
      setSelected(idx)
    },
    [],
  )

  useEffect(() => {
    setIsMobile(window?.innerWidth < 1124)
  }, [])

  const onResize = useCallback(() => {
    setIsMobile(window?.innerWidth < 1124)
  }, [])

  useOnResize(onResize)

  const _terms = useMemo(
    () => (successTerms ? successTerms : terms),
    [terms, successTerms],
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      // @ts-ignore
      window.toggleTermsModal = toggleTermsModal
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [toggleTermsModal])

  if (!_terms?.length) return null
  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      className="terms-modal"
      onClose={() => toggleTermsModal()}
      modalBodyClassName="min-padding"
    >
      <div className="tab">
        {_terms?.map((term, idx) => (
          <React.Fragment key={idx}>
            <Button
              onClick={onSelect(idx)}
              className={classNames({
                active: idx === selected,
              })}
            >
              {ellipsizeMiddle(
                // @ts-ignore
                isRussian ? t(`${term.subCode}Title`) : capitalize(term?.title),
                25,
              )}
              <Icon name="chevron-right" />
            </Button>
            <div
              className={classNames('m-tab-content d-none m-p-2', {
                'd-block': idx === selected && isMobile,
              })}
            >
              <p className="tab-text mb-0">
                {isRussian
                  ? // @ts-ignore
                    t(`${_terms?.[selected]?.subCode}Desc`)
                  : _terms?.[selected]?.text}
              </p>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className="tab-content printable d-m-none">
        <div className="d-flex justify-between align-center mb-3">
          <h3 className="tab-title">
            {isRussian
              ? // @ts-ignore
                t(`${_terms?.[selected]?.subCode}Title`)
              : _terms?.[selected]?.title}
          </h3>
          <Button className="print-btn" onClick={() => window.print()}>
            <CustomImage src={printIcon} width={15} height={15} />
            <p>{t('print')}</p>
          </Button>
        </div>
        <p className="tab-text">
          {isRussian
            ? // @ts-ignore
              t(`${_terms?.[selected]?.subCode}Desc`)
            : _terms?.[selected]?.text}
        </p>
      </div>
    </Modal>
  )
}
