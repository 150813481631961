import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'

import { PageWrapper } from '@/components'
import { useReservation } from '@/hooks'

import { EstimationModal, TimeOutModal } from './components'
import { ChooseLocation } from './chooseLocation'
import { ChooseVehicle } from './chooseVehicle'
import { ReviewReservation } from './review'
import { Extras } from './extras'

const TermsModal = dynamic(
  () => import('./termsModal').then(module => module.TermsModal),
  {
    ssr: false,
  },
)

const pages: Record<string, React.ElementType> = {
  2: ChooseLocation,
  3: ChooseVehicle,
  4: Extras,
  5: ReviewReservation,
}

export const Steps: React.FC = () => {
  const { currentStep, isLoading } = useReservation()

  const Component = useMemo(() => pages[`${currentStep}`], [currentStep])
  return (
    <PageWrapper isLoading={isLoading}>
      <div className="reservation-steps">
        <Component />
        <TermsModal />
        <EstimationModal />
        <TimeOutModal />
      </div>
    </PageWrapper>
  )
}
