import { useCallback, useEffect } from 'react'
import { useLocale, useTranslations, TranslationValues } from 'next-intl'
import { usePathname, useRouter } from 'next-intl/client'
import moment from 'moment'

import 'moment/locale/en-gb'
import 'moment/locale/ru'

import {
  LANG_KEY,
  CURRENCY_KEY,
  DEFAULT_LANG,
  APP_CURRENCIES,
} from '@/constants'
import { getStorageValue, setStorageValue } from '@/utils'
import { Paths, TCurrency, TLang } from '@/types'

import enTranslation from '@/locale/en.json'
import ruTranslation from '@/locale/ru.json'
import hyTranslation from '@/locale/hy.json'

export const resources = {
  en: { ...enTranslation },
  ru: { ...ruTranslation },
  hy: { ...hyTranslation },
} as const

type rootLevelKeys = (typeof resources)[TLang]
export type DepthKeys = Paths<rootLevelKeys, 4>

const momentLocale = {
  ru: 'ru-RU',
  en: 'en-EN',
  hy: 'hy',
}

export const useLanguages = () => {
  const router = useRouter()
  const appLang = useLocale()
  const _t = useTranslations()
  const pathname = usePathname()
  const lang = getStorageValue(LANG_KEY)
  const currency = getStorageValue<TCurrency>(CURRENCY_KEY) as TCurrency

  const changeLanguage = useCallback(
    (locale: TLang) => {
      router.replace(pathname, { locale })
      moment.locale(momentLocale[locale])
      setStorageValue(LANG_KEY, locale)
      document.querySelector('html')?.setAttribute('lang', locale)
    },
    [router, pathname],
  )

  const onChangeCurrency = useCallback(
    (currency: TCurrency) => {
      setStorageValue(CURRENCY_KEY, currency)
      router.refresh()
    },
    [router],
  )

  useEffect(() => {
    // cleanup
    localStorage.clear()
  }, [])

  useEffect(() => {
    if (!lang) {
      changeLanguage(DEFAULT_LANG as TLang)
    }
  }, [lang, changeLanguage])

  useEffect(() => {
    if (!currency) {
      onChangeCurrency(APP_CURRENCIES[1])
    }
  }, [currency, onChangeCurrency])

  const t = useCallback(
    (_key: DepthKeys, values?: TranslationValues) => {
      if (!_key) return ''
      if (!isNaN(+_key)) return _key
      return _t(_key, values)
    },
    [_t],
  )

  return {
    t,
    changeLanguage,
    onChangeCurrency,
    appLang: appLang as TLang,
    isRussian: appLang === 'ru',
    currency: currency ?? APP_CURRENCIES[1],
    momentLocale: momentLocale[appLang as TLang],
  }
}
