import axios from 'axios'

import { CURRENCY_KEY, LANG_KEY } from '@/constants'
import { TCurrency, TLang } from '@/types'
import { getStorageValue } from '@/utils'

import { API_ENDPOINT } from '@/constants'

export const apiRequest = axios.create({
  baseURL: `${API_ENDPOINT}/`,
})

apiRequest.interceptors.request.use(function (config) {
  const currency = getStorageValue<TCurrency>(CURRENCY_KEY)
  const language = getStorageValue<TLang>(LANG_KEY)
  // @ts-ignore
  config.headers = {
    ...config.headers,
    language: `${language ?? 'en'}`,
    currency: `${currency?.name ?? 'USD'}`,
    'ngrok-skip-browser-warning': true,
  }

  return config
})
