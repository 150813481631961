'use client'

import React, { useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import axios from 'axios'

import {
  ICarType,
  PopupVariant,
  ICarListItem,
  TCarReservationSchema,
} from '@/types'
import {
  Form,
  Modal,
  Button,
  TextArea,
  TextInput,
  PhoneNumberInput,
} from '@/components'
import { carReservationForm, generateFormData, joinArray } from '@/utils'
import { useHookForm, useLanguages } from '@/hooks'
import { API_ENDPOINT } from '@/constants'
import endpoints from '@/apis/endpoints'

interface ICarReservation {
  onClose?: () => void
  item: ICarType & ICarListItem
  onTrigger?: (_variant: PopupVariant) => void
}

export const CarReservation: React.FC<ICarReservation> = ({
  item,
  onClose,
  onTrigger,
}) => {
  const { t } = useLanguages()
  const [isLoading, setIsLoading] = useState(false)

  const {
    handler: {
      trigger,
      setValue,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(carReservationForm, {
    defaultValues: {
      car_lease_id: item?.car_id || item?.car?.id || item.id,
    },
  })

  const onSubmit: SubmitHandler<TCarReservationSchema> = useCallback(
    async data => {
      const formData = generateFormData(data)
      try {
        setIsLoading(true)
        const response = await axios.post(
          `${API_ENDPOINT}${endpoints.longTerm}`,
          formData,
        )
        if (response.status === 200) {
          onClose?.()
          setIsLoading(false)
          const timeout = setTimeout(() => {
            onTrigger?.('success')
            clearTimeout(timeout)
          }, 200)
        }
      } catch (error: any) {
        onClose?.()
        setIsLoading(false)
        const timeout = setTimeout(() => {
          onTrigger?.('error')
          clearTimeout(timeout)
        }, 200)
      }
    },
    [onClose, onTrigger],
  )

  return (
    <Modal
      isOpen
      closeOnBackdrop
      showCloseButton
      onClose={onClose}
      title={t('fillCarPopup')}
      modalBodyClassName="modal-scroll"
    >
      <div className="form-fields-box">
        <div>
          <h3>
            {t('selectedVehicleName', {
              name: joinArray(
                ' ',
                item?.car?.manufacturer ?? item?.manufacturer,
                item?.car?.model ?? item?.model,
              ),
            })}
          </h3>
          {!!item.about && (
            <p className="car-lease-details-more">{item.about}</p>
          )}
        </div>
        <Form className="form-fields" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('firstName')}
            label={t('firstName')}
            error={errors.firstName?.message}
            placeholder={t('firstName')}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('lastName')}
            label={t('lastName')}
            error={errors.lastName?.message}
            placeholder={t('lastName')}
          />
          <PhoneNumberInput
            required
            hasSuffix
            className="w-50"
            onChange={value => {
              setValue('contactNumber', value)
              trigger('contactNumber')
            }}
            label={t('contactNumber')}
            value={getValues('contactNumber')}
            error={errors.contactNumber?.message}
            placeholder={t('contactNumber')}
          />
          <TextInput
            required
            type="email"
            className="w-100"
            hasSuffix={false}
            {...register('emailAddress')}
            label={t('emailAddress')}
            error={errors.emailAddress?.message}
            placeholder={t('emailAddress')}
          />
          <TextArea
            required
            className="w-100"
            placeholder={t('text')}
            {...register('additionalComments')}
            label={t('additionalComments')}
            error={errors.additionalComments?.message}
          />
          <div className="w-100 d-flex justify-end justify-m-center">
            <Button disabled={isLoading} type="submit" className="btn big">
              {t('done')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
