import React from 'react'
import { useRouter } from 'next-intl/client'

import { ConditionalWrapper } from '../Wrapper'
import { useLanguages } from '@/hooks'
import { joinArray } from '@/utils'
import { Icon } from '../Icon'

import { type ITextLinkProps } from './types'

export const TextLink = React.forwardRef<HTMLElement, ITextLinkProps>(
  (
    {
      defaultAction = false,
      iconLeft,
      iconRight,
      disabled,
      className,
      children,
      WrapEL = 'a',
      ...rest
    },
    ref,
  ) => {
    const router = useRouter()
    const { appLang, isRussian } = useLanguages()

    return (
      <ConditionalWrapper
        {...rest}
        ref={ref}
        WrapEl={WrapEL}
        className={className}
        aria-label={`Open page ${rest.href ?? ''}`}
        // @ts-ignore
        href={
          !disabled
            ? defaultAction
              ? rest.href
              : joinArray(
                  '',
                  isRussian ? `/${appLang}` : undefined,
                  rest.href ?? '#',
                )
            : undefined
        }
        onClick={e => {
          if (defaultAction) return
          e.preventDefault()
          if (disabled) return
          if (rest.href && rest.href !== '#') {
            router.push(rest.href)
          }
          // @ts-ignore
          rest.onClick?.(e)
        }}
      >
        {!!iconLeft && <Icon name={iconLeft} />}
        {children}
        {!!iconRight && <Icon name={iconRight} />}
      </ConditionalWrapper>
    )
  },
)

export default TextLink
