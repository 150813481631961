import React from 'react'
import Slider, { Settings } from 'react-slick'
import { useRouter } from 'next-intl/client'

import { bestOfferPath, getImage, rem, reservationsPath } from '@/utils'
import { CustomImage, Button } from '@/components'
import { IBestOfferItem } from '@/types'
import { useLanguages } from '@/hooks'

const settings: Settings = {
  dots: false,
  infinite: false,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1.1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1.1,
      },
    },
  ],
}

export const OffersSlider: React.FC<{
  offers: IBestOfferItem[]
}> = ({ offers }) => {
  if (!offers.length) return null
  return (
    <section className="long-term">
      <div className="long-term-offers-box no-bg">
        <Slider {...settings} className="long-term-offers-carousel">
          {offers?.map?.(offer => <Offer {...offer} key={offer.id} />)}
        </Slider>
      </div>
    </section>
  )
}

const Offer: React.FC<IBestOfferItem> = ({
  slug,
  image,
  title,
  small_description,
}) => {
  const router = useRouter()
  const { t } = useLanguages()
  return (
    <div className="offer-card">
      <div className="offer-card-img-container">
        <CustomImage
          priority
          width={1280}
          height={(1280 * 9) / 16}
          className="offer-card-img"
          src={getImage(image, false)}
          style={{ width: '100%', height: rem(320) }}
        />
      </div>
      <div className="card-body gap">
        <div className="card-info">
          <p className="card-title">{title}</p>
          <div
            className="card-text"
            dangerouslySetInnerHTML={{ __html: small_description || '' }}
          />
        </div>
        <Button
          className="btn section-btn"
          onClick={() => {
            router.replace(`${reservationsPath}${bestOfferPath}/${slug}`)
          }}
        >
          {t('learnMore')}
        </Button>
      </div>
    </div>
  )
}
