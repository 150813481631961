import {
  TLang,
  IBranch,
  IResponse,
  IErrorResponse,
  IAdditionalSchema,
  IReserveNowPayload,
  IReserveNowResponse,
  ISelectVehiclePayload,
  ISelectVehicleResponse,
  ISearchLocationPayload,
  ISearchLocationResponse,
  ICheckReservationPayload,
  IStartReservationPayload,
  IStartReservationResponse,
  ICancelReservationResponse,
} from '@/types'

import { useLazyGet, useLazyGetCustom, usePost, usePut } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useSearchLocation = (
  onSuccess?: (_data: IResponse<ISearchLocationResponse[]>) => void,
) => {
  return useLazyGet<ISearchLocationPayload, ISearchLocationResponse[]>(
    endpoints.search,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useCustomSearch = (
  onSuccess?: (_data: ISearchLocationResponse) => void,
) => {
  return useLazyGetCustom(endpoints.searchEndpoint, undefined, {
    onSuccess,
  })
}

export const useStartReservation = () => {
  return usePost<
    IStartReservationPayload,
    IStartReservationResponse | IBranch[],
    IAdditionalSchema
  >(endpoints.startReservation, undefined)
}

export const useReserveNow = () => {
  return usePost<IReserveNowPayload, IReserveNowResponse>(
    endpoints.reserveNow,
    undefined,
  )
}

export const useGetReservation = (
  onSuccess?: (_data: IResponse<IReserveNowResponse>) => void,
  onFail?: (_error: IErrorResponse) => void,
) => {
  return useLazyGet<{ lang: TLang }, IReserveNowResponse>(
    endpoints.getReservation,
    undefined,
    {
      onSuccess,
      onFail,
    },
  )
}

export const useCancelReservation = () => {
  return usePut<ICheckReservationPayload, ICancelReservationResponse>(
    endpoints.cancelReservation,
    undefined,
  )
}

export const useModifyReservation = () => {
  return usePut<IReserveNowPayload, IReserveNowResponse>(
    endpoints.modifyReservation,
    undefined,
  )
}

export const useSelectVehicle = () => {
  return usePost<ISelectVehiclePayload, ISelectVehicleResponse>(
    endpoints.selectVehicle,
    undefined,
  )
}

export const useGetFlights = (onSuccess?: (_data: any) => void) => {
  return useLazyGetCustom(endpoints.flightEndpoint, undefined, {
    onSuccess,
  })
}
