import React from 'react'
import classNames from 'classnames'

import { type IIconProps } from './types'
import './styles.scss'

export const Icon: React.FC<IIconProps> = ({ name, className, ...rest }) => {
  if (!name) return null
  return (
    <span className={classNames('icon', `ic-${name}`, className)} {...rest} />
  )
}
