import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import { useHookForm, useLanguages, useReservation } from '@/hooks'
import { Button, DateRangePicker, Form, Popover } from '@/components'
import { DateRange as TDateRange, TEditRangeForm } from '@/types'
import { editDateRangeForm } from '@/utils'

import { DateRangePickerWrapper } from '../../Main'

const DATE_FORMAT = 'ddd, MMM D '

export const DateRange: React.FC = () => {
  const { parameters } = useReservation()

  const { pickUpDate, returnDate, pickUpTime, returnTime } = parameters || {}

  return (
    <>
      <p>
        {moment(pickUpDate).format(DATE_FORMAT)}
        {moment(pickUpTime, 'HH:mm').format('HH:mm A')}
      </p>
      <p>
        {moment(returnDate).format(DATE_FORMAT)}
        {moment(returnTime, 'HH:mm').format('HH:mm A')}
      </p>
    </>
  )
}

export const DateRangeEditor: React.FC = () => {
  const { t } = useLanguages()
  const initialized = useRef(false)
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null)
  const { isDateEditing, parameters, toStep, onUpdate } = useReservation()

  const onClose = useCallback(() => {
    if (!isDateEditing) return
    toStep(1)
    document.body.classList.remove('overlay')
  }, [isDateEditing, toStep])

  useEffect(() => {
    if (isDateEditing) {
      document.body.classList.add('overlay')
    } else {
      document.body.classList.remove('overlay')
    }
    return () => {
      document.body.classList.remove('overlay')
    }
  }, [isDateEditing])

  const {
    handler: {
      reset,
      trigger,
      setValue,
      getValues,
      handleSubmit,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      formState: { errors },
    },
  } = useHookForm(editDateRangeForm)

  const onSubmit: SubmitHandler<TEditRangeForm> = useCallback(
    payload => {
      const { pickUpDate, returnDate } = payload
      onUpdate(
        {
          ...payload,
          pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
          returnDate: moment(returnDate).format('YYYY-MM-DD'),
        },
        true,
      )
    },
    [onUpdate],
  )

  const defaultState = useMemo(
    () => ({
      // @ts-ignore
      returnDate: new Date(parameters?.returnDate),
      // @ts-ignore
      pickUpDate: new Date(parameters?.pickUpDate),
      pickUpTime: parameters?.pickUpTime,
      returnTime: parameters?.returnTime,
    }),
    [parameters],
  )

  useEffect(() => {
    if (!isDateEditing) {
      initialized.current = false
      return
    }
    if (initialized.current) return
    initialized.current = true
    reset({ ...defaultState })
  }, [isDateEditing, defaultState, reset])

  const onDateSelect = useCallback(
    (_dateRange?: TDateRange) => {
      // @ts-ignore
      setValue('pickUpDate', _dateRange?.from)
      // @ts-ignore
      setValue('returnDate', _dateRange?.to)
      trigger('pickUpDate')
      trigger('returnDate')
    },
    [setValue, trigger],
  )

  const onSelectTime = useCallback(
    (type: 'return' | 'pickUp') => (time: string) => {
      setValue(`${type}Time`, time)
      trigger(`${type}Time`)
    },
    [setValue, trigger],
  )

  useEffect(() => {
    if (isDateEditing) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      popoverRef.current?.onTrigger()
    } else {
      popoverRef.current?.onClose()
    }
  }, [isDateEditing])

  return (
    <Popover
      distance={0}
      closeOnOutside
      ref={popoverRef}
      button={<div />}
      hasArrow={false}
      onClosed={onClose}
      onOpened={() => document.body.classList.add('overlay')}
    >
      <div className="stepper-editor">
        <div className="stepper-editor-box">
          <div className="w-100 d-flex align-center flex-column pt-24 pt-m-0">
            <div className="stepper-editor-header w-50 m-w-70">
              <h3 className="stepper-editor-title">
                {t('reservationChangeDateTime')}
              </h3>
              <p>* {t('required')}</p>
            </div>
            <div className="preview bg-none bg-lg-none p-0 w-50 m-w-70">
              <div className="preview-box w-100 pv-0">
                <div className="form-fields-box pl-m-0">
                  <Form
                    className="form-fields"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="d-flex justify-between w-100 gap-60 flex-m-wrap gap-m-6">
                      <DateRangePickerWrapper
                        onChange={onDateSelect}
                        range={{
                          to: getValues('returnDate'),
                          from: getValues('pickUpDate'),
                        }}
                      >
                        {(range, toRef, fromRef, onChange, isMobile) => (
                          <div className="w-66 w-100 d-flex flex-m-wrap gap-30 gap-m-6">
                            <DateRangePicker
                              type="pickup"
                              ref={fromRef}
                              value={range}
                              className="w-100"
                              label={t('pickup')}
                              placement="bottom-start"
                              onChange={onChange('pickup')}
                              time={getValues('pickUpTime')}
                              onChangeTime={onSelectTime('pickUp')}
                              numberOfMonths={isMobile ? 1 : undefined}
                            />
                            <DateRangePicker
                              ref={toRef}
                              type="return"
                              value={range}
                              className="w-100"
                              label={t('return')}
                              placement="bottom-center"
                              onChange={onChange('return')}
                              time={getValues('returnTime')}
                              onChangeTime={onSelectTime('return')}
                              numberOfMonths={isMobile ? 1 : undefined}
                            />
                          </div>
                        )}
                      </DateRangePickerWrapper>
                      <div className="w-33 d-flex align-end">
                        <Button type="submit" className="btn w-100">
                          {t('reservationUpdate')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  )
}

export const DateRangeMobile: React.FC = () => {
  const { parameters } = useReservation()

  const { pickUpDate, returnDate, pickUpTime, returnTime } = parameters || {}
  return (
    <>
      <p className="stepper-opened-menu-value">
        {moment(pickUpDate).format(DATE_FORMAT)} <br />
        {moment(pickUpTime, 'HH:mm').format('HH:mm A')}
      </p>
      <p className="stepper-opened-menu-divider">-</p>
      <p className="stepper-opened-menu-value">
        {moment(returnDate).format(DATE_FORMAT)} <br />
        {moment(returnTime, 'HH:mm').format('HH:mm A')}
      </p>
    </>
  )
}
