import React from 'react'
import moment from 'moment'

import { useLanguages, useReservation } from '@/hooks'
import { Button } from '@/components'
import { joinArray } from '@/utils'

const DATE_FORMAT = 'ddd, MMM DD, YYYY @ '
const TIME_FORMAT = 'HH:mm A'

export const RentalDetailsSection: React.FC = () => {
  const { t } = useLanguages()
  const { toStep, parameters } = useReservation()
  const {
    driverAge,
    pickUpDate,
    pickUpTime,
    returnTime,
    returnDate,
    promotionCode,
    pickUpLocation,
    dropOffLocation,
  } = parameters || {}

  return (
    <div className="review-box">
      <h2 className="review-box-title">{t('reservationRentalDetails')}</h2>
      <div className="review-box-details">
        <div className="review-box-detail">
          <div className="review-box-detail-info">
            <p className="review-box-detail-info-text">
              {t('reservationDate&Times')}
            </p>
            <p className="review-box-detail-info-text">
              {moment(pickUpDate).format(DATE_FORMAT)}
              {moment(pickUpTime, 'HH:mm').format(TIME_FORMAT)}
            </p>
            <p className="review-box-detail-info-text">
              {moment(returnDate).format(DATE_FORMAT)}
              {moment(returnTime, 'HH:mm').format(TIME_FORMAT)}
            </p>
          </div>
          <Button type="button" className="edit-btn" onClick={() => toStep(1)}>
            {t('edit')}
          </Button>
        </div>
        <div className="review-box-detail">
          <div className="review-box-detail-info">
            <p className="review-box-detail-info-text">
              {t('reservationPickupAndReturn')}
            </p>
            <p className="review-box-detail-info-text">{t('locations')}</p>
            <p className="review-box-detail-info-text">
              {joinArray(', ', pickUpLocation)}
            </p>
            {dropOffLocation && (
              <p className="review-box-detail-info-text">
                {joinArray(', ', dropOffLocation)}
              </p>
            )}
          </div>
          <Button type="button" className="edit-btn" onClick={() => toStep(2)}>
            {t('edit')}
          </Button>
        </div>
        <div className="review-box-detail">
          <div className="review-box-detail-info">
            <p className="review-box-detail-info-text">
              {t('reservationAdditionalDetails')}
            </p>
            <p className="review-box-detail-info-text">
              {t('renterAge')}:
              {+(driverAge || 0) === 25 ? ' 25+' : ` ${driverAge}`}
            </p>
            <p className="review-box-detail-info-text">
              {t('reservationPromoDetails', {
                code: !!promotionCode ? promotionCode : '-',
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
