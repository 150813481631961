import React, { useMemo } from 'react'

import { PopupVariant, TFunc } from '@/types'
import { Button, Modal } from '@/components'
import { useLanguages } from '@/hooks'

interface IHandlerPopup {
  onClose: TFunc
  message?: string
  isOpen?: boolean
  customTitle?: string
  variant?: PopupVariant
}

export const HandlerPopup: React.FC<IHandlerPopup> = ({
  isOpen,
  onClose,
  message,
  customTitle,
  variant = 'error',
}) => {
  const { t } = useLanguages()

  const { title, description } = useMemo(() => {
    const isError = variant === 'error'

    return {
      title: isError ? t('popupError') : t('popupSuccess'),
      description: isError ? t('popupErrorDesc') : t('popupSuccessDesc'),
    }
  }, [t, variant])

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      closeOnBackdrop={false}
      modalContentClassName="min"
    >
      <h1 className="modal-subtitle">{customTitle || title}</h1>
      <div className="modal-hr"></div>
      <p className="modal-spantext">{message || description}</p>
      <div className="modal-btn-group">
        <Button className="btn rounded-btn green" onClick={onClose}>
          {t('popupOk')}
        </Button>
      </div>
    </Modal>
  )
}
