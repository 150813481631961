import React from 'react'
// useEffect,
//  useState
// useCallback,

import { ITransportationForEventsResponse } from '@/types'
import { CollaborateItem, Slider } from '@/components'
import {
  useLanguages,
  //  useOnResize
} from '@/hooks'

export const CollaboratedSection: React.FC<
  ITransportationForEventsResponse
> = data => {
  const { t } = useLanguages()
  // const [centerMode, setCenterMode] = useState(false)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setCenterMode(window?.innerWidth < 768)
  //     clearTimeout(timeout)
  //   }, 200)
  // }, [])

  // const onResize = useCallback(() => {
  //   if (window?.innerWidth < 768) {
  //     setCenterMode(true)
  //   } else {
  //     setCenterMode(false)
  //   }
  // }, [])

  // useOnResize(onResize)

  return (
    <section className="collaborated">
      <h2 className="section-title text-center text-m-start">
        {t('collaboratedWeCollaborated')}
      </h2>
      <p className="section-subtitle text-m-start mb-5">
        {t('collaboratedOutCollaboration')}
      </p>
      <Slider
        data={data.slider || []}
        Component={CollaborateItem}
        className="collaborated-carousel"
        settings={{ infinite: false }}
      />
    </section>
  )
}
