import React, { useCallback, useEffect, useState } from 'react'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { useOnResize } from '@/hooks'

import { ISliderProps } from './types'

export const Slider: React.FC<ISliderProps> = ({
  data,
  settings,
  Component,
  className,
  counts = 4,
  scrollCounts = 1,
}) => {
  const [_counts, setCounts] = useState(settings?.slidesToShow || counts)

  const onResize = useCallback(() => {
    if (window.innerWidth < 768) {
      setCounts(settings?.centerMode ? 1 : 1.2)
    } else if (window.innerWidth < 991) {
      setCounts(2)
    } else if (window?.innerWidth < 1260) {
      setCounts(3)
    } else {
      setCounts(settings?.slidesToShow || counts)
    }
  }, [settings?.slidesToShow, settings?.centerMode, counts])

  useOnResize(onResize)

  useEffect(() => {
    onResize()
  }, [onResize])

  return (
    <SlickSlider
      {...{
        dots: false,
        infinite: data.length > _counts,
        ...settings,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
            },
          },
          ...(settings?.responsive || []),
        ],
      }}
      className={className}
      slidesToShow={_counts}
      arrows={data.length > counts}
      slidesToScroll={settings?.slidesToScroll || scrollCounts}
    >
      {data.map((datum, idx) => (
        <Component {...datum} key={idx} />
      ))}
    </SlickSlider>
  )
}
