import React from 'react'
import { useRouter } from 'next-intl/client'
import classNames from 'classnames'

import { bestOfferPath, getImage, rem, reservationsPath } from '@/utils'
import { Button, CustomImage } from '@/components'
import { IBestOfferItem } from '@/types'
import { useLanguages } from '@/hooks'

export const OfferCard: React.FC<IBestOfferItem & { className?: string }> = ({
  slug,
  title,
  image,
  className,
  small_description,
}) => {
  const router = useRouter()
  const { t } = useLanguages()
  return (
    <div className="offer-card">
      <div className="offer-card-img-container">
        <CustomImage
          priority
          width={1280}
          height={(1280 * 9) / 16}
          className="offer-card-img"
          src={getImage(image, false)}
          style={{ width: '100%', height: rem(320) }}
        />
      </div>
      <div className={classNames('card-body', className)}>
        <div className="card-info">
          <p className="card-title">{title}</p>
          <div
            className="card-text"
            dangerouslySetInnerHTML={{ __html: small_description || '' }}
          />
        </div>
        <Button
          className="btn section-btn"
          onClick={() => {
            router.push(`${reservationsPath}${bestOfferPath}/${slug}`)
          }}
        >
          {t('learnMore')}
        </Button>
      </div>
    </div>
  )
}
