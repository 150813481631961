import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { SHOW_TIMEOUT_POPUP, TIMEOUT } from '@/constants'
import { useLanguages, useReservation } from '@/hooks'
import { countDownCalculation } from '@/utils'
import { Button, Modal } from '@/components'

export const TimeOutModal: React.FC = () => {
  const { t } = useLanguages()
  const { onContinue, onCloseCountDown, showCountDown } = useReservation()

  return (
    <Modal
      showCloseButton
      isOpen={showCountDown}
      onClose={onCloseCountDown}
      modalContentClassName="min"
    >
      <h1 className="modal-subtitle font-light">
        {t('reservationSessionTimeout')}
      </h1>
      {showCountDown && <CountDown />}
      <div className="d-flex justify-end">
        <Button className="btn rounded-btn green" onClick={onContinue}>
          {t('reservationContinueWorking')}
        </Button>
      </div>
    </Modal>
  )
}

const CountDown: React.FC = () => {
  const { t } = useLanguages()
  const [timer, setTimer] = useState<string>('')
  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const endDate = moment().add(TIMEOUT - SHOW_TIMEOUT_POPUP, 'millisecond')
    intervalRef.current = setInterval(() => {
      setTimer(countDownCalculation(endDate))
    }, 1000)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <p className="modal-mini-text">
      {t('reservationTimerDescription', { timer })}
    </p>
  )
}
