import React from 'react'
import Slider, { Settings } from 'react-slick'

import { Button, CustomImage } from '@/components'
import { getImage, rem } from '@/utils'
import { useLanguages } from '@/hooks'
import { IOfferType } from '@/types'

import bestOfferRUImg from '@/assets/images/best-offer-ru.png'
import bestOfferImg from '@/assets/images/best-offer.png'

const settings: Settings = {
  dots: false,
  infinite: false,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1.1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1.1,
      },
    },
  ],
}

export const LongTermRentalsSection: React.FC<{
  offers: IOfferType[]
}> = ({ offers }) => {
  return (
    <section className="long-term">
      <div className="long-term-offers-box">
        <Slider {...settings} className="long-term-offers-carousel">
          {offers?.map?.((offer, idx) => (
            <LongTermRentalItem key={idx} {...offer} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

export const LongTermRentalItem: React.FC<IOfferType> = ({
  title,
  picture,
  description,
  button_link,
  is_home_page,
}) => {
  const { t, appLang } = useLanguages()
  return (
    <div className="offer-card">
      <div className="offer-card-img-container">
        <CustomImage
          priority
          width={1280}
          height={(1280 * 9) / 16}
          className="offer-card-img"
          src={getImage(picture, false)}
          style={{ width: '100%', height: rem(320) }}
        />
        {!!is_home_page && (
          <CustomImage
            width={280}
            height={280}
            className="best-offer"
            src={appLang === 'ru' ? bestOfferRUImg.src : bestOfferImg.src}
          />
        )}
      </div>
      <div className="card-body">
        <div className="card-info">
          <p className="card-title">{title}</p>
          <div
            className="card-text"
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
        </div>
        <Button
          className="btn section-btn"
          onClick={() => {
            window.open(button_link, '_self')
          }}
        >
          {t('learnMore')}
        </Button>
      </div>
    </div>
  )
}
