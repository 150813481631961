import React from 'react'

import { IList } from '@/types'

import { Icon } from '@/components'

interface IExtensiveSectionProps {
  items: IList[]
}

export const ExtensiveSection: React.FC<IExtensiveSectionProps> = ({
  items,
}) => {
  return (
    <section className="extensive">
      <div className="extensive-cards">
        {items.map((item, idx) => (
          <div key={idx} className="extensive-card">
            <Icon name="check-circle-outline" />
            <div className="extensive-card-info">
              <p className="extensive-card-text">{item.label}</p>
              {!!item.desc && (
                <p className="extensive-card-text">{item.desc}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
