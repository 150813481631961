import React, { useCallback, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import axios from 'axios'

import {
  Form,
  Icon,
  Modal,
  Button,
  TextInput,
  CustomImage,
  PhoneNumberInput,
} from '@/components'
import {
  getImage,
  joinArray,
  carLeaseForm,
  generateFormData,
  humanFormatNumber,
  cleanDecimalPartForLanguage,
} from '@/utils'
import {
  PopupVariant,
  TCarLeaseSchema,
  ICarLeaseCarType,
  ICurrencyResponse,
} from '@/types'
import { useHookForm, useLanguages } from '@/hooks'
import { API_ENDPOINT } from '@/constants'
import endpoints from '@/apis/endpoints'

import popularCarIcon from '@/assets/images/icons/popular-cars-menu-1.svg'
import closeIcon from '@/assets/images/icons/close.svg'
import doneIcon from '@/assets/images/icons/done.svg'

interface ILeasingDetailsProps {
  onClose?: () => void
  item: ICarLeaseCarType
  exchange?: ICurrencyResponse['data']
  onTrigger?: (_variant: PopupVariant) => void
}

export const LeasingDetails: React.FC<ILeasingDetailsProps> = ({
  item,
  onClose,
  exchange,
  onTrigger,
}) => {
  const { t, currency } = useLanguages()

  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(carLeaseForm, {
    defaultValues: {
      carLeaseId: item.id,
      carId: item?.car_id || item?.car?.id,
    },
  })

  const onSubmit: SubmitHandler<TCarLeaseSchema> = useCallback(
    async data => {
      try {
        const formData = generateFormData(data)
        const response = await axios.post(
          `${API_ENDPOINT}${endpoints.lease}`,
          formData,
        )
        if (response.status === 200) {
          onClose?.()
          const timeout = setTimeout(() => {
            onTrigger?.('success')
            clearTimeout(timeout)
          }, 200)
        }
      } catch (error: any) {
        onClose?.()
        const timeout = setTimeout(() => {
          onTrigger?.('error')
          clearTimeout(timeout)
        }, 200)
      }
    },
    [onClose, onTrigger],
  )

  const _convertedPrice = useMemo(
    () =>
      (
        (exchange?.[currency.name.toUpperCase()]?.rate || 1) *
        +`${item.price}`.replace(/[,]/g, '')
      ).toFixed(2),
    [exchange, currency, item.price],
  )

  const transmission_type = item.car.transmission_type
  const calculationDetails = item?.calculation_information?.split?.('\n')
  const inclusions = item?.lease_inclusions?.split?.('\n')
  const hasAc = item?.car?.has_air_conditioner
  return (
    <Modal
      isOpen
      closeOnBackdrop
      onClose={onClose}
      title={t('fillCarPopup')}
      modalBodyClassName="modal-scroll"
      header={
        <div className="car-lease-details-modal">
          <div className="row gap">
            <div className="w-66 border-right">
              <h1 className="car-lease-details-title">
                {t('leasingPopupCarLeaseDetails')}
              </h1>
              <h3 className="car-lease-details-subtitle">
                {t('selectedVehicleName', {
                  name: joinArray(' ', item.car.manufacturer, item.car.model),
                })}
              </h3>
              <div className="car-lease-details-menu">
                <div className="car-lease-details-menu-item">
                  <CustomImage
                    width={19}
                    height={19}
                    src={popularCarIcon.src}
                  />
                  {/* @ts-ignore */}
                  <p>{t(transmission_type?.toLowerCase())}</p>
                </div>
                <div className="car-lease-details-menu-item">
                  <Icon name="popular-cars-menu-2" />
                  <p>{hasAc ? t('leasingPopupYes') : t('leasingPopupNo')}</p>
                </div>
                <div className="car-lease-details-menu-item">
                  <Icon name="popular-cars-menu-3" />
                  <p>
                    {t('leasingPopupSeats', {
                      count: item?.car?.seating_capacity,
                    })}
                  </p>
                </div>
                <div className="car-lease-details-menu-item">
                  <Icon name="popular-cars-menu-4" />
                  <p>{t('leasingPopupBags', { count: item?.car?.big_bags })}</p>
                </div>
                <div className="car-lease-details-menu-item small">
                  <Icon name="popular-cars-menu-5" />
                  <p>
                    {t('leasingPopupBags', { count: item?.car?.small_bags })}
                  </p>
                </div>
              </div>
              <h3 className="car-lease-details-subtitle">
                {t('leasingPopupInclusions')}
              </h3>
              <div className="inclusions-ul">
                {inclusions?.map?.((inclusion, idx) => (
                  <div key={idx} className="inclusions-li">
                    <CustomImage
                      width={16}
                      height={16}
                      src={
                        inclusion.includes('-') ? closeIcon.src : doneIcon.src
                      }
                    />
                    <p>{inclusion.slice(1)}</p>
                  </div>
                ))}
              </div>
              <h3 className="car-lease-details-subtitle">
                {t('leasingPopupInfoImportant')}
              </h3>
              <div
                className="car-lease-details-more"
                dangerouslySetInnerHTML={{
                  __html: item?.important_information || '',
                }}
              />
            </div>
            <div className="w-33 d-flex flex-column justify-end">
              <img
                width={240}
                height={223}
                alt="Car img"
                className="w-100"
                style={{ objectFit: 'contain' }}
                src={getImage(item?.car?.image, false)}
              />
              <div className="car-lease-modal badges">
                <h1>{t('leasingPopupCalculationBased')}</h1>
                {calculationDetails?.map?.((info, idx) => (
                  <div key={idx} className="car-lease-modal-badge">
                    <h1>{info.split('=')?.[0]}</h1>
                    <p>{info.split('=')?.[1]}</p>
                  </div>
                ))}
              </div>
              <div className="car-lease-modal price mb-0">
                <h1>{t('leasingPopupPrice')}</h1>
                <div className="monthly-price">
                  <h1>{t('leasingPopupMonthlyPrice')}</h1>
                  <p>
                    {t('currency', {
                      currency: '',
                      value: cleanDecimalPartForLanguage(
                        `${humanFormatNumber(
                          `${_convertedPrice}`.replace(/[,]/g, ''),
                          ',',
                        )} ${currency.name}`,
                        currency.name,
                      ),
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="form-fields-box">
        <Form className="form-fields" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('firstName')}
            label={t('firstName')}
            error={errors.firstName?.message}
            placeholder={t('firstName')}
          />
          <TextInput
            required
            className="w-50"
            hasSuffix={false}
            {...register('lastName')}
            label={t('lastName')}
            error={errors.lastName?.message}
            placeholder={t('lastName')}
          />
          <TextInput
            required
            disabled
            className="w-50"
            hasSuffix={false}
            error={errors.carLeaseId?.message}
            label={t('leasingPopupRequestedLeasingVehicle')}
            placeholder={t('leasingPopupRequestedLeasingVehicle')}
            value={joinArray(' ', item?.car?.manufacturer, item?.car?.model)}
          />
          <PhoneNumberInput
            required
            className="w-50"
            hasSuffix={false}
            onChange={value => {
              setValue('contactNumber', value)
              trigger('contactNumber')
            }}
            label={t('contactNumber')}
            value={getValues('contactNumber')}
            error={errors.contactNumber?.message}
            placeholder={t('contactNumber')}
          />
          <TextInput
            required
            className="w-100"
            hasSuffix={false}
            {...register('emailAddress')}
            label={t('emailAddress')}
            error={errors.emailAddress?.message}
            placeholder={t('emailAddress')}
          />
          <div className="w-100 d-flex justify-end justify-m-center">
            <Button type="submit" className="btn big">
              {t('done')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
