import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { capitalize } from 'lodash'

import { reserveNowForm, ellipsizeMiddle, replacePhoneNumber } from '@/utils'
import { Form, Icon, Button, ConfirmationModal } from '@/components'
import { useHookForm, useLanguages, useReservation } from '@/hooks'
import { TReserveNowForm } from '@/types'

import { RentalDetailsSection } from './rentalDetailsSection'
import { VehicleDetails } from './vehicleDetails'
import { FlightDetails } from './flightDetails'
import { DetailsForm } from './detailsForm'

export const ReviewReservation: React.FC = () => {
  const mounted = useRef(false)
  const { t, isRussian } = useLanguages()
  const [isOpen, setOpened] = useState(false)
  const {
    terms,
    onModify,
    isMobile,
    onReserveNow,
    reservationDetails,
    onExitWithoutModifying,
  } = useReservation()

  const {
    handler: {
      reset,
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(reserveNowForm, {
    defaultValues: {
      email: '',
      lastName: '',
      firstName: '',
      phoneNumber: '',
    },
  })

  const disabled = useMemo(
    () => !!reservationDetails?.reservationNumber,
    [reservationDetails?.reservationNumber],
  )

  const onSubmit: SubmitHandler<TReserveNowForm> = useCallback(
    data => {
      const request = disabled ? onModify : onReserveNow
      request(data)
    },
    [disabled, onModify, onReserveNow],
  )

  const userInfo: Partial<TReserveNowForm> = useMemo(
    () => ({
      email: reservationDetails?.email,
      lastName: reservationDetails?.lastName,
      firstName: reservationDetails?.firstName,
      phoneNumber: replacePhoneNumber(reservationDetails?.phoneNumber),
    }),
    [
      reservationDetails?.email,
      reservationDetails?.lastName,
      reservationDetails?.firstName,
      reservationDetails?.phoneNumber,
    ],
  )

  useEffect(() => {
    if (Object.values(userInfo).some(_v => _v!) && !mounted.current) {
      mounted.current = true
      reset(userInfo)
    }
  }, [reset, userInfo])

  const onTrigger = useCallback(
    (bool: boolean) => () => {
      setOpened(bool)
    },
    [],
  )

  const onAction = useCallback(() => {
    onTrigger(false)()
    onExitWithoutModifying()
  }, [onTrigger, onExitWithoutModifying])

  return (
    <>
      <section className="review">
        <div className="container">
          <div className="review-header-container">
            <h1 className="reservation-title">
              {t('reservationReviewAndReserve')}
            </h1>
            {disabled && (
              <Button
                onClick={onTrigger(true)}
                className="reservation-btn bordered"
              >
                {t('reservationExitWithoutModifying')}
              </Button>
            )}
          </div>
          <Form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="review-container">
              {isMobile && (
                <>
                  <div className="review-box d-none d-m-block">
                    <DetailsForm
                      errors={errors}
                      trigger={trigger}
                      setValue={setValue}
                      register={register}
                      disabled={disabled}
                      getValues={getValues}
                    />
                  </div>
                  <FlightDetails />
                </>
              )}
              <RentalDetailsSection />
              <VehicleDetails />
              <div className="review-box">
                <h2 className="review-box-title">
                  {t('reservationRentalPolicies')}
                </h2>
                <div className="review-box-links">
                  {terms?.map((term, idx) => (
                    <div
                      key={idx}
                      className="review-box-link"
                      // @ts-ignore
                      onClick={() => window?.toggleTermsModal?.(idx)}
                    >
                      {ellipsizeMiddle(
                        isRussian
                          ? // @ts-ignore
                            t(`${term.subCode}Title`)
                          : capitalize(term.title),
                        40,
                      )}
                      <Icon name="chevron-right" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="review-box d-m-block d-none">
                <CompleteBooking />
              </div>
            </div>
            <div className="reserve-container">
              {!isMobile && (
                <>
                  <div className="review-box d-m-none">
                    <DetailsForm
                      errors={errors}
                      trigger={trigger}
                      register={register}
                      setValue={setValue}
                      disabled={disabled}
                      getValues={getValues}
                    />
                  </div>
                  <FlightDetails />
                </>
              )}
              <div className="review-box d-m-none">
                <CompleteBooking />
              </div>
              <div className="row justify-end">
                <div className="w-33">
                  <Button type="submit" className="btn w-100">
                    {disabled
                      ? t('reservationModify')
                      : t('reservationReserve')}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
      <ConfirmationModal
        isOpen={isOpen}
        onAction={onAction}
        onClose={onTrigger(false)}
        closeButtonLabel={t('stayHere')}
        description={t('reservationExitDesc')}
        title={t('reservationExitModification')}
        confirmButtonLabel={t('reservationExit')}
      />
    </>
  )
}

const CompleteBooking: React.FC = () => {
  const { t } = useLanguages()
  return (
    <div className="review-form">
      <h1 className="review-form-title">{t('reservationCompleteBooking')}</h1>
      <h1 className="review-form-text">
        {t('reservationCompleteBookingDesc')}
      </h1>
    </div>
  )
}
