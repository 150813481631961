import React from 'react'

import { TextLink, CustomImage, Icon } from '@/components'
import { faqPath, replacePhoneNumber } from '@/utils'
import { SUPPORT_EMAIL } from '@/constants'
import { useLanguages } from '@/hooks'
import { IPageDetail } from '@/types'

import envelopeIcon from '@/assets/images/icons/email-big.svg'
import clockIcon from '@/assets/images/icons/clock.svg'
import faqIcon from '@/assets/images/icons/faq.svg'

export const ContactUsSection: React.FC<IPageDetail[]> = data => {
  const { t } = useLanguages()
  return (
    <div className="big-container">
      <h1 className="landing-title">{t('contactUsTitle')}</h1>
      <div className="row mb-5">
        <div className="w-50">
          <TextLink
            defaultAction
            className="contact-us-card"
            href={`mailto:${SUPPORT_EMAIL}`}
          >
            <CustomImage
              width={51}
              height={51}
              src={envelopeIcon.src}
              className="contact-us-card-icon"
            />
            <div className="contact-us-card-link">
              {t('contactUsEmailCardTitle')}
            </div>
            <p className="contact-us-card-text">
              {t('contactUsEmailDescription')}
            </p>
          </TextLink>
        </div>
        <div className="w-50">
          <TextLink href={faqPath} className="contact-us-card">
            <CustomImage
              width={51}
              height={51}
              src={faqIcon.src}
              className="contact-us-card-icon"
            />
            <div className="contact-us-card-link">
              {t('contactUsFaqCardTitle')}
            </div>
            <p className="contact-us-card-text">
              {t('contactUsFaqDescription')}
            </p>
          </TextLink>
        </div>
      </div>
      {data?.map?.((_location, idx) => (
        <React.Fragment key={idx}>
          <div className="line mb-5" />
          <h1 className="landing-title">{_location.title}</h1>
          <div className="row mb-5 mt-5">
            <div className="w-50">
              <div className="contact-us-box">
                <CustomImage width={20} height={20} src={clockIcon.src} />
                <p>
                  {t('contactUsOperationDaysAndHours')} <br />
                  {_location.days_and_hours} {_location.working_hours}
                </p>
              </div>
            </div>
            <div className="w-50">
              <div className="contact-us-box">
                <Icon name="footer-phone" />
                <TextLink
                  defaultAction
                  href={`tel:${replacePhoneNumber(_location.phone_number)}`}
                >
                  {replacePhoneNumber(_location.phone_number)}
                </TextLink>
              </div>
              <div className="contact-us-box">
                <Icon name="footer-email" />
                <TextLink defaultAction href={`mailto:${_location.email}`}>
                  {_location.email}
                </TextLink>
              </div>
              <div className="contact-us-box">
                <Icon name="footer-location" />
                <p>{_location.address}</p>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
