import { TLang, TParams } from '@/types'

export const homePath = '/'
export const reservationsPath = '/reservations'
export const startPath = '/start'
export const editPath = '/edit'
export const oneWayRentalsPath = '/one-way-rentals'
export const monthAndMoreRentalsPath = '/month-and-more-rentals'
export const rentWithDriverPath = '/rent-with-driver'
export const luxuryCarsPath = '/luxury-cars'
export const bestOfferPath = '/best-offer'

export const forBusinessesPath = '/for-businesses'
export const corporateRentalsPath = '/corporate-rentals'
export const longTermRentalsPath = '/long-term-rentals'
export const transportationForEventsPath = '/transportation-for-events'
export const transferServicesPath = '/transfer-services'

export const carLeasePath = '/car-lease'
export const vehiclesPath = '/vehicles'
export const apiPath = '/api'

export const rentInLocationPath = '/:location'
export const aboutPath = '/about-us'

export const termsPath = '/terms-and-conditions'
export const contactUsPath = '/contact-us'
export const blogPostPath = '/blog'
export const fleetPath = '/fleet'
export const faqPath = '/faq'

export const reservationSuccessPath = '/reservations/success'

export const termsOfUsePath = '/terms-of-use'
export const cookiePolicyPath = '/cookie-policy'
export const privacyPolicyPath = '/privacy-armenia'

export const yerevanSlug = 'rent-a-car-in-yerevan'
export const yerevanAirportSlug = 'car-rental-yerevan-airport'

export const locationPath = `${reservationsPath}/location`
export const vehiclePath = `${reservationsPath}/vehicle`
export const extrasPath = `${reservationsPath}/extras`
export const reviewPath = `${reservationsPath}/review`

export const reservationPaths = [
  reviewPath,
  extrasPath,
  vehiclePath,
  locationPath,
]

export const isActiveLink = (locationPath: string, path: string): boolean => {
  const _path = locationPath.split('/')

  return _path.includes(path)
}

export const generatePath = (
  path: string,
  params: TParams = {},
  queryData?: Record<string, string | number | boolean>[],
): string => {
  let newPath: string = path
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  if (queryData && queryData.length > 0) {
    const query = queryData
      .map(item => Object.keys(item)[0] + '=' + Object.values(item)[0])
      .join('&')
    newPath = newPath + '?' + query
  }

  return newPath
}

export const generateSubPath = (
  path: string,
  subPath: string,
  params: TParams = {},
): string => {
  let newPath: string = `${path}${subPath}`
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  return newPath
}

const paths = [
  homePath,
  aboutPath,
  blogPostPath,
  `/${yerevanAirportSlug}`,
  `/${yerevanSlug}`,
  contactUsPath,
  cookiePolicyPath,
  faqPath,
  fleetPath,
  `${forBusinessesPath}${carLeasePath}`,
  `${forBusinessesPath}${corporateRentalsPath}`,
  `${forBusinessesPath}${longTermRentalsPath}`,
  `${forBusinessesPath}${transferServicesPath}`,
  `${forBusinessesPath}${transportationForEventsPath}`,
  privacyPolicyPath,
  `${reservationsPath}${bestOfferPath}`,
  `${reservationsPath}${editPath}`,
  `${reservationsPath}${luxuryCarsPath}`,
  `${reservationsPath}${monthAndMoreRentalsPath}`,
  `${reservationsPath}${oneWayRentalsPath}`,
  `${reservationsPath}${rentWithDriverPath}`,
  `${reservationsPath}${startPath}`,
  termsPath,
  termsOfUsePath,
]

export const appPaths: Record<TLang, string[]> = {
  en: paths,
  ru: paths,
}
