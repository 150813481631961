import { TLang, TCurrency } from '@/types'

export const APP_NAME = 'Enterprise'
export const LANG_KEY = '@lang'
export const CURRENCY_KEY = '@currency'
export const START_KEY = '@reservation_start'
export const CONFIRMED_KEY = '@reservation_confirmed'

export const DEFAULT_LANG = (process.env.NEXT_PUBLIC_DEFAULT_LANG ||
  'en') as TLang

export const APP_LANGUAGES: TLang[] = [
  'en',
  // 'hy',
  'ru',
]

export const APP_CURRENCIES: TCurrency[] = [
  {
    name: 'AMD',
    symbol: '֏',
  },
  {
    name: 'USD',
    symbol: '$',
  },
  {
    name: 'EUR',
    symbol: '€',
  },
  {
    name: 'RUB',
    symbol: '₽',
  },
]

export const API_ENDPOINT = `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}://${process.env.NEXT_PUBLIC_API_ENDPOINT}`

export const MAIN_ADDRESS = process.env.NEXT_PUBLIC_MAIN_ADDRESS
export const MAIN_ADDRESS_LINK = process.env.NEXT_PUBLIC_ADDRESS_LINK
export const SUPPORT_PHONE = process.env.NEXT_PUBLIC_SUPPORT_PHONE
export const SUPPORT_EMAIL = process.env.NEXT_PUBLIC_SUPPORT_EMAIL
// timeout checking in milliseconds
export const TIMEOUT_CHECK_INTERVAL =
  +(process.env.NEXT_PUBLIC_INTERVAL_FOR_CHECKING_TIMEOUT || 1) * 1000
// timeout in milliseconds
export const TIMEOUT = +(process.env.NEXT_PUBLIC_TIMEOUT || 1200) * 1000
export const SHOW_TIMEOUT_POPUP =
  +(process.env.NEXT_SHOW_TIMEOUT_POPUP || 900) * 1000
