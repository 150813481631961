import { DateRange } from 'react-day-picker'
import moment from 'moment'
import _, { capitalize } from 'lodash'

import { IBranch } from '@/types'

import { joinArray } from './misc'

export const formatDateTime = (
  dateTime: Date | string | number,
  format: string,
): string => moment(dateTime).format(format)

export const isDisableDate = (
  curDate: Date,
  disabledList: Date[] = [],
  maxDate?: Date,
  minDate?: Date,
): boolean => {
  if (maxDate) {
    if (moment(curDate).isAfter(maxDate)) {
      return true
    }
  }
  if (minDate) {
    if (moment(curDate).isBefore(minDate)) {
      return true
    }
  }
  return !!disabledList?.some(d => moment(d).isSame(curDate, 'day'))
}

export const getDateRange = (
  curVal: DateRange,
  newVal: DateRange | undefined,
): DateRange => {
  if (!newVal) return { from: undefined, to: undefined }
  if (newVal.from && newVal.to) {
    if (moment(curVal.from).isAfter(newVal.from)) {
      return { from: newVal.from, to: newVal.to }
    }
    return { from: newVal.to, to: newVal.from }
  } else if (newVal.from) {
    return newVal
  } else if (newVal.to) {
    return { from: newVal.to, to: curVal.from }
  }

  return { from: undefined, to: undefined }
}

export const getRangeText = (
  value?: DateRange | undefined | null,
  format = 'MMM DD, YYYY',
  defaultEnd = true,
): string => {
  if (!value) return ''

  let res = ''
  if (value.from !== undefined) {
    res = formatDateTime(value.from, format)
  }
  if (value.to) {
    res += ` - ${formatDateTime(value.to, format)}`
  } else if (defaultEnd) {
    res += ` - ${moment(value.from).endOf('month').format(format)}`
  }

  return res
}

export const minutesToTimeStr = (minutes: number): string => {
  if (!minutes) return '0m'

  const sign = minutes < 0 ? '-' : ''

  const _minutes = Math.abs(minutes)
  const daysDiff = Math.floor(_minutes / 1440)
  const hoursDiff = Math.floor((_minutes % 1440) / 60)
  const minsDiff = _minutes % 60

  let timeStr = sign
  if (daysDiff) {
    timeStr += `${daysDiff}d `
  }
  if (hoursDiff) {
    timeStr += `${hoursDiff}h `
  }
  if (minsDiff) {
    timeStr += `${minsDiff}m`
  }

  return timeStr
}

export const getTimeDiff = (
  start: Date | string | number,
  end: Date | string | number,
  unit: 'minutes' | 'seconds' | 'hours',
): number => moment(start).diff(end, unit)

export const isAlertExpiring = (date: Date | string | number) =>
  getTimeDiff(date, new Date(), 'minutes') < 720

export const getMinDate = (days: string[]) => {
  let minDate: Date = new Date(days[0])
  days.forEach(day => {
    if (moment(minDate).isAfter(new Date(day))) minDate = new Date(day)
  }, [])

  return formatDateTime(minDate, 'YYYY-MM-DD')
}

/**
 * Get timestamp in seconds from date
 * @param value Date | string | undefined : Selected value
 * @param type 'date' | 'datetime' : Determine whether to return date or datetime value
 * @param shouldEndDay boolean : Determine whether return end of day or start of day when `type` is 'date`
 * @param defaultValue Date | string | undefined : Default value
 * @returns number : Timestamp value in seconds
 */
export const getTimeStamp = (
  value: Date | string | undefined,
  type: 'date' | 'datetime' = 'date',
  shouldEndDay = false,
  defaultValue?: Date | string,
): number => {
  let res = 0
  const tmstpInSeconds = (val: Date | string): number => {
    const datetime = moment(val)
    let timestamp = 0
    if (type === 'date') {
      timestamp = shouldEndDay
        ? datetime.endOf('day').unix()
        : datetime.startOf('day').unix()
    } else {
      timestamp = datetime.unix()
    }

    return timestamp
  }

  if (!value) {
    if (defaultValue) {
      res = tmstpInSeconds(defaultValue)
    }
  } else {
    res = tmstpInSeconds(value)
  }

  return res
}

export const getGreetingTime = (): 'morning' | 'afternoon' | 'evening' => {
  const now = new Date().getHours()
  if (now >= 0 && now <= 12) return 'morning'
  if (now > 12 && now < 18) return 'afternoon'

  return 'evening'
}

export const openDays = (
  location: IBranch,
): { label: string; value: string }[] => {
  const weekDays = new Array(7).fill(1).map(function (_, i) {
    const weekDay = moment(i, 'e')
      .startOf('week')
      .isoWeekday(i + 1)
    return {
      label: weekDay.format('dddd'),
      value: weekDay.locale('en').format('dddd'),
    }
  })

  const openedDays: { label: string; value: string }[] = []

  const startsWithPrefix = (value: any, key: keyof IBranch) =>
    key.startsWith('Branch_Close') || key.startsWith('Branch_Open')
  const dates = _.pickBy(location, startsWithPrefix)
  weekDays.forEach(day => {
    const _day = day.value.slice(0, 3)
    const openedAt = dates[`Branch_Open_${_day}`]
    const closedAt = dates[`Branch_Close_${_day}`]
    const value = (
      closedAt === openedAt ? openedAt : joinArray(' - ', openedAt, closedAt)
    ) as string
    openedDays.push({
      label: capitalize(day.label),
      value,
    })
  })
  return openedDays
}

export const countDownCalculation = (endTime: moment.Moment) => {
  const currentTime = moment()
  // const endTime = moment().add(TIMEOUT - SHOW_TIMEOUT_POPUP, 'millisecond')
  const duration = moment.duration(endTime.diff(currentTime))

  const minutes = duration.minutes()
  const seconds = duration.seconds()
  return `${minutes}:${seconds}`
}
