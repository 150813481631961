import React, { useCallback, useMemo, useState } from 'react'

import { useLanguages, useReservationSuccess } from '@/hooks'
import { Button, ConfirmationModal, CustomImage } from '@/components'

import printIcon from '@/assets/images/icons/print.svg'

type TModal = 'cancel' | 'modify'

export const ReservationModify: React.FC = () => {
  const { t } = useLanguages()
  const [isOpen, setOpened] = useState(false)
  const [type, setType] = useState<TModal>('cancel')
  const { onModify, isCanceled, onCancel } = useReservationSuccess()

  const onOpen = useCallback(
    (type: TModal) => () => {
      setType(type)
      setOpened(true)
    },
    [],
  )

  const onClose = useCallback(() => {
    setOpened(false)
  }, [])

  const onAction = useCallback(() => {
    const request = type === 'cancel' ? onCancel : onModify
    request(onClose)
  }, [onCancel, onClose, onModify, type])

  const { title, buttonLabel, description, closeButtonLabel } = useMemo(() => {
    const isModify = type === 'modify'
    return {
      title: isModify
        ? t('reservationModifyReservation')
        : t('reservationCancelReservation'),
      buttonLabel: isModify
        ? t('reservationModifyReservation')
        : t('reservationCancelReservation'),
      description: isModify
        ? t('reservationModifyReservationDesc')
        : t('reservationCancelReservationDesc'),
      closeButtonLabel: t('stayHere'),
    }
  }, [t, type])

  return (
    <>
      <section className="modify-reservation">
        <div className="reservation-container">
          <div className="d-flex justify-between align-start modify-line flex-wrap">
            <div className="modify-reservation-box">
              <p className="modify-reservation-text">
                {t('reservationEmailSent')}
              </p>
              <div className="modify-reservation-btns">
                <Button
                  className="modify-reservation-btn"
                  onClick={() => window.print()}
                >
                  <CustomImage src={printIcon} width={24} height={24} />
                  <p>{t('print')}</p>
                </Button>
              </div>
            </div>
            {!isCanceled && (
              <div className="d-flex reservation-btns">
                <Button
                  onClick={onOpen('modify')}
                  className="reservation-btn colored"
                >
                  {t('reservationModifyReservation')}
                </Button>
                <Button
                  onClick={onOpen('cancel')}
                  className="reservation-btn bordered"
                >
                  {t('reservationCancelReservation')}
                </Button>
              </div>
            )}
          </div>
          <div className="line"></div>
        </div>
      </section>
      <ConfirmationModal
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        onAction={onAction}
        description={description}
        confirmButtonLabel={buttonLabel}
        closeButtonLabel={closeButtonLabel}
      />
    </>
  )
}
