import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import {
  //  Icon
  Button,
  CustomImage,
} from '@/components'
import { TStartReservationStorageData } from '@/context'
import { useLanguages, useReservation } from '@/hooks'
import { getStorageValue } from '@/utils'
import { START_KEY } from '@/constants'
import { IBranch } from '@/types'

import { SelectLocation, Map } from './'

import searchIcon from '@/assets/images/icons/search.svg'
// import filterIcon from '@/assets/images/icons/filter.svg'
// import hideIcon from '@/assets/images/icons/hide.svg'

// eslint-disable-next-line no-unused-vars
enum Tabs {
  // eslint-disable-next-line no-unused-vars
  List,
  // eslint-disable-next-line no-unused-vars
  Map,
}

export const ChooseLocation: React.FC = () => {
  const { t } = useLanguages()
  const { locations, toStep } = useReservation()
  const [activeTab, setActiveTab] = useState(Tabs.List)
  const [data, setData] = useState<TStartReservationStorageData>()
  const [showLocationDetails, setShowLocationDetails] = useState<IBranch>()

  const onShowDetails = useCallback((_location?: IBranch) => {
    setShowLocationDetails(_location)
  }, [])

  useEffect(() => {
    const data = getStorageValue<TStartReservationStorageData>(START_KEY)
    if (data?.parameters) {
      setData(data)
    }
  }, [])

  return (
    <>
      <section className="choose-location">
        <h1 className="reservation-title">{t('reservationChooseLocation')}</h1>
        <div className="choose-location-info">
          <div className="choose-location-results">
            <div className="choose-location-result">
              <span>
                {t('reservationResults', { count: locations.length })}
              </span>
              <p>{data?.parameters?.pickUpLocation}</p>
              {!!data?.parameters?.differentLocation && (
                <p>{data?.parameters?.dropOffLocation}</p>
              )}
            </div>
            <div className="change-location" onClick={() => toStep(2, true)}>
              <CustomImage src={searchIcon.src} width={20} height={20} />
              <p>{t('reservationChangeLocation')}</p>
            </div>
          </div>
          {/* <div className="choose-location-tools">
            <div className="choose-location-tool">
              <CustomImage src={filterIcon.src} width={20} height={20} />
              <p>{t('reservationFilter')}</p>
              <Icon name="menu-drop-down" />
            </div>
            <div className="choose-location-tool">
              <CustomImage src={hideIcon.src} width={20} height={20} />
              <p>{t('reservationHide')}</p>
            </div>
          </div> */}
          <div className="choose-location-mobile-tools d-none">
            <Button className="btn-tools" onClick={() => toStep(2, true)}>
              <CustomImage src={searchIcon.src} width={12} height={12} />
              <p>{t('reservationChangeLocation')}</p>
            </Button>
            {/* <Button className="btn-tools">
              <CustomImage src={filterIcon.src} width={12} height={12} />
              <p>{t('reservationFilter')}</p>
            </Button> */}
          </div>
        </div>
      </section>
      <div className="location-tab">
        <Button
          className={classNames('tablinks', {
            active: activeTab === Tabs.List,
          })}
          onClick={() => setActiveTab(Tabs.List)}
        >
          {t('reservationList')}
        </Button>
        <Button
          className={classNames('tablinks', {
            active: activeTab === Tabs.Map,
          })}
          onClick={() => setActiveTab(Tabs.Map)}
        >
          {t('reservationMap')}
        </Button>
      </div>
      <section className="select-location">
        <SelectLocation
          onShowDetails={onShowDetails}
          isActive={activeTab === Tabs.List}
        />
        <Map
          location={showLocationDetails}
          isActive={activeTab === Tabs.Map}
          onClose={() => onShowDetails(undefined)}
        />
      </section>
    </>
  )
}
