import React, { useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'

import {
  DepthKeys,
  useLanguages,
  useReservation,
  useOnClickOutside,
} from '@/hooks'
import { Button, CustomImage, Popover } from '@/components'
import { TFunc } from '@/types'

import {
  Vehicle,
  DateRange,
  Locations,
  ExtraMobile,
  ExtraDetails,
  VehicleMobile,
  LocationMobile,
  DateRangeEditor,
  LocationsEditor,
  DateRangeMobile,
} from './components'

import baseStepElement from '@/assets/images/icons/baseStepElements.svg'
import stepperArrow from '@/assets/images/icons/stepper_arrow.svg'
import stepperLine from '@/assets/images/icons/stepper-line.svg'
import menuIcon from '@/assets/images/icons/stepper-menu.svg'

const steps: Record<
  string,
  {
    label: DepthKeys
    className?: string
    Component?: React.ElementType
    MobileComponent?: React.ElementType
  }
> = {
  1: {
    Component: DateRange,
    className: 'datetime',
    MobileComponent: DateRangeMobile,
    label: 'reservationRentalDetails',
  },
  2: {
    className: 'location',
    Component: Locations,
    MobileComponent: LocationMobile,
    label: 'reservationPickupAndReturn',
  },
  3: {
    Component: Vehicle,
    label: 'reservationVehicle',
    MobileComponent: VehicleMobile,
  },
  4: {
    Component: ExtraDetails,
    label: 'reservationExtras',
    MobileComponent: ExtraMobile,
  },
  5: {
    label: 'reservationReviewAndReserve',
  },
}

export const Stepper: React.FC = () => {
  const { t } = useLanguages()
  const divRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null)
  const { isDateEditing, isLocationEditing, isMobile } = useReservation()

  useEffect(() => {
    if (isDateEditing || isLocationEditing) {
      popoverRef.current?.onClose()
    }
  }, [isDateEditing, isLocationEditing])

  useOnClickOutside(divRef, true, popoverRef.current?.onClose)

  return (
    <>
      <section className="stepper">
        {Object.keys(steps).map(key => {
          const { label, Component } = steps[key]
          return (
            <React.Fragment key={key}>
              <Step
                step={+key}
                {...steps[key]}
                label={t(label)}
                info={Component && <Component />}
              />
              {steps?.[+key + 1] && <div className="stepper-line"></div>}
            </React.Fragment>
          )
        })}
        {!isMobile && (
          <>
            <DateRangeEditor />
            <LocationsEditor />
          </>
        )}
      </section>
      <section className="stepper-mobile d-none">
        {Object.keys(steps).map(key => (
          <React.Fragment key={key}>
            <StepMobileItem
              step={+key}
              {...steps[key]}
              label={t(steps[key].label)}
              onClick={() => popoverRef.current?.onTrigger()}
            />
            {steps?.[+key + 1] && (
              <div className="stepper-mobile-line">
                <CustomImage src={stepperLine.src} width={12} height={12} />
              </div>
            )}
          </React.Fragment>
        ))}
        <div>
          <Popover
            distance={0}
            hasArrow={false}
            ref={popoverRef}
            onOpened={() => document.body.classList.add('overlay')}
            onClosed={() =>
              !isDateEditing &&
              !isLocationEditing &&
              document.body.classList.remove('overlay')
            }
            button={
              <div className="stepper-menu">
                <CustomImage src={menuIcon.src} width={8} height={8} />
              </div>
            }
          >
            <div ref={divRef} className="stepper-opened-menu">
              {!isDateEditing && !isLocationEditing && (
                <>
                  {Object.keys(steps).map(key => {
                    const { MobileComponent } = steps[key]
                    return (
                      <StepperMobileRow
                        key={key}
                        step={+key}
                        {...steps[key]}
                        label={t(steps[key].label)}
                        onClick={popoverRef.current?.onClose}
                        info={MobileComponent && <MobileComponent />}
                      />
                    )
                  })}
                </>
              )}
            </div>
          </Popover>
          {isMobile && (
            <>
              <DateRangeEditor />
              <LocationsEditor />
            </>
          )}
        </div>
      </section>
    </>
  )
}

interface IStep {
  step: number
  label: string
  onClick?: TFunc
  className?: string
  info?: React.ReactNode
}

const Step: React.FC<IStep> = ({ step, label, info, className }) => {
  const { currentStep, toStep } = useReservation()

  const [isDone] = useMemo(() => [currentStep > step], [currentStep, step])

  return (
    <div
      className={classNames('stepper-item', {
        [className || '']: !!className,
      })}
      onClick={() => toStep(step)}
    >
      <div
        className={classNames('stepper-item-title', {
          active: isDone,
        })}
      >
        <CustomImage
          width={25}
          height={25}
          src={baseStepElement.src}
          style={{ objectFit: 'cover' }}
        />
        <div className="stepper-child">{step}</div>
        <p>{label}</p>
      </div>
      <div className="stepper-item-info">{info}</div>
    </div>
  )
}

const StepMobileItem: React.FC<IStep> = ({ step, label, onClick }) => {
  const { currentStep } = useReservation()
  const [isDone, isCurrent] = useMemo(
    () => [currentStep > step, currentStep === step],
    [currentStep, step],
  )

  return (
    <div
      className={classNames('stepper-mobile-item', {
        checked: isDone,
        active: isCurrent,
      })}
      onClick={onClick}
    >
      <span>{step}</span>
      {isCurrent && <p>{label}</p>}
      <CustomImage
        width={10}
        height={10}
        src={stepperArrow.src}
        style={{ objectFit: 'contain' }}
      />
    </div>
  )
}

const StepperMobileRow: React.FC<IStep> = ({ step, label, info, onClick }) => {
  const { t } = useLanguages()
  const { toStep, currentStep } = useReservation()
  return (
    <div
      onClick={() => {
        step > 2 && onClick?.()
        toStep(step)
      }}
      className={classNames('stepper-opened-menu-item', {
        checked: currentStep > step,
      })}
    >
      <div className="stepper-opened-menu-header">
        <div className="stepper-opened-menu-title">
          <div className="stepper-opened-menu-checker">
            <span>{step}</span>
            <CustomImage
              width={10}
              height={10}
              src={stepperArrow.src}
              style={{ objectFit: 'contain' }}
            />
          </div>
          {!!label && <p>{label}</p>}
        </div>
        {step <= 2 && (
          <Button
            className="stepper-opened-menu-edit"
            onClick={e => {
              e.stopPropagation()
              step > 2 && onClick?.()
              toStep(step)
            }}
          >
            {t('edit')}
          </Button>
        )}
      </div>
      <div className="stepper-opened-menu-body">
        {step <= 4 && step === currentStep && (
          <div className="stepper-opened-menu-select">{t('select')}</div>
        )}
        {info}
      </div>
    </div>
  )
}
