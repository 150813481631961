import { useMemo, forwardRef, useCallback, HTMLAttributes } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { DateRange, RangePickerTypes } from '@/types'
import { useLanguages } from '@/hooks'
import { Icon } from '../'

interface Props extends HTMLAttributes<HTMLDivElement> {
  text?: string
  label: string
  time?: string
  disabled?: boolean
  required?: boolean
  type?: RangePickerTypes
  showBothTriggers?: boolean
  value?: DateRange | undefined | null
  onChangeTime?: (_time: string) => void
}

const Trigger = forwardRef<HTMLDivElement, Props>(
  (
    {
      type,
      time,
      value,
      label,
      disabled,
      required,
      className,
      onChangeTime,
      showBothTriggers,
      ...rest
    },
    ref,
  ) => {
    const { t, appLang } = useLanguages()

    const { day, month, year } = useMemo(() => {
      const withoutDate = !value?.to && !value?.from
      moment.locale(appLang)
      const date = moment(type === 'return' ? value?.to : value?.from)
      const day = date.get('date')
      const month = date.format('MMM')
      const year = date.get('year')

      if (withoutDate) {
        date.startOf('hour').add(1, 'hour')
      }

      return {
        day,
        year,
        month,
      }
    }, [type, value?.from, value?.to, appLang])

    const onSelect = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChangeTime?.(e.target.value)
      },
      [onChangeTime],
    )

    const [hour, minutes] = useMemo(() => (time ?? '12:00').split(':'), [time])

    return (
      <div
        ref={ref}
        className={classNames('row', className, {
          disabled: !!disabled,
          'w-100': showBothTriggers,
        })}
        {...rest}
      >
        <div className="w-100">
          <div className={classNames('preview-input')}>
            {!!label && (
              <label>
                {label}
                {required && <sup> *</sup>}
              </label>
            )}
          </div>
          <div className="pick-date-box">
            <div className="pick-date-calendar">
              <div className="date-title">{day}</div>
              <div className="date-subtitle">
                {month} <br />
                {year}
              </div>
              <Icon name="chevron-right" />
            </div>
            <div
              onClick={e => e.stopPropagation()}
              className="pick-date-calendar time"
            >
              <label htmlFor={`${type}Time`}>
                <div className="date-title">{hour}</div>
                <div className="date-subtitle">
                  :{minutes} <br />
                  {moment(time, 'HH:mm').format('A')}
                </div>
                <Icon name="chevron-right" />
              </label>
              <select
                value={time}
                id={`${type}Time`}
                onChange={onSelect}
                className="pick-date-time-select"
              >
                <option value="default" disabled>
                  {t('selectTime', { type })}
                </option>
                {[...new Array(24).fill(1)].map((_, idx) => (
                  <option
                    key={idx}
                    value={moment()
                      .startOf('day')
                      .add(idx, 'hours')
                      .format('HH:mm')}
                  >
                    {moment()
                      .startOf('day')
                      .add(idx, 'hours')
                      .format('HH:mm A')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default Trigger
