import React, { useMemo } from 'react'
import _ from 'lodash'

import { useLanguages, useReservation } from '@/hooks'
import { joinArray } from '@/utils'

export const ExtraDetails: React.FC = () => {
  const { t } = useLanguages()
  const { reservationDetails } = useReservation()
  const { extras } = reservationDetails || {}
  const { equipments, coverage } = extras || {}

  const sum = useMemo(
    () =>
      (_.uniqBy(
        (equipments || [])?.filter(_v => _v.action !== 'Cancel'),
        'type',
      )?.length || 0) +
      (_.uniqBy((coverage || [])?.filter(_v => _v.action !== 'Cancel'), 'type')
        ?.length || 0),
    [coverage, equipments],
  )

  if (!sum) return null

  return <p>{joinArray(' ', sum + '', t('reservationExtras'))}</p>
}

export const ExtraMobile: React.FC = () => {
  const { t } = useLanguages()
  const { reservationDetails } = useReservation()
  const { extras } = reservationDetails || {}
  const { equipments, coverage } = extras || {}

  const sum = useMemo(
    () =>
      (_.uniqBy(
        (equipments || [])?.filter(_v => _v.action !== 'Cancel'),
        'type',
      )?.length || 0) +
      (_.uniqBy((coverage || [])?.filter(_v => _v.action !== 'Cancel'), 'type')
        ?.length || 0),
    [coverage, equipments],
  )

  if (!sum) return null
  return (
    <p className="stepper-opened-menu-value">
      {joinArray(' ', sum + '', t('reservationExtras'))}
    </p>
  )
}
