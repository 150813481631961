import React from 'react'
import Image from 'next/image'

import { ICustomImageProps } from './types'
import { rem } from '@/utils'

export const CustomImage: React.FC<ICustomImageProps> = ({
  src,
  width = 0,
  height = 0,
  style,
  ...rest
}) => {
  return (
    <Image
      {...rest}
      alt=""
      src={src}
      width={width}
      height={height}
      style={{ width: rem(width), height: rem(height), ...style }}
    />
  )
}
