import React from 'react'

import { ISliderType } from '@/types'
import { getImage } from '@/utils'

export const CollaborateItem: React.FC<ISliderType> = ({
  // id,
  image,
  title,
}) => {
  return (
    <div className="collaborated-carousel-box">
      <div
        style={{ backgroundImage: getImage(image) }}
        className="collaborated-carousel-box-img"
      ></div>
      <p className="collaborated-carousel-box-title text-center">{title}</p>
    </div>
  )
}
