import React, { useCallback, useMemo } from 'react'
import moment from 'moment'

import {
  Icon,
  // CustomImage,
} from '@/components'
import { joinArray, openDays, replacePhoneNumber } from '@/utils'
import { IBranch, TFunc } from '@/types'
import { useLanguages } from '@/hooks'

// import highlightOffIcon from '@/assets/images/icons/highlight-off-black.svg'
// import locationIcon from '@/assets/images/icons/location-on-black.svg'
// import infoIcon from '@/assets/images/icons/info.svg'

interface ILocationDetailsProps {
  onClose?: TFunc
  location?: IBranch
}

export const LocationDetails: React.FC<ILocationDetailsProps> = ({
  onClose,
  location,
}) => {
  const { t, appLang } = useLanguages()
  if (!location) return null
  const workingDates = useMemo(() => openDays(location), [location])

  const replace = useCallback(
    (value: string) => {
      if (appLang === 'ru') {
        return value.replaceAll('Hrs', 'часа')
      }
      return value
    },
    [appLang],
  )

  return (
    <div className="hours-services-modal">
      <div className="hours-services-modal-header">
        <p>{t('reservationHoursAndServices')}</p>
        <span className="close" onClick={onClose}>
          &times;
        </span>
      </div>
      <div className="hours-services-modal-body">
        <p className="hours-services-city">{location.Branch_Station_City}</p>
        <div className="hours-services-items">
          <div className="hours-services-item">
            <Icon name="footer-location" />
            <div
              dangerouslySetInnerHTML={{
                __html: joinArray(
                  '<br />',
                  location.Branch_Station_Address_1,
                  location.Branch_Station_Address_2,
                ),
              }}
            />
          </div>
          <div className="hours-services-item">
            <Icon name="footer-phone" />
            <p>{replacePhoneNumber(location?.Branch_Station_Phone)}</p>
          </div>
          {/* <div className="hours-services-item">
            <CustomImage src={locationIcon.src} width={24} height={24} />
            <p>
              {t('reservationPickupUnavailable')}
              <CustomImage src={infoIcon.src} width={20} height={20} />
            </p>
          </div>
          <div className="hours-services-item">
            <CustomImage src={highlightOffIcon.src} width={24} height={24} />
            <p>
              {t('reservationAfterHoursUnavailable')}
              <CustomImage src={infoIcon.src} width={20} height={20} />
            </p>
          </div> */}
        </div>
        <div className="hours-services-calendar">
          <div className="hours-services-calendar-title">
            {moment().format('MMMM DD, YYYY')}
          </div>
          {workingDates.map(days => (
            <div key={days.label} className="hours-services-calendar-item">
              <p>{days.label}</p>
              <p>{replace(days.value)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
