import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { capitalize, uniqBy } from 'lodash'
import classNames from 'classnames'

import { Button, CustomImage, Icon } from '@/components'
import { TCoverage, TEquipment, TExtras } from '@/types'
import { useLanguages, useReservation } from '@/hooks'

import removeIcon from '@/assets/images/icons/remove-circle-outline.svg'
import addIcon from '@/assets/images/icons/add-circle-outline.svg'

export const Extras: React.FC = () => {
  const mounted = useRef(false)
  const { t, currency } = useLanguages()
  const { extras, equipments, onSelectExtras, reservationDetails, parameters } =
    useReservation()
  const [selectedExtras, setExtras] = useState<TExtras>({
    coverage: [],
    equipments: [],
  })
  const [showMore, setShowMore] = useState<{
    coverage?: number
    equipments?: number
  }>({
    coverage: undefined,
    equipments: undefined,
  })
  const reservationNumber = reservationDetails?.reservationNumber

  useEffect(() => {
    if (reservationDetails?.extras && !mounted.current) {
      mounted.current = true
      const { equipments, coverage } = reservationDetails.extras

      setExtras({ equipments, coverage })
    }
  }, [reservationDetails])

  const onAction = useCallback(
    (
      payload: TEquipment | TCoverage,
      action: 'add' | 'remove',
      type: keyof TExtras,
    ) => {
      const handleAction = (prev: TExtras) => {
        let _prev = prev
        if (action === 'add') {
          // @ts-ignore
          _prev[type] = _prev[type].filter(_v => _v.type !== payload.type)

          // @ts-ignore
          _prev[type].push({
            ...payload,
            action: 'Add',
          })
        }
        if (action === 'remove') {
          // @ts-ignore
          _prev[type] = _prev[type].filter(_v => _v.type !== payload.type)

          if (reservationNumber) {
            // @ts-ignore
            _prev[type].push({
              ...payload,
              action: 'Cancel',
            })
          }
          _prev = {
            ...prev,
            [type]: _prev[type],
          }
        }
        return { ..._prev }
      }
      const _extras = handleAction(selectedExtras)
      setExtras({ ..._extras })
    },
    [selectedExtras, reservationNumber],
  )

  const _continue = useCallback(() => {
    onSelectExtras({
      coverage: uniqBy(selectedExtras.coverage, 'type'),
      equipments: uniqBy(selectedExtras.equipments, 'type'),
    })
  }, [onSelectExtras, selectedExtras])

  const onShowMore = useCallback(
    (idx: number, type: 'equipments' | 'extras') => () => {
      const isEquipments = type === 'equipments'
      const field = isEquipments ? 'equipments' : 'coverage'
      setShowMore(prev => ({
        ...prev,
        [field]: prev[field] === idx ? undefined : idx,
        [!isEquipments ? 'equipments' : 'coverage']: undefined,
      }))
    },
    [],
  )

  const isEvn = useMemo(
    () =>
      [
        parameters?.pickUpLocation.toLowerCase(),
        parameters?.dropOffLocation?.toLowerCase(),
      ].some(_v => _v?.includes('yerevan')),
    [parameters?.pickUpLocation, parameters?.dropOffLocation],
  )

  return (
    <section className="extras">
      <div className="extras-header">
        <h1 className="extras-title">{t('reservationAddExtras')}</h1>
        <Button className="btn continue-btn" onClick={_continue}>
          {t('reservationContinue')}
        </Button>
      </div>
      <div className="extras-warning">
        {t('reservationIncludes', {
          count: extras.filter(_v => _v.required === 'true').length,
        })}
      </div>
      <div className="extras-items-header">
        <h3 className="extras-items-header-title">
          {t('reservationProtectionProducts')}
        </h3>
        <p className="extras-items-header-price">{t('leasingPopupPrice')}</p>
      </div>
      <div className="extras-items">
        {extras
          .filter(_v => _v.required !== 'true')
          .map((extra, idx) => (
            <ExtrasItem
              key={idx}
              datum={{
                code: extra.code,
                type: extra.coverageType,
              }}
              type="coverage"
              onAction={onAction}
              hasExtraInfo={isEvn}
              // @ts-ignore
              label={t(`${extra.code}Title`)}
              showMore={showMore.coverage === idx}
              price={t('extrasPrice', {
                currency: currency.symbol,
                total: extra?.calculation?.unitCharge,
                // @ts-ignore
                period: t(extra?.calculation?.unitName),
              })}
              onMoreDetails={onShowMore(idx, 'extras')}
              active={selectedExtras.coverage.some(
                _v => _v.code === extra.code && _v.action !== 'Cancel',
              )}
            />
          ))}
      </div>
      <div className="extras-items-header">
        <h3 className="extras-items-header-title">
          {t('reservationEquipment')}
        </h3>
        <p className="extras-items-header-price">{t('leasingPopupPrice')}</p>
      </div>
      <div className="extras-items">
        {equipments?.map?.((equipment, idx) => {
          const filtered =
            equipment.calculation.find(_v => _v.unitName === 'RentalPeriod') ||
            equipment.calculation[0]
          return (
            <ExtrasItem
              key={idx}
              type="equipments"
              onAction={onAction}
              datum={{
                code: equipment.equipment.equipType,
                type: equipment.equipment.equipType,
                quantity: +equipment.equipment.quantity,
              }}
              hasExtraInfo={isEvn}
              price={t('extrasPrice', {
                currency: currency.symbol,
                total: filtered?.unitCharge,
                // @ts-ignore
                period: t(filtered?.unitName),
              })}
              showMore={showMore.equipments === idx}
              onMoreDetails={onShowMore(idx, 'equipments')}
              label={
                isEvn
                  ? // @ts-ignore
                    t(`${equipment.equipment.equipType}Title`)
                  : equipment.equipment.description
              }
              active={selectedExtras.equipments.some(
                _v =>
                  _v.type === equipment.equipment.equipType &&
                  _v.action !== 'Cancel',
              )}
            />
          )
        })}
      </div>
      <div className="d-flex justify-end">
        <Button className="btn continue-btn" onClick={_continue}>
          {t('reservationContinue')}
        </Button>
      </div>
    </section>
  )
}

interface IExtrasItemProps {
  label: string
  price: string
  active: boolean
  type: keyof TExtras
  showMore?: boolean
  hasExtraInfo?: boolean
  onMoreDetails?: () => void
  datum: TCoverage | TEquipment
  onAction: (
    _payload: TCoverage | TEquipment,
    _action: 'add' | 'remove',
    _type: keyof TExtras,
  ) => void
}

const ExtrasItem: React.FC<IExtrasItemProps> = ({
  type,
  label,
  datum,
  price,
  active,
  onAction,
  showMore,
  hasExtraInfo,
  onMoreDetails,
}) => {
  const { t } = useLanguages()

  const onClick = useCallback(
    (action: 'add' | 'remove') => () => {
      onAction(datum, action, type)
    },
    [datum, type, onAction],
  )
  return (
    <div
      className={classNames('extras-item', {
        active,
      })}
    >
      <h3 className="extras-item-title">{capitalize(label)}</h3>
      <p className="extras-item-price">{price}</p>
      {hasExtraInfo && (
        <div className="more-tool" onClick={onMoreDetails}>
          <p>{t('reservationReadDetails')}</p>
          <Icon name="arrow-drop-down" />
        </div>
      )}
      <Button
        className="btn change-btn"
        onClick={onClick(!active ? 'add' : 'remove')}
      >
        <CustomImage
          width={24}
          height={24}
          src={removeIcon.src}
          className="remove-icon"
        />
        <CustomImage
          width={24}
          height={24}
          src={addIcon.src}
          className="add-icon"
        />
        <p className="add">{t('add')}</p>
        <p className="remove">{t('remove')}</p>
      </Button>
      {hasExtraInfo && (
        <div
          className={classNames('extras-info', {
            show: showMore,
          })}
        >
          <div className="extras-line"></div>
          {/* @ts-ignore */}
          <p>{t(`${datum.code}Desc`)}</p>
        </div>
      )}
    </div>
  )
}
