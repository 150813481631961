export const airlineDetails = [
  {
    code: '1T',
    description: '1TIME AIRLINE',
  },
  {
    code: '7L',
    description: 'A B AIRLINES',
  },
  {
    code: 'ZY',
    description: 'ADA AIR',
  },
  {
    code: 'II',
    description: 'ADI DOMESTIC AIRLINES',
  },
  {
    code: 'JP',
    description: 'ADRIA AIRWAYS-AIRLINE OF SLOVENIA',
  },
  {
    code: 'A3',
    description: 'AEGEAN AVIATION',
  },
  {
    code: 'WL',
    description: 'AEORPERLAS AIRLINES',
  },
  {
    code: 'RE',
    description: 'AER ARANN EXPRESS',
  },
  {
    code: 'EI',
    description: 'AER LINGUS LIMITED',
  },
  {
    code: 'EE',
    description: 'AERO AIRLINES',
  },
  {
    code: 'E4',
    description: 'AERO ASIA INTERNATIONAL',
  },
  {
    code: 'JR',
    description: 'AERO CALIFORNIA',
  },
  {
    code: 'ML',
    description: 'AERO COSTA RICA',
  },
  {
    code: '02',
    description: 'AERO HONDURAS',
  },
  {
    code: 'YP',
    description: 'AERO LLOYD',
  },
  {
    code: 'P5',
    description: 'AERO RUPUBLICA',
  },
  {
    code: 'Z9',
    description: 'AERO ZAMBIAX',
  },
  {
    code: 'SX',
    description: 'AEROEJECUTIVO S.A. DE C.V.',
  },
  {
    code: 'SU',
    description: 'AEROFLOT-RUSSIAN INTL AIRLINES',
  },
  {
    code: 'AR',
    description: 'AEROLINEAS ARGENTINAS',
  },
  {
    code: 'BQ',
    description: 'AEROMAR',
  },
  {
    code: 'AM',
    description: 'AEROMEXICO',
  },
  {
    code: '5D',
    description: 'AEROMEXICO CONNECT',
  },
  {
    code: 'QO',
    description: 'AEROMEXPRESS S.A. DE C.V.',
  },
  {
    code: 'PL',
    description: 'AEROPERU - EMPRESA DE TRANSPORTES',
  },
  {
    code: 'VH',
    description: 'AEROPOSTAL',
  },
  {
    code: 'VV',
    description: 'AEROSVIT',
  },
  {
    code: 'VE',
    description: 'AEROVIAS VENEZOLANAS, S.A.',
  },
  {
    code: 'Y2',
    description: 'AFRICAN JOINT AIR SERVICES',
  },
  {
    code: '8U',
    description: 'AFRIQIHYA AIRWAYS',
  },
  {
    code: 'ZI',
    description: 'AIGLE AZUR',
  },
  {
    code: 'RK',
    description: 'AIR AFRIQUE',
  },
  {
    code: 'AH',
    description: 'AIR ALGERIE',
  },
  {
    code: '3J',
    description: 'AIR ALLIANCE, INC',
  },
  {
    code: 'A6',
    description: 'AIR ALPS AVIATION',
  },
  {
    code: 'D7',
    description: 'AIR ASIA',
  },
  {
    code: '4L',
    description: 'AIR ASTANA',
  },
  {
    code: 'CC',
    description: 'AIR ATLANTA ICELANDIC',
  },
  {
    code: 'LU',
    description: 'AIR ATLANTIC DOMINICANA',
  },
  {
    code: 'UU',
    description: 'AIR AUSTRAL',
  },
  {
    code: 'BT',
    description: 'AIR BALTIC CORPORATION S/A',
  },
  {
    code: '8B',
    description: 'AIR BENIN',
  },
  {
    code: 'AB',
    description: 'AIR BERLIN BMBTT & CO LUFTVERKEHR',
  },
  {
    code: 'JA',
    description: 'AIR BOSNIA',
  },
  {
    code: 'BP',
    description: 'AIR BOTSWANA CORPORATION',
  },
  {
    code: '2J',
    description: 'AIR BURKINA',
  },
  {
    code: 'BX',
    description: 'AIR BUSAN',
  },
  {
    code: 'TY',
    description: 'AIR CALEDONIE',
  },
  {
    code: 'SB',
    description: 'AIR CALEDONIE INTERNATIONAL',
  },
  {
    code: 'AC',
    description: 'AIR CANADA',
  },
  {
    code: 'XC',
    description: 'AIR CARRIBBEAN',
  },
  {
    code: 'UT',
    description: 'AIR CHARTER AISA',
  },
  {
    code: 'CV',
    description: 'AIR CHATHAMS',
  },
  {
    code: 'CA',
    description: 'AIR CHINA INTERNATIONAL CORPORATION',
  },
  {
    code: 'YN',
    description: 'AIR CREEBEC',
  },
  {
    code: 'EN',
    description: 'AIR DOLOMITI S.P.A. AEEREE REGION',
  },
  {
    code: 'RQ',
    description: 'AIR ENGIADINA',
  },
  {
    code: 'Z8',
    description: 'AIR ENTERPRISE PULKOVO',
  },
  {
    code: 'UX',
    description: 'AIR EUROPA LINEAS AEREAS, S.A.',
  },
  {
    code: 'PE',
    description: 'AIR EUROPE S.P.A.',
  },
  {
    code: 'XT',
    description: "AIR EXEL NETH'LANDS BV DBA KLM EXEL",
  },
  {
    code: 'XV',
    description: 'AIR EXPRESS',
  },
  {
    code: 'PC',
    description: 'AIR FIJI LTD',
  },
  {
    code: 'OF',
    description: 'AIR FINLAND',
  },
  {
    code: 'GS',
    description: 'AIR FOYLE LTD/GRANT AVIATION, INC',
  },
  {
    code: 'AF',
    description: 'AIR FRANCE',
  },
  {
    code: 'GN',
    description: 'AIR GABON',
  },
  {
    code: 'DA',
    description: 'AIR GEORGIA',
  },
  {
    code: 'ZX',
    description: 'AIR GEORGIAN',
  },
  {
    code: 'LD',
    description: 'AIR HONG KONG',
  },
  {
    code: 'NY',
    description: 'AIR ICELAND',
  },
  {
    code: '3H',
    description: 'AIR INUIT',
  },
  {
    code: 'I9',
    description: 'AIR ITALY',
  },
  {
    code: 'VU',
    description: 'AIR IVOIRE',
  },
  {
    code: 'JM',
    description: 'AIR JAMAICA',
  },
  {
    code: 'V6',
    description: 'AIR JET',
  },
  {
    code: '9Y',
    description: 'AIR KAZAKSTAN',
  },
  {
    code: 'JS',
    description: 'AIR KORYO',
  },
  {
    code: 'UE',
    description: 'AIR LA',
  },
  {
    code: 'QL',
    description: 'AIR LESOTHO (PTY) LTD.',
  },
  {
    code: 'IJ',
    description: 'AIR LIBERTE',
  },
  {
    code: 'VD',
    description: 'AIR LIBERTE',
  },
  {
    code: 'TT',
    description: 'AIR LITHUANIA',
  },
  {
    code: 'FU',
    description: 'AIR LITTORAL',
  },
  {
    code: 'NX',
    description: 'AIR MACAU COMPANY LIMITED',
  },
  {
    code: 'MD',
    description: 'AIR MADAGASCAR',
  },
  {
    code: 'QM',
    description: 'AIR MALAWI LIMITED',
  },
  {
    code: 'L6',
    description: 'AIR MALDIVES LIMITED',
  },
  {
    code: 'KM',
    description: 'AIR MALTA',
  },
  {
    code: 'CW',
    description: 'AIR MARSHALL ISLANDS, INC.',
  },
  {
    code: 'MR',
    description: 'AIR MAURITANIE',
  },
  {
    code: 'MK',
    description: 'AIR MAURITIUS',
  },
  {
    code: 'DR',
    description: 'AIR MEDITERRANEE',
  },
  {
    code: 'MC',
    description: 'AIR MOBILITY COMMAND',
  },
  {
    code: '3R',
    description: 'AIR MOLDOVA INTERNATIONAL S.A.',
  },
  {
    code: '9U',
    description: 'AIR MOLDOVA INTL',
  },
  {
    code: 'SW',
    description: 'AIR NAMIBIA',
  },
  {
    code: 'ON',
    description: 'AIR NAURU',
  },
  {
    code: 'N1',
    description: 'AIR NEW GUINEA',
  },
  {
    code: 'NZ',
    description: 'AIR NEW ZEALAND LIMITED',
  },
  {
    code: 'EL',
    description: 'AIR NIPPON CO. LTD.',
  },
  {
    code: 'PX',
    description: 'AIR NIUGINI PTY LIMITED DBA',
  },
  {
    code: '4N',
    description: 'AIR NORTH',
  },
  {
    code: 'M3',
    description: 'AIR NORWAY',
  },
  {
    code: 'YW',
    description: 'AIR NOSTRUM L.A.M.S.A.',
  },
  {
    code: 'QK',
    description: 'AIR NOVA',
  },
  {
    code: 'AP',
    description: 'AIR ONE',
  },
  {
    code: '8K',
    description: 'AIR OSTRAVA',
  },
  {
    code: 'FJ',
    description: 'AIR PACIFIC LTD.',
  },
  {
    code: '2P',
    description: 'AIR PHILIPPINES CORPORATION',
  },
  {
    code: 'A7',
    description: 'AIR PLUS COMET',
  },
  {
    code: 'RY',
    description: 'AIR RWANDA',
  },
  {
    code: '5W',
    description: 'AIR SAN JUAN/CHARTAIR',
  },
  {
    code: '7W',
    description: 'AIR SASK AVIATION 1991',
  },
  {
    code: 'V7',
    description: 'AIR SENEGAL INTERNATIONAL',
  },
  {
    code: 'HM',
    description: 'AIR SEYCHELLES LIMITED',
  },
  {
    code: 'YI',
    description: 'AIR SHUNSHINE INC.',
  },
  {
    code: 'BM',
    description: 'AIR SICILIA',
  },
  {
    code: '4D',
    description: 'AIR SINAI',
  },
  {
    code: 'GM',
    description: 'AIR SLOVAKIA BWJ, LTD.',
  },
  {
    code: 'CT',
    description: 'AIR SOFIA',
  },
  {
    code: 'PJ',
    description: 'AIR ST. PIERRE',
  },
  {
    code: 'ZP',
    description: 'AIR ST.THOMAS',
  },
  {
    code: 'VT',
    description: 'AIR TAHITI',
  },
  {
    code: 'TN',
    description: 'AIR TAHITI NUI',
  },
  {
    code: 'TC',
    description: 'AIR TANZANIA CORPORATION',
  },
  {
    code: '8T',
    description: 'AIR TINDI LTD',
  },
  {
    code: 'YT',
    description: 'AIR TOGO',
  },
  {
    code: 'TS',
    description: 'AIR TRANSAT',
  },
  {
    code: 'ZH',
    description: 'AIR TRUCK LINEAS AEREAS, SA',
  },
  {
    code: 'UK',
    description: 'AIR UK LIMITED DBA KLM UK',
  },
  {
    code: '6U',
    description: 'AIR UKRAINE',
  },
  {
    code: '3N',
    description: 'AIR URGA',
  },
  {
    code: 'DO',
    description: 'AIR VALLEE S.P.A./AIR VIRGINIA, INC',
  },
  {
    code: 'NF',
    description: 'AIR VANUATU (OPERATIONS) LIMITED',
  },
  {
    code: 'VL',
    description: 'AIR VIA BULGARIAN AIRWAYS',
  },
  {
    code: '6G',
    description: 'AIR WALES',
  },
  {
    code: 'ZW',
    description: 'AIR WISCONSIN',
  },
  {
    code: 'RL',
    description: 'AIR WORLD AVIATION',
  },
  {
    code: 'QC',
    description: 'AIR ZAIRE',
  },
  {
    code: 'UM',
    description: 'AIR ZIMBABWE CORPORATION',
  },
  {
    code: 'AI',
    description: 'AIR-INDIA LIMITED',
  },
  {
    code: 'AK',
    description: 'AIRASIA SDN BHD',
  },
  {
    code: 'A9',
    description: 'AIRCOMPANY AIRZENA GEORGIAN AIRLINE',
  },
  {
    code: 'QP',
    description: 'AIRKENYA AVIATION LTD',
  },
  {
    code: 'A5',
    description: 'AIRLINAIR',
  },
  {
    code: 'ND',
    description: 'AIRLINE PTY LTD',
  },
  {
    code: 'RT',
    description: 'AIRLINES OF SOUTH AUSTRALIA',
  },
  {
    code: 'FL',
    description: 'AIRTRAN AIRWAYS, INC',
  },
  {
    code: '6L',
    description: 'AKLAK',
  },
  {
    code: 'AS',
    description: 'ALASKA AIRLINES INC.',
  },
  {
    code: 'KO',
    description: 'ALASKA CENTRAL EXPRESS',
  },
  {
    code: 'A2',
    description: 'ALITALIA',
  },
  {
    code: 'XM',
    description: 'ALITALIA EXPRESS',
  },
  {
    code: 'NH',
    description: 'ALL NIPPON AIRWAYS CO. LTD.',
  },
  {
    code: 'G4',
    description: 'ALLEGIANT AIR',
  },
  {
    code: '1O',
    description: 'ALLEGRO',
  },
  {
    code: 'CD',
    description: 'ALLIANCE AIR',
  },
  {
    code: 'LM',
    description: 'ALM 1997 AIRLINE INC.',
  },
  {
    code: 'AQ',
    description: 'ALOHA AIRLINES INC.',
  },
  {
    code: 'E8',
    description: 'ALPI EAGLES S.P.A.',
  },
  {
    code: '5A',
    description: 'ALPINE AVIATION, INC.',
  },
  {
    code: 'HP',
    description: 'AMERICA WEST AIRLINES INC.',
  },
  {
    code: 'AA',
    description: 'AMERICAN AIRLINES',
  },
  {
    code: 'AX',
    description: 'AMERICAN CONNECTION',
  },
  {
    code: 'MQ',
    description: 'AMERICAN EAGLE',
  },
  {
    code: 'MV',
    description: 'AMRENIAN INTERNATIONAL AIRWAYS',
  },
  {
    code: '2V',
    description: 'AMTRACK',
  },
  {
    code: '8G',
    description: 'ANGEL AIRLINES',
  },
  {
    code: 'AN',
    description: 'ANSETT AUSTRALIA',
  },
  {
    code: 'ZQ',
    description: 'ANSETT NEW ZEALAND',
  },
  {
    code: 'HO',
    description: 'ANTINEA AIRLINES',
  },
  {
    code: 'IW',
    description: 'AOM-MINERVE S.A.',
  },
  {
    code: 'N2',
    description: 'AREOLINEAS INTERNACIONALES',
  },
  {
    code: 'FG',
    description: 'ARIANA AFGHAN AIRLINES',
  },
  {
    code: 'OR',
    description: 'ARKEFLY',
  },
  {
    code: 'IZ',
    description: 'ARKIA - ISRAELI AIRLINES LTD',
  },
  {
    code: 'R3',
    description: 'ARMENIAN AIRLINES',
  },
  {
    code: 'OZ',
    description: 'ASIANA AIRLINES INC',
  },
  {
    code: 'C5',
    description: 'ASOCIADOS LATINOAMERICANOS DE TR',
  },
  {
    code: 'TZ',
    description: 'ATA AIRLINES',
  },
  {
    code: '9T',
    description: 'ATHABASKA AIRWAYS LTD',
  },
  {
    code: 'RC',
    description: 'ATLANTIC AIRWAYS FAROE ISLANDS',
  },
  {
    code: 'EV',
    description: 'ATLANTIC SOUTHEAST AIRLINES',
  },
  {
    code: '8A',
    description: 'ATLAS BLUE',
  },
  {
    code: 'IQ',
    description: 'AUGSBURG-AIRWAYS GMBH',
  },
  {
    code: 'GR',
    description: 'AURIGNY',
  },
  {
    code: 'NO',
    description: 'AUS-AIR PTY LTD',
  },
  {
    code: 'AU',
    description: 'AUSTRAL',
  },
  {
    code: 'OS',
    description: 'AUSTRIAN AIRLINES',
  },
  {
    code: 'XP',
    description: 'AVELO AIRLINES',
  },
  {
    code: 'RX',
    description: 'AVIA EXPRESS',
  },
  {
    code: 'OC',
    description: 'AVIACION DEL NOROESTE S.A. DE C.V.',
  },
  {
    code: 'AO',
    description: 'AVIACION Y COMERCIO S.A. (AVIACO)',
  },
  {
    code: 'T5',
    description: 'AVIACOMPANY TURKMENISTAN',
  },
  {
    code: '6A',
    description: 'AVIACSA',
  },
  {
    code: 'AV',
    description: 'AVIANCA - AEROVIAS NACIONALES DE',
  },
  {
    code: 'GU',
    description: 'AVIATECA, S.A.',
  },
  {
    code: 'DF',
    description: 'AVIOSARDA S.R.L.',
  },
  {
    code: '2Q',
    description: 'AVITRANS',
  },
  {
    code: 'A1',
    description: 'AVRO',
  },
  {
    code: '6V',
    description: 'AXYS AIRWAYS',
  },
  {
    code: 'J2',
    description: 'AZERBAIJAN HAVA YOLLARY',
  },
  {
    code: 'AD',
    description: 'AZUL BRAZILIAN AIRLINES',
  },
  {
    code: 'ZS',
    description: 'AZZURRA AIR S.P.A.',
  },
  {
    code: 'TH',
    description: 'BA CITIEXPRESS',
  },
  {
    code: 'UP',
    description: 'BAHAMASAIR',
  },
  {
    code: 'LZ',
    description: 'BALKAN - BULGARIAN AIRLINES',
  },
  {
    code: 'PG',
    description: 'BANGKOK AIRWAY CO. LTD',
  },
  {
    code: 'JV',
    description: 'BEARSKIN AIRLINES',
  },
  {
    code: '4T',
    description: 'BELAIR AIRLINES AG',
  },
  {
    code: 'B2',
    description: 'BELAVIA',
  },
  {
    code: 'B3',
    description: 'BELLVIEW AIRLINES LTD.',
  },
  {
    code: 'CH',
    description: 'BEMIDJI AIRLINES',
  },
  {
    code: 'J8',
    description: 'BERJAYA AIR SDN. BHD.',
  },
  {
    code: 'GQ',
    description: 'BIG SKY AIRLINES',
  },
  {
    code: 'BG',
    description: 'BIMAN BANGLADESH AIRLINES',
  },
  {
    code: 'NT',
    description: 'BINTER CANARIAS',
  },
  {
    code: 'B1',
    description: 'BIRDY AIRLINES',
  },
  {
    code: 'KF',
    description: 'BLUE 1',
  },
  {
    code: 'JO',
    description: 'BLUE AIR',
  },
  {
    code: 'BC',
    description: 'BLUE ISLANDS',
  },
  {
    code: '6B',
    description: 'BLUE SCANDINAVA AB/BAXTER AVIATION',
  },
  {
    code: 'WW',
    description: 'BMI BABY',
  },
  {
    code: 'OB',
    description: 'BOLIVIANA DE AVIACION',
  },
  {
    code: '5Q',
    description: 'BQB LINEAS AEREAS',
  },
  {
    code: 'B7',
    description: 'BRA',
  },
  {
    code: 'BU',
    description: 'BRAATHENS SOUTH AMERICAN AND',
  },
  {
    code: 'MX',
    description: 'BREEZE AIRWAYS',
  },
  {
    code: 'DB',
    description: 'BRIT AIR',
  },
  {
    code: 'BH',
    description: 'BRITANNIA',
  },
  {
    code: 'BF',
    description: 'BRITANY FERRIES',
  },
  {
    code: 'BA',
    description: 'BRITISH AIRWAYS P.L.C.',
  },
  {
    code: 'RR',
    description: 'BRITISH ARMY',
  },
  {
    code: 'BD',
    description: 'BRITISH MIDLAND AIRWAYS LTD.',
  },
  {
    code: 'BY',
    description: 'BRITTANNIA AIRWAYS LTD.',
  },
  {
    code: 'SN',
    description: 'BRUSSELS AIRLINES',
  },
  {
    code: 'FB',
    description: 'BULGARIA AIR',
  },
  {
    code: 'U4',
    description: 'BURYAT AIRLINE',
  },
  {
    code: '5E',
    description: 'BUSINESS AVIATION SVCS EINDHOVEN',
  },
  {
    code: 'BW',
    description: 'BWIA INTERNATIONAL AIRWAYS LIMITED',
  },
  {
    code: 'CK',
    description: 'CALEDONIAN AIRLINES',
  },
  {
    code: 'I5',
    description: 'CAMALI COMPAGNIE MALI',
  },
  {
    code: 'UY',
    description: 'CAMEROON AIRLINES',
  },
  {
    code: 'FH',
    description: 'CAMPANIA HISPANO IRLANDESA DE AVIA',
  },
  {
    code: '2T',
    description: 'CANADA 3000 AIRLINE LIMITED',
  },
  {
    code: 'CP',
    description: 'CANADIAN AIRLINES INTL LIMITED',
  },
  {
    code: '5T',
    description: 'CANADIAN NORTH AIRLINE',
  },
  {
    code: 'C6',
    description: 'CANJET AIRLINES',
  },
  {
    code: '9K',
    description: 'CAPE AIR',
  },
  {
    code: 'PT',
    description: 'CAPITOL CARGO INTL. AIRLINES, INC',
  },
  {
    code: 'NN',
    description: 'CARDINAL AIRLINES LIMITED',
  },
  {
    code: 'V3',
    description: 'CARPATAIR',
  },
  {
    code: 'CX',
    description: 'CATHAY PACIFIC AIRWAYS LTD.',
  },
  {
    code: 'KX',
    description: 'CAYMAN AIRWAYS LTD',
  },
  {
    code: 'ED',
    description: 'CCAIR, INC',
  },
  {
    code: '5J',
    description: 'CEBU PACIFIC AIR',
  },
  {
    code: '9M',
    description: 'CENTRAL MOUNTAIN AIR LTD',
  },
  {
    code: 'C0',
    description: 'CENTRALWINGS',
  },
  {
    code: 'C1',
    description: 'CFLCO CHARTER',
  },
  {
    code: 'M6',
    description: 'CHALAIR',
  },
  {
    code: 'MG',
    description: 'CHAMPION AIR',
  },
  {
    code: 'RP',
    description: 'CHAUTAUQUA',
  },
  {
    code: 'C8',
    description: 'CHICAGO EXPRESS AIRLINES, INC',
  },
  {
    code: 'CI',
    description: 'CHINA AIRLINES',
  },
  {
    code: 'MU',
    description: 'CHINA EASTERN AIRLINES',
  },
  {
    code: 'CJ',
    description: 'CHINA NORTHERN AIRLINES',
  },
  {
    code: 'WH',
    description: 'CHINA NORTHWEST AIRLINES',
  },
  {
    code: 'CZ',
    description: 'CHINA SOUTHERN AIRLINES',
  },
  {
    code: 'SZ',
    description: 'CHINA SOUTHWEST AIRLINES',
  },
  {
    code: '3O',
    description: 'CHINA YUNNAN AIRLINES',
  },
  {
    code: '3Q',
    description: 'CHINA YUNNAN AIRLINES',
  },
  {
    code: 'QI',
    description: 'CIMBER AIR',
  },
  {
    code: 'FO',
    description: 'CIRCLE AIR FREIGHT CORP',
  },
  {
    code: 'C9',
    description: 'CIRRUS AIR',
  },
  {
    code: 'CF',
    description: 'CITY AIRLINE',
  },
  {
    code: 'H2',
    description: 'CITY BIRD',
  },
  {
    code: 'WX',
    description: 'CITYJET',
  },
  {
    code: 'XG',
    description: 'CLICKAIR',
  },
  {
    code: '9L',
    description: 'COLGAN AIR',
  },
  {
    code: 'OH',
    description: 'COMAIR, INC',
  },
  {
    code: 'MN',
    description: 'COMMERCIAL AIRWAYS (PTY) LTD.',
  },
  {
    code: 'XK',
    description: 'COMPAGNIE AERIENNE CORSE',
  },
  {
    code: '5L',
    description: 'COMPANIA BOLIVIANA DE TRANS AEREO',
  },
  {
    code: 'DE',
    description: 'CONDOR FLUGDIENST GMBTT',
  },
  {
    code: 'EO',
    description: 'CONGO AIRLINES/EXPRESS ONE INTL INC',
  },
  {
    code: 'CO',
    description: 'CONTINENTAL AIRLINES, INC.',
  },
  {
    code: '04',
    description: 'CONTINENTAL CONNECTION',
  },
  {
    code: 'CS',
    description: 'CONTINENTAL MICRONESIA, INC.',
  },
  {
    code: 'CM',
    description: 'COPA - COMPANIA PANAMENA DE',
  },
  {
    code: '3C',
    description: 'CORPORATE AIRLINES',
  },
  {
    code: 'C2',
    description: 'CORPORATE EXPRESS',
  },
  {
    code: 'SS',
    description: 'CORSE AIR INTERNATIONAL',
  },
  {
    code: 'ZE',
    description: 'COSMOS AIR LUFTFAHRTUNTERNEHMEN GMB',
  },
  {
    code: 'OU',
    description: 'CROATIA AIRLINES',
  },
  {
    code: 'X5',
    description: 'CRONUS AIRLINES',
  },
  {
    code: 'QE',
    description: 'CROSSAIR',
  },
  {
    code: 'CU',
    description: 'CUBANA DE AVIACION S.A.',
  },
  {
    code: 'CY',
    description: 'CYPRUS AIRWAYS',
  },
  {
    code: 'OK',
    description: 'CZECH AIRLINES A.S. , CSA',
  },
  {
    code: 'D3',
    description: 'DAALLO AIRLINES',
  },
  {
    code: '6P',
    description: 'DAC AIR S.A.',
  },
  {
    code: 'D4',
    description: 'DAMAL AIRLINES',
  },
  {
    code: 'DX',
    description: 'DANISH AIR TRANSPORT',
  },
  {
    code: 'V5',
    description: 'DANUBE WINGS',
  },
  {
    code: '2G',
    description: 'DEBONAIR AIRWAYS LTD.',
  },
  {
    code: 'DL',
    description: 'DELTA AIR LINES INC.',
  },
  {
    code: '03',
    description: 'DELTA CONNECTION',
  },
  {
    code: '05',
    description: 'DELTA SHUTTLE',
  },
  {
    code: 'KS',
    description: 'DENAIR',
  },
  {
    code: '3D',
    description: 'DENIM AIR',
  },
  {
    code: 'DI',
    description: 'DEUTSCHE BA LUFTFAHRTGESELLSCHAFT',
  },
  {
    code: 'ES',
    description: 'DHL INTERNATIONAL E.C.',
  },
  {
    code: 'D1',
    description: 'DIRECT AIR (MYRTLE BEACH)',
  },
  {
    code: '4Y',
    description: 'DISCOVER AIRLINES',
  },
  {
    code: 'Z6',
    description: 'DNIEPROAVIA',
  },
  {
    code: 'D9',
    description: 'DONAVIA',
  },
  {
    code: 'NE',
    description: 'DOWNEAST FLYING SERVICE, INC',
  },
  {
    code: 'KB',
    description: 'DRUK AIR',
  },
  {
    code: 'ZN',
    description: 'EAGLE AIRLINES',
  },
  {
    code: 'Y4',
    description: 'EAGLE AVIATION LTD.',
  },
  {
    code: 'FE',
    description: 'EAGLE CANYON AIRLINES INC',
  },
  {
    code: '4S',
    description: 'EAST WEST TRAVEL TRADE LINKS LTD.',
  },
  {
    code: 'T3',
    description: 'EASTERN AIRWAYS',
  },
  {
    code: 'DK',
    description: 'EASTLAND AIR',
  },
  {
    code: 'W9',
    description: 'EASTWIND AIRLINES, INC.',
  },
  {
    code: 'U2',
    description: 'EASYJET',
  },
  {
    code: 'DS',
    description: 'EASYJET SWITZERLAND SA',
  },
  {
    code: 'EU',
    description: 'ECUATORIANA DE AVIACION S.A.',
  },
  {
    code: 'WK',
    description: 'EDELWEISS AIR',
  },
  {
    code: 'MS',
    description: 'EGYPTAIR',
  },
  {
    code: 'LY',
    description: 'EL AL ISRAEL AIRLINES LTD.',
  },
  {
    code: 'EK',
    description: 'EMIRATES',
  },
  {
    code: 'EM',
    description: 'EMPIRE AIRLINES',
  },
  {
    code: 'E0',
    description: 'EOS AIRLINES',
  },
  {
    code: 'OV',
    description: 'ESTONIAN AIR',
  },
  {
    code: 'S8',
    description: 'ESTONIAN AIR',
  },
  {
    code: 'ET',
    description: 'ETHIOPIAN AIRLINES ENTERPRISE',
  },
  {
    code: 'EY',
    description: 'ETIHAD AIRLINE',
  },
  {
    code: 'RN',
    description: 'EURALAIR',
  },
  {
    code: 'UI',
    description: 'EUROCYPRIA AIRLINES',
  },
  {
    code: 'GJ',
    description: 'EUROFLY',
  },
  {
    code: '5O',
    description: 'EUROPE AIRPOST',
  },
  {
    code: 'E7',
    description: 'EUROPEAN AIR',
  },
  {
    code: 'EA',
    description: 'EUROPEAN AIR EXPRESS',
  },
  {
    code: 'QY',
    description: 'EUROPEAN AIR TRANSPORT',
  },
  {
    code: '2H',
    description: 'EUROPEAN AIRLINES',
  },
  {
    code: '9F',
    description: 'EUROSTAR (U.K.) LTD',
  },
  {
    code: 'EW',
    description: 'EUROWINGS',
  },
  {
    code: 'BR',
    description: 'EVA AIRWAYS CORPORATION',
  },
  {
    code: 'XE',
    description: 'EXPRESS JET',
  },
  {
    code: 'EX',
    description: 'EXPRESS ONE - SW',
  },
  {
    code: '4X',
    description: "F'AIRLINES B.V.",
  },
  {
    code: 'LK',
    description: 'FAIRLINES',
  },
  {
    code: '6F',
    description: 'FALCON AIR',
  },
  {
    code: 'F2',
    description: 'FALCON AIR EXPRESS, INC.',
  },
  {
    code: 'IH',
    description: 'FALCON AVIATION AB',
  },
  {
    code: 'FC',
    description: 'FALCON EXPRESS CARGO AIRLINES/TAL',
  },
  {
    code: 'FX',
    description: 'FEDEX',
  },
  {
    code: 'AY',
    description: 'FINNAIR OY',
  },
  {
    code: '7F',
    description: 'FIRST AIR',
  },
  {
    code: 'DP',
    description: 'FIRST CHOICE AIRWAYS',
  },
  {
    code: '8F',
    description: 'FISCHER AIR',
  },
  {
    code: '8N',
    description: 'FLAGSHIP AIRLINES, INC DBA AMERICAN',
  },
  {
    code: 'FK',
    description: 'FLAMENCO AIRWAYS, INC.',
  },
  {
    code: 'IX',
    description: 'FLANDRE AIR',
  },
  {
    code: 'OT',
    description: 'FLIGHT SAFETY',
  },
  {
    code: 'YC',
    description: 'FLIGHT WEST AIRLINES PTY LTD.',
  },
  {
    code: 'PA',
    description: 'FLORIDA COASTAL',
  },
  {
    code: 'BE',
    description: 'FLY BE',
  },
  {
    code: 'SH',
    description: 'FLY ME SWEEDEN AB',
  },
  {
    code: 'HG',
    description: 'FLY NIKI',
  },
  {
    code: 'F7',
    description: 'FLYBABOO',
  },
  {
    code: 'OP',
    description: 'FLYING BOAT, INC DBA PAN AM AIR',
  },
  {
    code: 'F3',
    description: 'FLYING ENTERPRISE AB',
  },
  {
    code: 'HK',
    description: 'FOUR STAR AVIATION',
  },
  {
    code: 'F9',
    description: 'FRONTIER AIRLINES, INC',
  },
  {
    code: '2F',
    description: 'FRONTIER FLYING SERVICE',
  },
  {
    code: 'G7',
    description: 'GANDAS AIRLINES',
  },
  {
    code: 'GA',
    description: 'GARUDA INDONESIA',
  },
  {
    code: 'GT',
    description: 'GB AIRWAYS LTD.',
  },
  {
    code: '3P',
    description: 'GEORGIAN ARLNS',
  },
  {
    code: 'ST',
    description: 'GERMANIA FLUGGESELLSCHAFT/YANDA',
  },
  {
    code: '4U',
    description: 'GERMANWINGS',
  },
  {
    code: 'GP',
    description: 'GESTAIR S.A.',
  },
  {
    code: 'GH',
    description: 'GHANA AIRWAYS CORP.',
  },
  {
    code: '9C',
    description: 'GILL AVIATION LTD.',
  },
  {
    code: 'B4',
    description: 'GLOBESPAN',
  },
  {
    code: 'GB',
    description: 'GO AIRLINE',
  },
  {
    code: '06',
    description: 'GO!',
  },
  {
    code: 'G3',
    description: 'GOL LINHAS AEREAS',
  },
  {
    code: 'DC',
    description: 'GOLDEN AIR FLYG AB',
  },
  {
    code: 'GW',
    description: 'GOLDEN WEST AIRLINES, INC',
  },
  {
    code: 'DV',
    description: 'GORDA AERO SERVICES, INC/ROTTNEST',
  },
  {
    code: '8L',
    description: 'GRAND INTERNATIONAL AIRWAYS, INC.',
  },
  {
    code: 'ZK',
    description: 'GREAT LAKES',
  },
  {
    code: 'G9',
    description: 'GREAT PLAINS',
  },
  {
    code: 'GL',
    description: 'GREENLANDAIR',
  },
  {
    code: 'G8',
    description: 'GUJARAT AIRWAYS LIMITED',
  },
  {
    code: 'GF',
    description: 'GULF AIR COMPANY G.S.C.',
  },
  {
    code: 'GY',
    description: 'GUYANA AIRWAYS',
  },
  {
    code: 'HR',
    description: 'HAHN AIR BUSINESSLINE',
  },
  {
    code: 'HU',
    description: 'HAINAN AIRLINES',
  },
  {
    code: '2N',
    description: "HAITI AVIATION S.A. DBA AIR D'AYITI",
  },
  {
    code: 'WD',
    description: 'HAITIAN AVIATION',
  },
  {
    code: '4R',
    description: 'HAMBURG INTERNATIONAL',
  },
  {
    code: 'HI',
    description: 'HAMBURG INTERNATIONAL',
  },
  {
    code: 'X3',
    description: 'HAPAG LLOYD EXPRESS',
  },
  {
    code: 'HF',
    description: 'HAPAG LLOYD FLUGGESELLSCHAFT',
  },
  {
    code: 'H3',
    description: 'HARBOUR AIR LTD',
  },
  {
    code: 'HQ',
    description: 'HARMONY AIRWAYS',
  },
  {
    code: 'HA',
    description: 'HAWAIIAN AIRLINES',
  },
  {
    code: 'ZL',
    description: 'HAZELTON AIRLINES',
  },
  {
    code: 'LE',
    description: 'HELGOLAND ARLNS',
  },
  {
    code: 'EC',
    description: 'HELI INTER RIVIERA',
  },
  {
    code: 'YO',
    description: 'HELI-AIR-MONACO',
  },
  {
    code: 'JB',
    description: 'HELIJET AIRWAYS',
  },
  {
    code: 'YQ',
    description: 'HELIKOPTERSERVICE, EUROAIR',
  },
  {
    code: 'T4',
    description: 'HELLAS JET',
  },
  {
    code: '2L',
    description: 'HELVETIC',
  },
  {
    code: 'DU',
    description: 'HEMUS AIR',
  },
  {
    code: 'UD',
    description: "HEX' AIR",
  },
  {
    code: 'HS',
    description: 'HIGHLAND AIR AB',
  },
  {
    code: 'HD',
    description: 'HOKKAIDO INTERNATIONAL AIRLINES',
  },
  {
    code: 'KA',
    description: 'HONG KONG DRAGON AIRLINES LIMITED',
  },
  {
    code: 'H1',
    description: 'HOOTERS AIR',
  },
  {
    code: 'QX',
    description: 'HORIZON AIR',
  },
  {
    code: 'KH',
    description: 'HORIZON AIRLINES',
  },
  {
    code: 'AG',
    description: 'HUNTING CARGO AIRLINES',
  },
  {
    code: 'IB',
    description: 'IBERIA -LINEAS AEREAS DE ESPANA',
  },
  {
    code: 'E9',
    description: 'IBEROJET  AIRLINES',
  },
  {
    code: 'HW',
    description: 'ICELAND EXPRESS',
  },
  {
    code: 'FI',
    description: 'ICELANDAIR',
  },
  {
    code: 'IK',
    description: 'IMAIR',
  },
  {
    code: 'DH',
    description: 'INDEPENDENCE AIR.',
  },
  {
    code: 'IC',
    description: 'INDIAN AIRLINES',
  },
  {
    code: 'IM',
    description: 'INTENSIVE AIR (PTY) LTD',
  },
  {
    code: 'D6',
    description: 'INTER-AVIATION SVCS DBA INTER-AIR',
  },
  {
    code: 'M4',
    description: 'INTERIMPEX - AVIOIMPEX',
  },
  {
    code: '40',
    description: 'INTERJET',
  },
  {
    code: 'ID',
    description: 'INTERLINK AIRLINES',
  },
  {
    code: '9B',
    description: 'INTOURTRANS',
  },
  {
    code: 'IR',
    description: 'IRAN AIR',
  },
  {
    code: 'B9',
    description: 'IRAN AIR TOURS',
  },
  {
    code: 'EP',
    description: 'IRAN ASSEMAN AIRLINES',
  },
  {
    code: 'IA',
    description: 'IRAQI AIRWAYS',
  },
  {
    code: 'IF',
    description: 'IRISH FERRIES',
  },
  {
    code: 'WP',
    description: 'ISLAND AIR',
  },
  {
    code: 'IS',
    description: 'ISLAND AIRLINES, INC',
  },
  {
    code: '2S',
    description: 'ISLAND EXPRESS',
  },
  {
    code: 'HH',
    description: 'ISLANDFLUG',
  },
  {
    code: 'CN',
    description: 'ISLANDS NATIONAIR',
  },
  {
    code: 'WC',
    description: 'ISLENA AIRLINES',
  },
  {
    code: '6H',
    description: 'ISRAIR AIRLINES & TOURISM LTD',
  },
  {
    code: 'IL',
    description: 'ISTANBUL AIRLINES A.S.',
  },
  {
    code: 'AZ',
    description: 'ITA AIRWAYS',
  },
  {
    code: 'B8',
    description: 'ITALAIR',
  },
  {
    code: 'IT',
    description: 'ITT SHERATON CLUB INTERNATIONAL',
  },
  {
    code: 'JC',
    description: 'JAL JAPAN AIRLINES',
  },
  {
    code: 'LN',
    description: 'JAMAHIRIYA LIBYAN ARAB AIRLINES',
  },
  {
    code: 'NS',
    description: 'JANLIN PTY LTD DBA CAPE NY AIR',
  },
  {
    code: '3X',
    description: 'JAPAN AIR COMMUTER CO. LTD.',
  },
  {
    code: 'JD',
    description: 'JAPAN AIR SYSTEM CO., LTD.',
  },
  {
    code: 'JL',
    description: 'JAPAN AIRLINES COMPANY LTD.',
  },
  {
    code: 'EG',
    description: 'JAPAN ASIA AIRWAYS CO LTD',
  },
  {
    code: 'NU',
    description: 'JAPAN TRANSOCEAN AIR CO. LTD.',
  },
  {
    code: 'JT',
    description: 'JARO INTERNATIONAL SA',
  },
  {
    code: 'J9',
    description: 'JAZEERA AIRWAYS',
  },
  {
    code: 'JY',
    description: 'JERSEY EUROPEAN AIRWAYS LTD.',
  },
  {
    code: '9W',
    description: 'JET AIRWAYS (INDIA) LIMITED',
  },
  {
    code: 'QJ',
    description: 'JET AIRWAYS, INC.',
  },
  {
    code: 'PP',
    description: 'JET AVIATION BUSINESS JETS AG',
  },
  {
    code: 'JG',
    description: 'JET GO',
  },
  {
    code: 'LF',
    description: 'JET TRAIN CORPORATION',
  },
  {
    code: 'LS',
    description: 'JET2',
  },
  {
    code: '8J',
    description: 'JET4YOU',
  },
  {
    code: 'B6',
    description: 'JETBLUE AIRWAYS',
  },
  {
    code: 'JQ',
    description: 'JETSTAR',
  },
  {
    code: 'LJ',
    description: 'JIN AIR',
  },
  {
    code: '2U',
    description: 'JOINT STOCK COMPANY AIR CO. KARAT',
  },
  {
    code: 'J5',
    description: 'JOINT-STOCK COMPANY SOCHI AIRLINE',
  },
  {
    code: 'JU',
    description: 'JUGOSLOVENSKI AEROTRANSPORT (JAT)',
  },
  {
    code: 'KT',
    description: 'KAMPUCHEA AIRLINES',
  },
  {
    code: '5R',
    description: 'KARTHAGO AIRLINES',
  },
  {
    code: 'K4',
    description: 'KAZAKHSTAN AIRLINES',
  },
  {
    code: 'KD',
    description: 'KENDELL AIRLINES',
  },
  {
    code: 'KQ',
    description: 'KENYA AIRWAYS',
  },
  {
    code: 'BZ',
    description: 'KEYSTONE AIR SERVICE LTD.',
  },
  {
    code: 'K6',
    description: 'KHALIFA AIRWAYS',
  },
  {
    code: 'Y9',
    description: 'KISH AIR',
  },
  {
    code: 'KP',
    description: 'KIWI INTERNATIONAL AIR LINES',
  },
  {
    code: 'WA',
    description: 'KLM CITYHOPPER',
  },
  {
    code: 'KL',
    description: 'KLM ROYAL DUTCH AIRLINES',
  },
  {
    code: 'KE',
    description: 'KOREAN AIR LINES CO. LTD.',
  },
  {
    code: '6K',
    description: 'KORSAR ARLNS',
  },
  {
    code: 'KU',
    description: 'KUWAIT AIRWAYS',
  },
  {
    code: 'JF',
    description: 'L.A.B. FLYING SERVICE INC',
  },
  {
    code: '7Z',
    description: 'L.B. LIMITED',
  },
  {
    code: 'WJ',
    description: 'LABRADOR AIRWAYS',
  },
  {
    code: 'UC',
    description: 'LADECO S.A. D/B/A LADECO AIRLINES',
  },
  {
    code: 'TM',
    description: 'LAM - LINHAS AEREAS DE MOCAMBIQUE',
  },
  {
    code: '4M',
    description: 'LAN ARGENTINA',
  },
  {
    code: 'JN',
    description: 'LAN ECUADOR',
  },
  {
    code: 'XL',
    description: 'LAN ECUADOR',
  },
  {
    code: 'BN',
    description: 'LANDAIR INTERNATIONAL AIRLINES',
  },
  {
    code: 'OI',
    description: 'LANDMARK AVIATION',
  },
  {
    code: 'ZZ',
    description: 'LANE AVIATION',
  },
  {
    code: 'LP',
    description: 'LANPERU',
  },
  {
    code: 'QV',
    description: 'LAO AVIATION',
  },
  {
    code: 'LA',
    description: 'LATAM AIRLINES',
  },
  {
    code: 'LW',
    description: 'LATIN AMERICAN WINGS',
  },
  {
    code: 'NG',
    description: 'LAUDA AIR LUFTFAHRT AG',
  },
  {
    code: 'A0',
    description: 'LAVION',
  },
  {
    code: 'LV',
    description: 'LEVEL AIRLINES',
  },
  {
    code: 'HE',
    description: 'LGW LUFTFAHRTGESELLSCHAFT',
  },
  {
    code: 'LI',
    description: 'LIAT (1974) LTD.',
  },
  {
    code: 'LR',
    description: 'LINEAS AEREAS COSTARRICENSES S.A.',
  },
  {
    code: 'MJ',
    description: 'LINEAS AEREAS PRIVADAS ARGENTINAS',
  },
  {
    code: 'TE',
    description: 'LITHUANIAN AIRLINES',
  },
  {
    code: 'LB',
    description: 'LLOYD AEREO BOLIVIANO S.A. (LAB)',
  },
  {
    code: 'LC',
    description: 'LOGANAIR',
  },
  {
    code: 'LO',
    description: 'LOT - POLISH AIRLINES',
  },
  {
    code: 'T2',
    description: 'LOTUS AIR',
  },
  {
    code: 'XU',
    description: 'LOTUS AIRLINE (TAS)',
  },
  {
    code: 'XO',
    description: 'LTE INTERNATIONAL',
  },
  {
    code: 'LT',
    description: 'LTU INTERNATIONAL AIRWAYS',
  },
  {
    code: 'LH',
    description: 'LUFTHANSA',
  },
  {
    code: 'CL',
    description: 'LUFTHANSA CITYLINE GMBH',
  },
  {
    code: 'LG',
    description: 'LUXAIR',
  },
  {
    code: 'XR',
    description: 'LUXOR AIR  (LXO)',
  },
  {
    code: 'L7',
    description: 'LVIV AIRLINES',
  },
  {
    code: 'L1',
    description: 'LYNX',
  },
  {
    code: 'IN',
    description: 'MACEDONIAN AIRLINES',
  },
  {
    code: 'DM',
    description: 'MAERSK AIR A/S',
  },
  {
    code: 'H5',
    description: 'MAGADAN AIRLINES',
  },
  {
    code: 'W5',
    description: 'MAHAN AIR',
  },
  {
    code: 'MH',
    description: 'MALAYSIAN AIRLINE SYSTEM BERHAD',
  },
  {
    code: 'MA',
    description: 'MALEV HUNGARIAN AIRLINES PUBLIC LTD',
  },
  {
    code: '6E',
    description: 'MALMO AVIATION',
  },
  {
    code: 'TF',
    description: 'MALMO AVIATION',
  },
  {
    code: 'AE',
    description: 'MANDARIN AIRLINES',
  },
  {
    code: 'JE',
    description: 'MANX AIRLINES',
  },
  {
    code: 'GO',
    description: 'MARITIME GLOBAL AIRLINES',
  },
  {
    code: 'KN',
    description: 'MAROOMBA AIRLINES',
  },
  {
    code: 'MP',
    description: 'MARTINAIR HOLLAND N.V.',
  },
  {
    code: '6M',
    description: 'MAVERICK AIRWAYS CORPORATION',
  },
  {
    code: 'MY',
    description: 'MAXJET AIRWAYS',
  },
  {
    code: 'MW',
    description: 'MAYA AIRWAYS',
  },
  {
    code: 'IG',
    description: 'MERIDIANA S.P.A.',
  },
  {
    code: 'YV',
    description: 'MESA AIRLINES',
  },
  {
    code: 'XJ',
    description: 'MESABA AIRLINES',
  },
  {
    code: 'OW',
    description: 'METAVIA AIRLINES (PTY) LTD',
  },
  {
    code: 'LL',
    description: 'MIAMI AIR INTERNATIONAL',
  },
  {
    code: 'OM',
    description: 'MIAT-MONGOLIAN',
  },
  {
    code: 'ME',
    description: 'MIDDLE EAST AIRLINES AIRLIBAN',
  },
  {
    code: 'JI',
    description: 'MIDWAY AIRLINES',
  },
  {
    code: 'YX',
    description: 'MIDWEST AIRLINES',
  },
  {
    code: 'ZV',
    description: 'MIDWEST EXPRESS',
  },
  {
    code: 'YF',
    description: 'MILITARY CHARTER',
  },
  {
    code: 'N3',
    description: 'MIRANIA ROMANIAN',
  },
  {
    code: 'MB',
    description: 'MNG AIRLIENS CARGO/EXECAIRE INC',
  },
  {
    code: '5Z',
    description: 'MOKULELE FLIGHT SERVICE',
  },
  {
    code: '2M',
    description: 'MOLDAVIAN ARLNS',
  },
  {
    code: 'ZB',
    description: 'MONARCH AIRLINES',
  },
  {
    code: 'YM',
    description: 'MONTENEGRO AIRLINES',
  },
  {
    code: 'ZR',
    description: 'MUK AIR',
  },
  {
    code: 'VZ',
    description: 'MY TRAVEL',
  },
  {
    code: '8I',
    description: 'MY WAY AIRLINES',
  },
  {
    code: 'UB',
    description: 'MYANMAR ARWYS INTL',
  },
  {
    code: 'HC',
    description: 'NASKE-AIR',
  },
  {
    code: 'K7',
    description: 'NATIONAL AIRCOMPANY SAKHA AVIA',
  },
  {
    code: 'YJ',
    description: 'NATIONAL AIRLINES CHILE',
  },
  {
    code: 'N4',
    description: 'NATIONAL AIRLINES CHILE S.A.',
  },
  {
    code: 'N7',
    description: 'NATIONAL AIRLINES, INC.',
  },
  {
    code: 'NC',
    description: 'NATIONAL JET SYSTEMS PTY/NORTHERN',
  },
  {
    code: 'CE',
    description: 'NATIONWIDE AIRLINES (PTY) LTD',
  },
  {
    code: '5C',
    description: 'NATURE AIR',
  },
  {
    code: 'NA',
    description: 'NETWORK AVIATION',
  },
  {
    code: '9X',
    description: 'NEW AXIS AIRWAYS',
  },
  {
    code: 'EJ',
    description: 'NEW ENGLAND AIRLINES',
  },
  {
    code: '6Y',
    description: 'NICA',
  },
  {
    code: 'WT',
    description: 'NIGERIA AIRWAYS LTD.',
  },
  {
    code: 'KZ',
    description: 'NIPPON CARGO AIRLINES',
  },
  {
    code: 'J3',
    description: 'NORTHWESTERN AIRWAYS',
  },
  {
    code: 'DY',
    description: 'NORWEGIAN',
  },
  {
    code: 'BJ',
    description: 'NOUVELAIR',
  },
  {
    code: 'NV',
    description: 'NTW AIR',
  },
  {
    code: 'UQ',
    description: "O'CONNOR-MT. GAMBIER'S AIRLINES",
  },
  {
    code: 'O6',
    description: 'OCEANAIR',
  },
  {
    code: 'OL',
    description: 'OLT-OSTFRIESISCHE LUFTRANSPORT',
  },
  {
    code: 'OA',
    description: 'OLYMPIC AIRWAYS',
  },
  {
    code: 'WY',
    description: 'OMAN AVIATION SERVICES CO. (SAOG)',
  },
  {
    code: 'X9',
    description: 'OMNI AIR INTERNATIONAL',
  },
  {
    code: 'J1',
    description: 'ONE JET',
  },
  {
    code: '8Q',
    description: 'ONUR AIR TASIMACILIK A/S',
  },
  {
    code: 'NQ',
    description: 'ORBI GEORGIAN AIRWAYS',
  },
  {
    code: 'RF',
    description: 'ORD AIR CHARTER PTY. LTD',
  },
  {
    code: 'OX',
    description: 'ORIENT THAI AIRLINES',
  },
  {
    code: 'XX',
    description: 'OTHER',
  },
  {
    code: 'SG',
    description: 'P.T. SEMPATI AIR',
  },
  {
    code: 'GX',
    description: 'PACIFIC AIRWAYS CORP (PACIFICAIR)',
  },
  {
    code: '8P',
    description: 'PACIFIC COASTAL AIRLINES LIMITED',
  },
  {
    code: '9J',
    description: 'PACIFIC ISLAND AVIATION',
  },
  {
    code: '2W',
    description: 'PACIFIC MIDLAND AIRLINES LTD.',
  },
  {
    code: 'PK',
    description: 'PAKISTAN INTERNATIONAL AIRLINES',
  },
  {
    code: 'PF',
    description: 'PALESTINIAN AIRLINES',
  },
  {
    code: 'PV',
    description: 'PAN AIR LINEAS AEREAS S.A.',
  },
  {
    code: 'KW',
    description: 'PAN AMERICAN WORLD AIRWAYS, INC.',
  },
  {
    code: 'PQ',
    description: 'PANAFRICAN AIRWAYS',
  },
  {
    code: 'P8',
    description: 'PANTANAL',
  },
  {
    code: 'BK',
    description: 'PARADISE ISLAND AIRLINES, INC DBA',
  },
  {
    code: 'P3',
    description: 'PASSAREDO TRANSPORTES AEREOS',
  },
  {
    code: 'P1',
    description: 'PEACE AIR',
  },
  {
    code: 'H9',
    description: 'PEGASUS',
  },
  {
    code: '1I',
    description: 'PEGASUS AIRLINES',
  },
  {
    code: '9P',
    description: 'PELANGI AIRWAYS SDN. BHD.',
  },
  {
    code: '7V',
    description: 'PELICAN AIR SERVICES',
  },
  {
    code: 'UW',
    description: 'PERIMETER AIRLINES',
  },
  {
    code: 'PR',
    description: 'PHILIPPINE AIRLINES, INC.',
  },
  {
    code: '9R',
    description: 'PHUKET AIR',
  },
  {
    code: 'PW',
    description: 'PINE STATE AIRLINES',
  },
  {
    code: '9E',
    description: 'PINNACLE',
  },
  {
    code: 'PO',
    description: 'POLAR AIR CARGO',
  },
  {
    code: 'PH',
    description: 'POLYNESIAN LIMITED',
  },
  {
    code: 'PD',
    description: 'PORTER AIRLINES',
  },
  {
    code: 'NI',
    description: 'PORTUGALIA-COMPANHIA PORTUGUESA DE',
  },
  {
    code: 'T9',
    description: 'PRIME AIR, INC(D.B.A TRANSMERIDIAN)',
  },
  {
    code: 'PU',
    description: 'PRIMERAS LINEAS URUGUAYAS DE',
  },
  {
    code: '00',
    description: 'PRIVATE PLANE',
  },
  {
    code: 'P9',
    description: 'PROAIR',
  },
  {
    code: 'HN',
    description: 'PROTHEUS HELICOPTERES',
  },
  {
    code: 'PB',
    description: 'PROVINCIAL AIRLINES',
  },
  {
    code: 'BO',
    description: 'PT BOURAQ',
  },
  {
    code: 'MZ',
    description: 'PT. MERPATI NUSANTARA',
  },
  {
    code: 'FV',
    description: 'PULKOVO AVIATION ENTERPRISE',
  },
  {
    code: 'QF',
    description: 'QANTAS AIRWAYS LTD.',
  },
  {
    code: 'QR',
    description: 'QATAR AIRWAYS (W.L.L.)',
  },
  {
    code: '7H',
    description: 'RAVNAIR ALASKA',
  },
  {
    code: 'RV',
    description: 'REEVE ALEUTIAN AIRWAYS INC',
  },
  {
    code: '7S',
    description: 'REGION AIR PTE. LTD.',
  },
  {
    code: 'FN',
    description: 'REGIONAL AIRLINES',
  },
  {
    code: 'VM',
    description: 'REGIONAL AIRLINES',
  },
  {
    code: 'YS',
    description: 'REGIONAL AIRLINES',
  },
  {
    code: 'QQ',
    description: 'RENO AIR INC.',
  },
  {
    code: 'WE',
    description: 'RHEINTALFLUG SEEWALD',
  },
  {
    code: 'GV',
    description: 'RIGA AIRLINES',
  },
  {
    code: 'SL',
    description: 'RIO-SUL SERVICOS AEREOS REGIONALES',
  },
  {
    code: 'WQ',
    description: 'ROMAVIA',
  },
  {
    code: 'AT',
    description: 'ROYAL AIR MAROC',
  },
  {
    code: 'QN',
    description: 'ROYAL AVIATION INC',
  },
  {
    code: 'BI',
    description: 'ROYAL BRUNEI AIRLINES SDN BHD',
  },
  {
    code: 'RJ',
    description: 'ROYAL JORDANIAN',
  },
  {
    code: 'RA',
    description: 'ROYAL NEPAL ARLNS',
  },
  {
    code: 'ZC',
    description: 'ROYAL SWAZI NATIONAL AIRWAYS CORP.',
  },
  {
    code: 'WR',
    description: 'ROYAL TONGAN AIRLINES',
  },
  {
    code: 'VJ',
    description: 'ROYAN AIR CAMBODGE',
  },
  {
    code: 'RD',
    description: 'RYAN INTERNATIONAL',
  },
  {
    code: 'FR',
    description: 'RYANAIR LTD.',
  },
  {
    code: '4Z',
    description: 'SA AIRLINK',
  },
  {
    code: 'FA',
    description: 'SAFAIR (PROPRIETARY) LTD.',
  },
  {
    code: 'S2',
    description: 'SAHARA INDIA AIRLINES LTD.',
  },
  {
    code: 'HZ',
    description: 'SAKHALINSKIE AVIATRASSY',
  },
  {
    code: 'E5',
    description: 'SAMARA AIRLINES',
  },
  {
    code: 'S3',
    description: 'SANTA BARBARA AIRLINES C.A.',
  },
  {
    code: 'N5',
    description: 'SARDAIRLIEN - SOC. COOP. A.R.L.',
  },
  {
    code: 'SP',
    description: 'SATA - AIR ACORES',
  },
  {
    code: 'S4',
    description: 'SATA INTERNATIONAL',
  },
  {
    code: 'SV',
    description: 'SAUDI ARABIAN AIRLINES',
  },
  {
    code: 'SK',
    description: 'SCANDINAVIAN AIRLINES SYSTEM (SAS)',
  },
  {
    code: '3L',
    description: 'SCENIC AIR, INC.',
  },
  {
    code: 'AW',
    description: 'SCHREINER AIRWAY BV',
  },
  {
    code: 'CB',
    description: 'SCOT AIRWAYS',
  },
  {
    code: 'BB',
    description: 'SEABORNE AVIATION',
  },
  {
    code: 'VC',
    description: 'SERICIOS AVENSA',
  },
  {
    code: 'RZ',
    description: 'SERVICIOS AEREAS NATIONALES S.A.',
  },
  {
    code: 'WB',
    description: 'SERVICIOS AEREOS NACCIONALES (SAN)',
  },
  {
    code: 'FS',
    description: 'SERVICIOS DE TRANSPORTES AEREOS',
  },
  {
    code: 'UG',
    description: 'SEVENAIR',
  },
  {
    code: 'D2',
    description: 'SEVERSTAL',
  },
  {
    code: 'NL',
    description: 'SHAHEEN AIR INTERNATIONAL',
  },
  {
    code: 'SC',
    description: 'SHANDONG AIRLINES',
  },
  {
    code: '7Q',
    description: 'SHOROUK AIR',
  },
  {
    code: '3S',
    description: 'SHUSWAP FLIGHT CENTER LTD',
  },
  {
    code: 'DD',
    description: 'SHUTTLE AIR CARGO',
  },
  {
    code: 'S5',
    description: 'SHUTTLE AMERICA',
  },
  {
    code: 'TB',
    description: 'SHUTTLE INC DBA USAIR SHUTTLE',
  },
  {
    code: 'S7',
    description: 'SIBERIA ARLNS',
  },
  {
    code: '3U',
    description: 'SICHUAN AIRLINES',
  },
  {
    code: 'SI',
    description: 'SIERRA PACIFIC',
  },
  {
    code: 'MI',
    description: 'SILKAIR (S) PTE LTD',
  },
  {
    code: '3M',
    description: 'SILVER AIRWAYS',
  },
  {
    code: 'SQ',
    description: 'SINGAPORE AIRLINES',
  },
  {
    code: 'JW',
    description: 'SKIPPERS AVIATION',
  },
  {
    code: 'OO',
    description: 'SKY WEST AIRLINES',
  },
  {
    code: '5Y',
    description: 'SKYBUS',
  },
  {
    code: '5P',
    description: 'SKYEUROPE AILINES HUNGARY AS',
  },
  {
    code: '5G',
    description: 'SKYSERVICE',
  },
  {
    code: 'NP',
    description: 'SKYTRANS',
  },
  {
    code: 'JZ',
    description: 'SKYWAYS AB',
  },
  {
    code: '6Q',
    description: 'SLOVAK AIRLINES',
  },
  {
    code: 'QS',
    description: 'SMART WINGS',
  },
  {
    code: 'Q7',
    description: 'SOBELAIR',
  },
  {
    code: 'EH',
    description: 'SOCIEDAD ECUATORIANA DE TRANSPORTES',
  },
  {
    code: 'MM',
    description: 'SOCIEDED AERONUTICA MEDELLIN CON',
  },
  {
    code: 'TX',
    description: 'SOCIETE NOUVELLE AIR GUADELOUPE',
  },
  {
    code: 'PN',
    description: 'SOCIETE NOUVELLE AIR MARTINIQUE',
  },
  {
    code: 'OY',
    description: 'SODER AIR',
  },
  {
    code: '8R',
    description: 'SOL LINEAS AEREAS',
  },
  {
    code: 'IE',
    description: 'SOLOMON AIRLINES',
  },
  {
    code: 'SA',
    description: 'SOUTH AFRICAN AIRWAYS',
  },
  {
    code: 'S9',
    description: 'SOUTHEAST AIRLINES',
  },
  {
    code: 'SJ',
    description: 'SOUTHERN AIR TRANSPORT',
  },
  {
    code: 'WN',
    description: 'SOUTHWEST AIRLINES',
  },
  {
    code: 'JK',
    description: 'SPANAIR S.A.',
  },
  {
    code: 'NK',
    description: 'SPIRIT AIRLINES',
  },
  {
    code: 'UL',
    description: 'SRI LANKAN AIRLINES',
  },
  {
    code: 'T8',
    description: 'STA TRANS AFRICAN AIRLINES',
  },
  {
    code: 'SE',
    description: 'STAR AIR',
  },
  {
    code: 'YD',
    description: 'STATE ENTERPRISE GOMELAVIA',
  },
  {
    code: 'NB',
    description: 'STERLING EUROPEAN AIRLINES A/S',
  },
  {
    code: 'EF',
    description: 'STRATEGIC AIRLINES',
  },
  {
    code: 'SD',
    description: 'SUDAN AIRWAYS CO. LTD.',
  },
  {
    code: 'BV',
    description: 'SUN AIR',
  },
  {
    code: 'EZ',
    description: 'SUN AIR OF SCANDINAVIA',
  },
  {
    code: 'SY',
    description: 'SUN COUNTRY AIRLINES',
  },
  {
    code: 'XQ',
    description: 'SUN EXPRESS',
  },
  {
    code: 'SM',
    description: 'SUN WORLD INTERNATIONAL',
  },
  {
    code: 'PI',
    description: 'SUNFLOWER AIRLINES LTD.',
  },
  {
    code: 'JX',
    description: 'SUNJET INTERNATIONAL',
  },
  {
    code: 'WG',
    description: 'SUNWING',
  },
  {
    code: 'K0',
    description: 'SUNWING AIRLINES',
  },
  {
    code: 'PY',
    description: 'SURINAM AIRWAYS LTD.',
  },
  {
    code: '08',
    description: 'SWAN AERO',
  },
  {
    code: 'S1',
    description: 'SWANBERG AIR',
  },
  {
    code: 'HJ',
    description: 'SWEDEWAYS AIR LINES AB',
  },
  {
    code: 'LX',
    description: 'SWISS AIR LINES',
  },
  {
    code: 'SR',
    description: 'SWISS AIR TRANS CO. LTD. (SWISSAIR)',
  },
  {
    code: 'RB',
    description: 'SYRIAN ARAB AIRLINES',
  },
  {
    code: 'DT',
    description: 'TAAG - LINHAS AEREAS DE ANGOLA',
  },
  {
    code: 'TA',
    description: 'TACA INTERNATIONAL AIRLINES, S.A.',
  },
  {
    code: 'VR',
    description: 'TACV',
  },
  {
    code: 'JJ',
    description: 'TAM - TRANSPORTES AEREOS',
  },
  {
    code: 'PZ',
    description: 'TAM - TRANSPORTES AEREOS DEL',
  },
  {
    code: 'GG',
    description: 'TAMAIR',
  },
  {
    code: 'EQ',
    description: 'TAME',
  },
  {
    code: 'TP',
    description: 'TAP - AIR PORTUGAL',
  },
  {
    code: 'RO',
    description: 'TAROM - ROMANIAN AIR TRANSPORT',
  },
  {
    code: 'T6',
    description: 'TAVREY AIRCOMPANY',
  },
  {
    code: 'RU',
    description: 'TCI SKYKING',
  },
  {
    code: 'TG',
    description: 'THAI AIRWAYS INTERNATIONAL PUBLIC',
  },
  {
    code: 'NM',
    description: 'THE MOUNT COOK GROUP LTD.',
  },
  {
    code: 'FQ',
    description: 'THOMAS COOK AIRLINE',
  },
  {
    code: 'MT',
    description: 'THOMAS COOKE AIRLINES',
  },
  {
    code: 'TO',
    description: 'THOMSON FLY',
  },
  {
    code: 'TR',
    description: 'TIGER AIRWAYS',
  },
  {
    code: 'KI',
    description: 'TIME AIR, INC',
  },
  {
    code: 'ZT',
    description: 'TITAN AIRWAYS',
  },
  {
    code: 'TI',
    description: 'TOL AIR SERVICES, INC',
  },
  {
    code: 'FF',
    description: 'TOWER AIR INC.',
  },
  {
    code: 'P6',
    description: 'TRANS AIR',
  },
  {
    code: '7T',
    description: 'TRANS COTE INC',
  },
  {
    code: 'TL',
    description: 'TRANS MEDITERRANEAN AIRWAYS',
  },
  {
    code: '9N',
    description: 'TRANS STATES AIRLINES',
  },
  {
    code: '6N',
    description: 'TRANS TRAVEL',
  },
  {
    code: 'UN',
    description: 'TRANSAERO AIRLINES',
  },
  {
    code: 'GE',
    description: 'TRANSASIA AIRWAYS',
  },
  {
    code: 'HV',
    description: 'TRANSAVIA AIRLINES',
  },
  {
    code: 'AL',
    description: 'TRANSAVIAEXPORT CARGO AIRLINES',
  },
  {
    code: '4P',
    description: 'TRANSPORTES AEREAS DEL PERU, S.A.',
  },
  {
    code: 'GD',
    description: 'TRANSPORTES AEREOS EJECUTIVOS S.A.',
  },
  {
    code: 'KK',
    description: 'TRANSPORTES AEREOS REGIONAIS S.A.',
  },
  {
    code: 'TQ',
    description: 'TRANSWEDE AIRWAYS',
  },
  {
    code: '01',
    description: 'TRAVEL CITY (XL LEISURE GROUP)',
  },
  {
    code: 'PM',
    description: 'TROPIC AIR LTD/POLUS CENTRE FOR',
  },
  {
    code: 'TU',
    description: 'TUNIS AIR',
  },
  {
    code: 'QW',
    description: 'TURK & CAICOS AIRWAYS',
  },
  {
    code: 'TK',
    description: 'TURKISH AIRLINES INC.',
  },
  {
    code: 'T7',
    description: 'TWIN JET',
  },
  {
    code: 'VO',
    description: 'TYROLEAN ARWYS',
  },
  {
    code: '7M',
    description: 'TYUMEN ARLNS',
  },
  {
    code: 'WI',
    description: 'U-LAND AIRLINES',
  },
  {
    code: 'PS',
    description: 'UKRAINE INTERNATIONAL AIRLINES',
  },
  {
    code: 'UA',
    description: 'UNITED AIRLINES, INC.',
  },
  {
    code: 'UV',
    description: 'UNIVERSAL AIRWAYS INC.',
  },
  {
    code: 'UZ',
    description: 'UP AIRWAYS',
  },
  {
    code: '5X',
    description: 'UPS',
  },
  {
    code: 'U6',
    description: 'URAL AIRLINES',
  },
  {
    code: 'US',
    description: 'US AIRWAYS, INC.',
  },
  {
    code: 'U5',
    description: 'USA 3000',
  },
  {
    code: 'HY',
    description: 'UZBEKISTAN HAVO YULLARY',
  },
  {
    code: 'J7',
    description: 'VALUJET AIRLINES',
  },
  {
    code: 'NJ',
    description: 'VANGUARD AIRLINES',
  },
  {
    code: 'RG',
    description: 'VARIG S.A.',
  },
  {
    code: 'VP',
    description: 'VIACAO AEREA SAO PAULO, S.A. (VASP)',
  },
  {
    code: 'VI',
    description: 'VIEQUES AIR LINK, INC.',
  },
  {
    code: 'VN',
    description: 'VIETNAM AIRLINES CORPORATION',
  },
  {
    code: 'VK',
    description: 'VIKING AIRLINES',
  },
  {
    code: 'VQ',
    description: 'VINTAGE PROPS AND JETS',
  },
  {
    code: '9V',
    description: 'VIP AIR',
  },
  {
    code: '07',
    description: 'VIRGIN AMERICA',
  },
  {
    code: 'VX',
    description: 'VIRGIN AMERICA',
  },
  {
    code: 'VS',
    description: 'VIRGIN ATLANTIC',
  },
  {
    code: 'VA',
    description: 'VIRGIN AUSTRALIA',
  },
  {
    code: 'DJ',
    description: 'VIRGIN BLUE / PACIFIC BLUE',
  },
  {
    code: 'TV',
    description: 'VIRGIN EXPRESS',
  },
  {
    code: 'V2',
    description: 'VISION AIRLINES',
  },
  {
    code: 'VB',
    description: 'VIVAAEROBUS',
  },
  {
    code: 'VG',
    description: 'VLM VLAAMSE',
  },
  {
    code: '41',
    description: 'VOLARIS',
  },
  {
    code: 'G6',
    description: 'VOLGA AIRLINES',
  },
  {
    code: '4V',
    description: 'VOYAGEUR AIRWAYS',
  },
  {
    code: 'VY',
    description: 'VUELING AIRLINES',
  },
  {
    code: 'WU',
    description: 'WALK IN',
  },
  {
    code: 'WZ',
    description: 'WEST AFRICAN AIRLINES',
  },
  {
    code: '8O',
    description: 'WEST COAST AIR',
  },
  {
    code: 'OE',
    description: 'WESTAIR COMMUTER AIRLINES, INC',
  },
  {
    code: 'W7',
    description: 'WESTERN PACIFIC',
  },
  {
    code: 'WS',
    description: 'WESTJET AIRLINES LTD.',
  },
  {
    code: 'WF',
    description: "WIDEROE'S FLYVESELSKAP A/S",
  },
  {
    code: 'IV',
    description: 'WIND JET',
  },
  {
    code: 'RM',
    description: 'WINGS WEST AIRLINES, INC',
  },
  {
    code: 'WM',
    description: 'WINWARD ISLANDS',
  },
  {
    code: 'W6',
    description: 'WIZZ AIR',
  },
  {
    code: 'IY',
    description: 'YEMENIA YEMEN AIRWAYS',
  },
  {
    code: 'OQ',
    description: 'ZAMBIAN EXPRESSS AIRWAYS (1995) LTD',
  },
  {
    code: 'Z7',
    description: 'ZIMBABWE EXPRESS AIRLINES',
  },
  {
    code: 'C4',
    description: 'ZIMEX AVIATION',
  },
  {
    code: 'ZM',
    description: 'ZOOM',
  },
  {
    code: 'Z4',
    description: 'ZOOM AIRLINES',
  },
]
