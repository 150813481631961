import React, { useMemo } from 'react'

import {
  getImage,
  joinArray,
  humanFormatNumber,
  cleanDecimalPartForLanguage,
} from '@/utils'
import { ICarListItem, ICurrencyResponse } from '@/types'
import { Button, CustomImage, Icon, TForPage } from '../'
import { useLanguages } from '@/hooks'

import manualIcon from '@/assets/images/icons/popular-cars-menu-1.svg'

interface IPopularCarItemProps extends ICarListItem {
  type: TForPage
  exchange?: ICurrencyResponse['data']
  onClick?: (_item: ICarListItem) => void
}

export const PopularCarItem: React.FC<IPopularCarItemProps> = carItem => {
  const { price, car, isShowOnSlider, onClick, exchange, type } = carItem
  const _car = car || carItem
  if (!_car || isShowOnSlider === 0) return null
  const {
    model,
    image,
    big_bags,
    small_bags,
    manufacturer,
    vehicle_type,
    seating_capacity,
    transmission_type,
  } = _car
  const { t, currency } = useLanguages()

  const _convertedPrice = useMemo(
    () =>
      ((exchange?.[currency.name.toUpperCase()]?.rate || 1) * +price).toFixed(
        2,
      ),
    [exchange, currency, price],
  )

  return (
    <div className="item">
      <div className="popular-cars-img">
        <img
          width={240}
          height={180}
          alt="Popular car item"
          src={getImage(image, false)}
          style={{ height: 'unset', objectFit: 'contain' }}
        />
      </div>
      <div className="popular-cars-box">
        <div className="popular-cars-box-info">
          <p className="popular-cars-box-title">
            {joinArray(' ', manufacturer, model)}
          </p>
          <h3 className="popular-cars-box-subtitle">{vehicle_type}</h3>
          <div className="popular-cars-box-menu">
            <div className="popular-cars-box-menu-item">
              <div className="popular-cars-box-menu-icon">
                <CustomImage
                  width={20}
                  height={20}
                  src={manualIcon.src}
                  style={{ objectFit: 'contain' }}
                />
              </div>
              <p className="popular-cars-box-menu-text mb-0">
                {/* @ts-ignore */}
                {t(transmission_type?.toLowerCase?.())}
              </p>
            </div>
            <div className="popular-cars-box-menu-item">
              <div className="popular-cars-box-menu-icon">
                <Icon name="popular-cars-menu-2" />
              </div>
              <p className="popular-cars-box-menu-text mb-0">
                {_car?.has_air_conditioner
                  ? t('leasingPopupYes')
                  : t('leasingPopupNo')}
              </p>
            </div>
            <div className="popular-cars-box-menu-item">
              <div className="popular-cars-box-menu-icon">
                <Icon name="popular-cars-menu-3" />
              </div>
              <p className="popular-cars-box-menu-text mb-0">
                {seating_capacity}
              </p>
            </div>
            <div className="popular-cars-box-menu-item">
              <div className="popular-cars-box-menu-icon">
                <Icon name="popular-cars-menu-4" />
              </div>
              <p className="popular-cars-box-menu-text mb-0">{big_bags}</p>
            </div>
            <div className="popular-cars-box-menu-item">
              <div className="popular-cars-box-menu-icon small">
                <Icon name="popular-cars-menu-5" />
              </div>
              <p className="popular-cars-box-menu-text mb-0">{small_bags}</p>
            </div>
          </div>
        </div>
        <hr className="m-0 mt-2" />
        {['1', '2'].includes(type) && (
          <div className="popular-cars-price">
            <p className="popular-cars-price-title">{t('leasingPopupPrice')}</p>
            <div className="popular-cars-price-info">
              <p className="popular-cars-price-text mb-0">{t('priceStarts')}</p>
              <div className="popular-cars-price-badge">
                {t('currency', {
                  currency: '',
                  value: cleanDecimalPartForLanguage(
                    `${humanFormatNumber(
                      `${_convertedPrice}`.replace(/[,]/g, ''),
                      ',',
                    )} ${currency.name}`,
                    currency.name,
                  ),
                })}
              </div>
            </div>
          </div>
        )}
        <div className="popular-cars-btn-box">
          <Button
            className="btn section-btn w-100"
            onClick={() => onClick?.(carItem)}
          >
            {type === '3'
              ? t('requestPrice')
              : ['2', '4'].includes(type)
              ? t('requestNow')
              : t('seeDetails')}
          </Button>
        </div>
      </div>
    </div>
  )
}
