import React from 'react'
import { useRouter } from 'next-intl/client'

import { Button, CustomImage } from '@/components'
import { aboutPath, getImage, rem } from '@/utils'
import { useLanguages } from '@/hooks'
import { IBannerType } from '@/types'

export const CarRentalSection: React.FC<Partial<IBannerType>> = ({
  image,
  title,
  description,
}) => {
  const { t } = useLanguages()
  const router = useRouter()

  return (
    <section className="car-rental">
      <div className="car-rental-container">
        <p className="car-rental-title">{title || t('carRental&MuchMore')}</p>
        <p className="car-rental-subtitle">
          {description || t('moreThanRentals')}
        </p>
        <CustomImage
          width={1280}
          className="car"
          height={(1280 * 9) / 16}
          src={getImage(image, false)}
          style={{ width: rem(315), height: rem(153) }}
        />
        <Button className="btn big" onClick={() => router.push(aboutPath)}>
          {t('learnMore')}
        </Button>
      </div>
    </section>
  )
}
