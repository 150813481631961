import React, { useEffect, useMemo } from 'react'
import {
  useMap,
  APIProvider,
  AdvancedMarker,
  Map as GoogleMap,
} from '@vis.gl/react-google-maps'
import classNames from 'classnames'
import moment from 'moment'

import { useReservation } from '@/hooks'
import { IBranch, TFunc } from '@/types'

import { LocationDetails, MapPin } from './components'

interface IMapProps {
  onClose?: TFunc
  isActive: boolean
  location?: IBranch
}

const defaultProps = {
  zoom: 11,
}
const MapView: React.FC<IMapProps> = ({ location, onClose, isActive }) => {
  const map = useMap()
  const { locations } = useReservation()

  const markers = useMemo(() => {
    return locations.map((location, idx) => ({
      id: `${idx + 1}`,
      lat: location.Branch_Station_Latitude,
      lng: location.Branch_Station_Longitude,
      closed:
        // @ts-ignore
        !location[
          `Branch_Close_${moment().locale('en').format('ddd')}`
        ].includes('24'),
    }))
  }, [locations])

  useEffect(() => {
    const location = locations?.[0]
    if (location && map) {
      map.moveCamera({
        center: {
          lat: location.Branch_Station_Latitude,
          lng: location.Branch_Station_Longitude,
        },
      })
    }
  }, [locations, map])

  return (
    <div
      className={classNames('select-location-map', {
        locationtabcontent: !isActive,
      })}
    >
      <GoogleMap
        zoomControl
        disableDefaultUI
        mapId="custom-map-id"
        fullscreenControl={false}
        defaultCenter={markers?.[0]}
        defaultZoom={defaultProps.zoom}
        zoomControlOptions={{ position: 3 }}
        style={{ width: '100%', height: '100%' }}
      >
        {markers.map((marker, idx) => (
          <AdvancedMarker
            key={idx}
            position={{
              ...marker,
            }}
          >
            <MapPin
              id={marker.id}
              className={classNames({
                closed: marker.closed,
              })}
            />
          </AdvancedMarker>
        ))}
      </GoogleMap>
      {/* <div className="search-move">
        <div className="search-move-box">
          <Icon name="checkbox" />
        </div>
        <p className="search-move-text">Search as I move the map</p>
      </div> */}
      {!!location && <LocationDetails location={location} onClose={onClose} />}
    </div>
  )
}

export const Map: React.FC<IMapProps> = props => (
  <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string}>
    <MapView {...props} />
  </APIProvider>
)
