import React from 'react'

import { useLanguages } from '@/hooks'
import { FleetData } from '@/types'
import {
  Icon,
  //  Button,
  CustomImage,
} from '../'

import manualIcon from '@/assets/images/icons/popular-cars-menu-1.svg'

export const FleetCard: React.FC<FleetData> = ({
  features,
  LargeImage,
  ExampleModel,
  Transmission,
  AirConditioning,
  LuggageCapacity,
  PassengerCapacity,
}) => {
  const { t } = useLanguages()
  return (
    <div className="fleet-card">
      <div className="fleet-card-header">
        <div className="fleet-card-title">{ExampleModel}</div>
        {/* <div className="fleet-card-info">
          <Icon name="specs-passenger-gray" />
          {seats}
        </div>
        <div className="fleet-card-info">
          <Icon name="lock" />
          {bags}
        </div> */}
      </div>
      <div className="fleet-card-body">
        <div className="w-66 fleet-card-info">
          <div className="row">
            <div className="w-33">
              <p className="fleet-info-details-text title">{t('details')}</p>
              <div className="fleet-info-details-list">
                <div className="fleet-info-details-list-item">
                  <CustomImage
                    width={20}
                    height={20}
                    src={manualIcon.src}
                    style={{ objectFit: 'contain' }}
                  />
                  {/* @ts-ignore */}
                  <p>{t(Transmission?.toLocaleLowerCase())}</p>
                </div>
                <div className="fleet-info-details-list-item">
                  <Icon name="popular-cars-menu-3" />
                  <p>{t('leasingPopupSeats', { count: PassengerCapacity })}</p>
                </div>
                <div className="fleet-info-details-list-item">
                  <Icon name="popular-cars-menu-4" />
                  <p>{t('leasingPopupBags', { count: LuggageCapacity })}</p>
                </div>
                {!!AirConditioning && (
                  <div className="fleet-info-details-list-item">
                    <Icon name="popular-cars-menu-2" />
                    <p>{t('reservationAirConditioning')}</p>
                  </div>
                )}
                {/* <div className="fleet-info-details-list-item">
                  <CustomImage
                    width={20}
                    height={20}
                    src={electroIcon.src}
                    style={{ objectFit: 'contain' }}
                  />
                  <p>Electro car</p>
                </div> */}
              </div>
            </div>
            <div className="w-50">
              <p className="fleet-info-details-text title">
                {t('vehicleFeatures')}
              </p>
              <ul className="fleet-info-details-features">
                {Object.values(features).map((_feature, idx) => (
                  <li key={idx}>
                    <p className="fleet-info-details-text">
                      {_feature.Feature}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <img
          width={312}
          height={160}
          alt="fleet-img"
          src={LargeImage}
          className="fleet-card-img"
          style={{ height: 'unset' }}
        />
      </div>
      {/* <div className="fleet-card-footer">
        <Button className="btn w-m-100">{t('seeDetails')}</Button>
      </div> */}
    </div>
  )
}
