import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

import { IAddress, ICity, ISearchLocationResponse, TFunc } from '@/types'
import { Button, Icon, Popover, TextInput } from '@/components'
import { useLanguages, useSearch } from '@/hooks'
import { useCustomSearch } from '@/apis'

import { ITriggerAction } from '../Popover/types'
import { type ISearchInputProps } from './types'

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  ISearchInputProps
>(
  (
    {
      initial = '',
      value,
      debounce = 300,
      className,
      placeholder,
      onSelect,
      ...rest
    },
    ref,
  ) => {
    const { appLang, t } = useLanguages()
    const popoverRef = useRef<ITriggerAction>(null)
    const [locations, setLocations] = useState<ISearchLocationResponse>()

    const onSuccess = useCallback((_res: ISearchLocationResponse) => {
      setLocations(_res)
    }, [])

    useEffect(() => {
      // open popover programmatically
      if (!!locations) {
        popoverRef.current?.onTrigger()
      }
    }, [locations])

    const [getLocation] = useCustomSearch(onSuccess)

    const onSearch = useCallback(
      (query?: string) => {
        if (!query) return
        getLocation({
          pathParams: {
            lang: appLang,
            keyword: query,
          },
        })
      },
      [appLang, getLocation],
    )

    const { text, onChangeText } = useSearch(debounce, initial, onSearch)

    const renderAddress = useCallback((location: ICity | IAddress) => {
      const { formattedCityStateZip, longName, locationNameTranslation } =
        location as ICity & IAddress
      return (
        <div className="over-item-box-info">
          <h3 className="over-item-box-title">
            {longName ?? locationNameTranslation}
          </h3>
          {formattedCityStateZip && (
            <p className="over-item-box-subtitle">{formattedCityStateZip}</p>
          )}
        </div>
      )
    }, [])

    const _onSelect = useCallback(
      (_location?: IAddress | ICity, onClose?: TFunc) => () => {
        const {
          latitude,
          longName,
          longitude,
          stationId,
          locationNameTranslation,
        } = (_location || {}) as ICity & IAddress
        setLocations(undefined)
        onSelect(
          _location
            ? {
                latitude: latitude || '',
                longitude: longitude || '',
                name: longName ?? locationNameTranslation ?? '',
                code: longName ? '' : stationId ?? '',
              }
            : undefined,
        )
        onChangeText('')
        onClose?.()
      },
      [onChangeText, onSelect],
    )

    const { cities, airports, railStations } = useMemo(() => {
      const { cities, airports, railStations } = locations || {
        cities: [],
        airports: [],
        railStations: [],
      }
      return {
        cities,
        airports,
        railStations,
      }
    }, [locations])

    return (
      <Popover
        distance={0}
        ref={popoverRef}
        hasArrow={false}
        placement="bottom-start"
        disabled={!Object.keys(locations || {}).length}
        button={
          <TextInput
            {...rest}
            ref={ref}
            autoFocus
            type="text"
            value={text || ''}
            className={className}
            placeholder={placeholder}
            fakeValue={value as string}
            onClear={() => _onSelect(undefined)()}
            onChange={e => {
              if (!e.target.value) {
                _onSelect(undefined)()
                setLocations(undefined)
              }
              onChangeText(e.target.value)
            }}
          />
        }
      >
        {onClose => (
          <div
            className={classNames('over', {
              'd-none':
                !cities.length && !airports.length && !railStations.length,
            })}
          >
            {!!airports.length && (
              <>
                <div className="over-title">
                  <Icon name="airplane-active" />
                  <p>{t('airports')}</p>
                </div>
                <div className="over-items">
                  {airports.map((airport, idx) => (
                    <div className="over-item" key={idx}>
                      <div className="over-item-box">
                        {renderAddress(airport)}
                        <Button
                          type="button"
                          className="select-btn"
                          onClick={_onSelect(airport, onClose)}
                        >
                          {t('select')}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!!cities.length && (
              <>
                <div className="over-title">
                  <Icon name="footer-location" />
                  <p>{t('cities')}</p>
                </div>
                <div className="over-items">
                  {cities.map((city, idx) => (
                    <div className="over-item" key={idx}>
                      <div className="over-item-box">
                        {renderAddress(city)}
                        <Button
                          type="button"
                          className="select-btn"
                          onClick={_onSelect(city, onClose)}
                        >
                          {t('select')}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!!railStations.length && (
              <>
                <div className="over-title">
                  <Icon name="airplane-active" />
                  <p>{t('trainStation')}</p>
                </div>
                <div className="over-items">
                  {railStations.map((railStation, idx) => (
                    <div className="over-item" key={idx}>
                      <div className="over-item-box">
                        {renderAddress(railStation)}
                        <Button
                          type="button"
                          className="select-btn"
                          onClick={_onSelect(railStation, onClose)}
                        >
                          {t('select')}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Popover>
    )
  },
)
