import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useRouter } from 'next-intl/client'
import { capitalize } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'

import {
  Form,
  Button,
  Checkbox,
  TextLink,
  TextInput,
  SearchInput,
  CustomImage,
  PageWrapper,
  NativeSelect,
  DateRangePicker,
  ConditionalWrapper,
} from '@/components'
import {
  editPath,
  joinArray,
  generateSubPath,
  setStorageValue,
  reservationsPath,
  startReservationForm,
  locationPath,
  vehiclePath,
} from '@/utils'
import {
  ITab,
  DateRange,
  ILocationData,
  IErrorResponse,
  TStartReservationForm,
  IStartReservationPayload,
  IStartReservationResponse,
  TLang,
} from '@/types'
import { useHookForm, useLanguages } from '@/hooks'
import { useStartReservation } from '@/apis'
import { LANG_KEY, START_KEY } from '@/constants'

import { HandlerPopup } from '../Modals'

interface IPreviewSectionProps {
  code?: string
  image?: string
  promotionCode?: boolean
  hasBackground?: boolean
}

const ageOptions: ITab<string>[] = [
  ...new Array(8).fill(18).map((v, idx) => ({
    value: v + idx,
    label: `${v + idx}${v + idx === 25 ? '+' : ''}`,
  })),
].reverse()

export const PreviewSection: React.FC<IPreviewSectionProps> = ({
  code,
  image,
  promotionCode,
  hasBackground = true,
}) => {
  const router = useRouter()
  const { t } = useLanguages()
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const { mutateAsync: reservationStart, isPending: isStartingReservation } =
    useStartReservation()

  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(startReservationForm, {
    defaultValues: {
      pickUpTime: '12:00',
      returnTime: '12:00',
      differentLocation: 0,
      returnDate: moment().add(4, 'day').toDate(),
      pickUpDate: moment().add(1, 'day').startOf('day').toDate(),
      promotionCode: promotionCode ? code ?? t('promoCode') : undefined,
    },
  })

  const onClose = useCallback(() => {
    setShowPopup(false)
    setErrorMessage(undefined)
  }, [])

  const onSubmit: SubmitHandler<TStartReservationForm> = useCallback(
    async data => {
      const {
        driverAge,
        pickUpDate,
        pickUpStationCode,
        differentLocation,
        dropOffStationCode,
        returnDate,
        ...rest
      } = data

      const payload: IStartReservationPayload = {
        ...rest,
        driverAge: `${driverAge}`,
        // @ts-ignore
        differentLocation: `${differentLocation}`,
        returnDate: moment(returnDate).format('YYYY-MM-DD'),
        pickUpDate: moment(pickUpDate).format('YYYY-MM-DD'),
        pickUpStationCode: !pickUpStationCode ? null : pickUpStationCode,
        dropOffStationCode: !dropOffStationCode ? null : dropOffStationCode,
      }

      try {
        const response = await reservationStart(payload)
        const _data = {
          ...response.data,
          parameters: data,
          startedAt: new Date(),
        }
        if (!response.data?.isLocationPage) {
          const data = response.data.data as IStartReservationResponse
          if (!data.vehicles?.some?.(_v => _v.status === 'Available')) {
            setShowPopup(true)
            return
          }
        }
        const isEvn = [
          data.pickUpLocation.toLowerCase(),
          data?.dropOffLocation?.toLowerCase(),
        ].some(_v => _v?.includes('yerevan'))
        const _opts = !isEvn ? { locale: 'en' as TLang } : {}
        setStorageValue(START_KEY, _data)
        if (Object.keys(_opts).length) {
          setStorageValue(LANG_KEY, 'en')
        }
        const timer = setTimeout(() => {
          router.replace(
            response.data?.isLocationPage ? locationPath : vehiclePath,
            _opts,
          )
          clearTimeout(timer)
        }, 200)
      } catch (err: any) {
        const error: IErrorResponse = err
        setErrorMessage(error?.response?.data?.message)
        if (error?.response?.data?.message) {
          setShowPopup(true)
        }
      }
    },
    [reservationStart, router],
  )

  const onDateSelect = useCallback(
    (_dateRange?: DateRange) => {
      // @ts-ignore
      setValue('pickUpDate', _dateRange?.from)
      // @ts-ignore
      setValue('returnDate', _dateRange?.to)
      trigger('pickUpDate')
      trigger('returnDate')
    },
    [setValue, trigger],
  )

  const isChecked = !!getValues('differentLocation')

  const pickupLocation = joinArray(' ', getValues('pickUpLocation'))

  const dropOffLocation = joinArray(' ', getValues('dropOffLocation'))

  const onSelect = useCallback(
    (type: 'dropOff' | 'pickUp') => (location?: ILocationData) => {
      setValue(`${type}Location`, location?.name ?? '')
      setValue(`${type}StationCode`, location?.code ?? '')
      setValue(`${type}Latitude`, `${location?.latitude ?? ''}`)
      setValue(`${type}Longitude`, `${location?.longitude ?? ''}`)
      trigger(`${type}StationCode`)
      trigger(`${type}Longitude`)
      trigger(`${type}Latitude`)
      trigger(`${type}Location`)
    },
    [setValue, trigger],
  )

  const onSelectTime = useCallback(
    (type: 'return' | 'pickUp') => (time: string) => {
      setValue(`${type}Time`, time)
      trigger(`${type}Time`)
    },
    [setValue, trigger],
  )

  return (
    <PageWrapper isLoading={isStartingReservation}>
      <section
        className={classNames('preview pt-20', {
          checked: isChecked,
        })}
      >
        {!!image && (
          <CustomImage
            priority
            src={image}
            width={1240}
            className="preview-bg"
            height={(1240 * 9) / 16}
          />
        )}
        <div className="preview-box">
          <div className="preview-box-header">
            <ConditionalWrapper
              className="preview-box-title"
              WrapEl={!hasBackground ? 'h1' : 'p'}
            >
              {t('reserveAVehicle')}
            </ConditionalWrapper>
            <p className="preview-box-or">{t('or')}</p>
            <TextLink
              className="preview-box-modify"
              href={generateSubPath(reservationsPath, editPath)}
            >
              {t('viewModifyCancel')}
            </TextLink>
          </div>
          <div className="form-fields-box">
            <Form className="form-fields" onSubmit={handleSubmit(onSubmit)}>
              <div className="w-66 search-input">
                <SearchInput
                  required
                  value={pickupLocation}
                  error={errors.pickUpLocation?.message}
                  onSelect={onSelect('pickUp')}
                  label={t('pickupLocation')}
                  className="preview-input"
                  placeholder={t('locationPlaceholder')}
                />
                <Checkbox
                  onClick={(_isChecked: boolean) => {
                    setValue('differentLocation', _isChecked ? 1 : 0)
                    trigger('differentLocation')

                    if (!_isChecked) {
                      setValue('dropOffLocation', undefined)
                      setValue('dropOffLatitude', undefined)
                      setValue('dropOffLongitude', undefined)
                      setValue('dropOffStationCode', undefined)
                      trigger('dropOffLocation')
                      trigger('dropOffLatitude')
                      trigger('dropOffLongitude')
                      trigger('dropOffStationCode')
                    }
                  }}
                  checked={!!getValues('differentLocation')}
                  label={t('returnToDifferentLocation')}
                />
                {isChecked && (
                  <div className="w-66 search-input return-input">
                    <SearchInput
                      required
                      value={dropOffLocation}
                      error={errors.dropOffLocation?.message}
                      onSelect={onSelect('dropOff')}
                      label={t('returnLocation')}
                      className="preview-input"
                      placeholder={t('locationPlaceholder')}
                    />
                  </div>
                )}
              </div>
              <div className="w-33">
                <NativeSelect
                  required
                  options={ageOptions}
                  {...register('driverAge')}
                  label={t('renterAge')}
                  error={errors.driverAge?.message}
                />
              </div>

              <div
                className={classNames('d-flex justify-between w-100', {
                  'row-basic': isChecked,
                  'row-reverse': !isChecked,
                })}
              >
                {isChecked && (
                  <div className="w-66 search-input d-m-none">
                    <SearchInput
                      required
                      value={dropOffLocation}
                      error={errors.dropOffLocation?.message}
                      onSelect={onSelect('dropOff')}
                      label={t('returnLocation')}
                      className="preview-input"
                      placeholder={t('locationPlaceholder')}
                    />
                  </div>
                )}
                <div className="w-33">
                  <TextInput
                    disabled={!!promotionCode || !!code}
                    label={t('promotionCode')}
                    placeholder={t('promotionCode')}
                    error={errors.promotionCode?.message}
                    {...register('promotionCode')}
                  />
                </div>
                <DateRangePickerWrapper
                  onChange={onDateSelect}
                  range={{
                    to: getValues('returnDate'),
                    from: getValues('pickUpDate'),
                  }}
                >
                  {(range, toRef, fromRef, onChange, isMobile) => (
                    <div className="w-66 d-flex flex-m-wrap">
                      <DateRangePicker
                        type="pickup"
                        ref={fromRef}
                        value={range}
                        className="w-100"
                        placement="bottom-start"
                        label={t('pickup')}
                        onChange={onChange('pickup')}
                        time={getValues('pickUpTime')}
                        onChangeTime={onSelectTime('pickUp')}
                        numberOfMonths={isMobile ? 1 : undefined}
                      />
                      <DateRangePicker
                        ref={toRef}
                        type="return"
                        value={range}
                        className="w-100"
                        placement="bottom-center"
                        label={t('return')}
                        onChange={onChange('return')}
                        time={getValues('returnTime')}
                        onChangeTime={onSelectTime('return')}
                        numberOfMonths={isMobile ? 1 : undefined}
                      />
                    </div>
                  )}
                </DateRangePickerWrapper>
                <div className="w-33 d-flex align-end">
                  <Button type="submit" className="btn w-100">
                    {t('browseVehicles')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
      <HandlerPopup
        variant="error"
        onClose={onClose}
        isOpen={showPopup}
        // @ts-ignore
        message={t(capitalize(errorMessage)) ?? t('popupCarsNotAvailable')}
      />
    </PageWrapper>
  )
}

interface IDateRangePickerWrapper {
  range: DateRange | undefined
  onChange: (_range: DateRange | undefined) => void
  children:
    | React.ReactNode
    | ((
        _range: DateRange | undefined,
        _toRef: React.MutableRefObject<any>,
        _fromRef: React.MutableRefObject<any>,
        _onChange: (
          _type: 'pickup' | 'return',
        ) => (_range: DateRange | undefined) => void,
        _isMobile: boolean,
      ) => React.ReactNode)
}

export const DateRangePickerWrapper: React.FC<IDateRangePickerWrapper> = ({
  range,
  onChange,
  children,
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const toDateRef = useRef<React.ElementRef<typeof DateRangePicker>>(null)
  const fromDateRef = useRef<React.ElementRef<typeof DateRangePicker>>(null)

  const _onChange = useCallback(
    (type: 'pickup' | 'return') => (_date: DateRange | undefined) => {
      if (type === 'pickup' && moment(_date?.from).isAfter(range?.to)) {
        fromDateRef.current?.onClose()
        setTimeout(() => {
          toDateRef.current?.onTrigger()
        }, 200)
      } else {
        fromDateRef.current?.onClose()
        toDateRef.current?.onClose()
      }
      const from =
        type === 'return' && moment(_date?.from).isBefore(range?.from)
          ? _date?.from
          : type === 'pickup'
          ? _date?.from
          : range?.from
      const to = moment(_date?.from).isAfter(range?.to)
        ? _date?.from
        : type === 'return'
        ? _date?.from
        : range?.to
      // @ts-ignore
      onChange({
        ...range,
        from,
        to,
      })
    },
    [onChange, range],
  )

  useEffect(() => {
    setIsMobile(window?.innerWidth < 1124)
  }, [])

  return (
    <>
      {typeof children === 'function'
        ? children(range, toDateRef, fromDateRef, _onChange, isMobile)
        : children}
    </>
  )
}
