import React, { useEffect } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import { homePath } from '@/utils'

export const NotFoundTemplate: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    const timer = setTimeout(() => {
      router.replace(homePath)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [router])

  return (
    <div className="not-found">
      <h1>404</h1>
      <div>
        <h2>This page ({pathname}) could not be found.</h2>
      </div>
    </div>
  )
}
