import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IMeta, IBlogPage, IResponse, IBestOfferItem } from '@/types'
import { API_ENDPOINT, DEFAULT_LANG, LANG_KEY } from '@/constants'
import { generatePath, getStorageValue } from '@/utils'
import endpoints from '@/apis/endpoints'
import { Observer } from '@/components'
import { useLanguages } from '@/hooks'

import { OfferCard } from './offerCard'

interface ISuggestionsSectionProps {
  pageMeta?: IMeta
  items: IBestOfferItem[]
}

export const BestOffersSection: React.FC<ISuggestionsSectionProps> = ({
  items,
  pageMeta,
}) => {
  const { t } = useLanguages()
  const canLoad = useRef(false)
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState<IMeta>({
    page: 1,
    perPage: 9,
    totalCount: 0,
  })
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState<Record<string, IBestOfferItem[]>>({})

  useEffect(() => {
    setListData({
      1: items,
    })
  }, [items])

  useEffect(() => {
    if (pageMeta) {
      setMeta(pageMeta)
    }
  }, [pageMeta])

  const loadMore = useCallback(async () => {
    if (!canLoad.current) return
    try {
      canLoad.current = false
      setLoading(true)
      const lang = getStorageValue(LANG_KEY, DEFAULT_LANG)
      const res = await fetch(
        generatePath(
          `${API_ENDPOINT}/api/${lang}/${endpoints.offers}`,
          undefined,
          [
            {
              page,
              size: '9',
            },
          ],
        ),
      )
      const data: IResponse<IBlogPage> = await res.json()
      setListData(prev => ({ ...prev, [page]: data.data.items ?? [] }))
      setMeta(data.data.meta)
    } catch (error) {
      //
    } finally {
      setLoading(false)
    }
  }, [page])

  useEffect(() => {
    loadMore()
  }, [loadMore])

  const _pagedData = useMemo(
    () =>
      Object.keys(listData)
        .map(key => listData[key])
        .reduce((acc, cur) => [...acc, ...cur], []),
    [listData],
  )

  const hasMore = useMemo(
    () => meta?.totalCount > _pagedData.length,
    [meta, _pagedData],
  )

  const onLoad = useCallback(() => {
    if (!hasMore) return
    canLoad.current = true
    setPage(prev => ++prev)
  }, [hasMore])

  return (
    <section className="offers-page">
      <h1 className="landing-title">{t('bestOffer')}</h1>
      <div className="offers-box">
        {_pagedData?.map?.((offer, idx) => (
          <OfferCard key={idx} {...offer} className="gap" />
        ))}
        <Observer loading={loading} onIntersect={onLoad} />
      </div>
    </section>
  )
}

export * from './offersSlider'
